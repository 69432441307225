import { getLocalStorageWidth, setLocalStorageWidth } from '@/utils/ui/left-bar.util'
import cn from 'classnames'
import type { ResizeCallback } from 're-resizable'
import { Resizable } from 're-resizable'
import type { FC, PropsWithChildren } from 'react'
import React, { useState } from 'react'

import styles from './left-bar.module.css'

interface IProps {
  className?: string
}

const MIN_WIDTH_LAYOUT = 350
const MAX_WIDTH_LAYOUT = 500
const NAME_WIDTH_LAYOUT = 'widthLayout'

// TODO: Проверить во всём проекте скроллы в баре. Возможна проблема переполнения контента.
const LeftBar: FC<PropsWithChildren<IProps>> = ({ children, className }) => {
  const [width, setWidth] = useState<number | string>(getLocalStorageWidth(NAME_WIDTH_LAYOUT) || 'auto')

  const resizeStopHandler: ResizeCallback = (e, direction, ref) => {
    const currentWidth = ref.offsetWidth
    setWidth(currentWidth)
    setLocalStorageWidth(NAME_WIDTH_LAYOUT, String(currentWidth))
  }

  return (
    <Resizable
      className={cn(styles['left-bar'], className)}
      size={{ width: width, height: 'auto' }}
      minWidth={MIN_WIDTH_LAYOUT}
      maxWidth={MAX_WIDTH_LAYOUT}
      enable={{ right: true }}
      handleClasses={{ right: styles['handle-resize'] }}
      onResizeStop={resizeStopHandler}
    >
      <div className={styles['content-container']}>{children}</div>
    </Resizable>
  )
}

export default LeftBar
