import {
  mapGeneratorForTree,
  mapVp3701ForTree,
  mapVp3711ForTree
} from '@/app/configuration/components/devices/devices-tree/device-tree.service'
import { DeviceTypeId, DeviceTypeLabel } from '@/constants/device/device.constant'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetGeneratorsQuery, useGetVp3701ListQuery } from '@/store/api/devices.api'
import { useGetCratesQuery, useGetLtrModulesQuery } from '@/store/api/ltr.api'
import type { TDeviceNode } from '@/types/device/device-node.type'
import { findNode } from '@/utils/tree/find-node'
import { FolderOutlined } from '@ant-design/icons'
import { type GetRef, Tree } from 'antd'
import type { FC, Key } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import styles from './device-tree.module.css'

type TreeRef = GetRef<typeof Tree>

const DevicesTree: FC = () => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const { setDeviceContent, setSelectedDeviceId, setDeviceNode } = useActions()
  const { data: pagedVp3701List } = useGetVp3701ListQuery(undefined, { pollingInterval: 10000 })
  const { data: pagedGenerators } = useGetGeneratorsQuery()
  const { data: pagedCrates } = useGetCratesQuery()
  const { data: pagedLtrModules } = useGetLtrModulesQuery()
  const { selectedDeviceId } = useTypedSelector((state) => state.devicesReducer)
  const vp3701List = pagedVp3701List?.content
  const generators = pagedGenerators?.content
  const crates = pagedCrates?.content
  const ltrModules = pagedLtrModules?.content

  const treeRef = useRef<TreeRef | null>(null)

  const deviceTree = useMemo<TDeviceNode[]>(
    () => [
      {
        key: DeviceTypeId.Vp3701,
        title: DeviceTypeLabel.Vp3701,
        icon: <FolderOutlined />,
        content: EDeviceContent.VP_3701,
        children: vp3701List ? mapVp3701ForTree(vp3701List) : []
      },
      {
        key: DeviceTypeId.Vp3711,
        title: DeviceTypeLabel.Vp3711,
        icon: <FolderOutlined />,
        content: EDeviceContent.VP_3711,
        children: crates && ltrModules ? mapVp3711ForTree(crates, ltrModules) : []
      },
      {
        key: DeviceTypeId.Generator,
        title: DeviceTypeLabel.Generator,
        icon: <FolderOutlined />,
        content: EDeviceContent.GENERATOR,
        children: generators ? mapGeneratorForTree(generators) : []
      }
    ],
    [crates, generators, ltrModules, vp3701List]
  )

  useEffect(() => {
    if (selectedDeviceId) {
      const foundNode = findNode(deviceTree, selectedDeviceId)
      setSelectedKeys([selectedDeviceId])

      if (foundNode?.path && foundNode?.node) {
        setDeviceNode(foundNode.node)
        setExpandedKeys((prevState) => Array.from(new Set([...prevState, ...foundNode.path])))
        if (treeRef.current) {
          treeRef.current.scrollTo({ key: selectedDeviceId })
        }
      }
    }
  }, [deviceTree, selectedDeviceId, setDeviceNode])

  const handleDeviceSelect = (keys: Key[]) => {
    if (keys) {
      const idString = keys[0]?.toString()
      const foundNode = findNode<TDeviceNode>(deviceTree, idString)
      setSelectedDeviceId(idString)
      setDeviceContent(foundNode?.node.content)
      setDeviceNode(foundNode?.node)
      setSelectedKeys(keys)
    }
  }

  const handleExpand = (keys: Key[]) => {
    setExpandedKeys(keys)
  }

  return (
    <Tree
      className={styles['tree']}
      showIcon={true}
      showLine={true}
      treeData={deviceTree}
      onSelect={handleDeviceSelect}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      selectedKeys={selectedKeys}
    />
  )
}

export default DevicesTree
