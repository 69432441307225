import type { IServer } from '@/types/settings/server/server.interface'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './servers-select.module.css'

type TProps = {
  servers: IServer[]
  selectedServer: IServer | null
  activatedServer: IServer | null
  onSelect: (server: IServer) => void
}

const ServersSelect: FC<TProps> = ({ servers, selectedServer, activatedServer, onSelect }) => (
  <div className={styles['servers-select']}>
    <ul className={styles['header']}>
      <li className={styles['header-item']}>Имя сервера</li>
      <li className={styles['header-item']}>Адрес сервера</li>
    </ul>
    <ul className={styles['servers']}>
      {servers.map((server) => (
        <li
          key={server.id}
          className={cn(styles['servers-item'], {
            [styles['servers-item-selected']]: server.url === selectedServer?.url,
            [styles['servers-item-activated']]: server.url === activatedServer?.url
          })}
          onClick={() => onSelect(server)}
        >
          <p className={styles['servers-item-clm']}>{server.name}</p>
          <p className={styles['servers-item-clm']}>{server.url}</p>
        </li>
      ))}
    </ul>
  </div>
)

export default ServersSelect
