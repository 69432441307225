import type { INodesState } from '@/types/nodes/nodes.type'
import type { TPlan } from '@/types/plan/plan.type'

import type { TDataNode } from '../PlansList'
import { ENodeType } from '../PlansList'
import PlanMachineItem from '../components/plan-machine-item'
import PlanMeasurementItem from '../components/plan-measurement-Item'
import PlanPointItem from '../components/plan-point-item'
import SwitcherIconElement from '../components/switcher-icon-element'

const generatePlansTreeData = ({
  selectedPlan,
  dataAllNodesState
}: {
  selectedPlan: TPlan | undefined
  dataAllNodesState: INodesState[] | undefined
}) => {
  const treeData: TDataNode[] | undefined = selectedPlan?.machines?.map((machine) => ({
    key: machine.machineId,
    machineId: machine.machineId,
    selectable: false,
    nodeType: ENodeType.MACHINE,
    icon: ({ checked }) => <SwitcherIconElement checked={checked} />,
    title: (
      <PlanMachineItem
        dataAllNodesState={dataAllNodesState}
        name={machine.name}
        paused={machine.paused}
        id={machine.machineId}
      />
    ),
    children: machine?.points?.map((point) => ({
      // соглашение что id ключа составляется с nodeId + _ + selectedPlanId
      key: point.pointId,
      machineId: machine.machineId,
      pointId: point.pointId,
      selectable: false,
      nodeType: ENodeType.POINT,
      icon: ({ checked }) => <SwitcherIconElement checked={checked} />,
      title: (
        <PlanPointItem
          dataAllNodesState={dataAllNodesState}
          name={point.name}
          paused={point.paused}
          id={point.pointId}
        />
      ),
      children: point.measurements?.map((measurement) => ({
        key: measurement.measurementId,
        machineId: machine.machineId,
        pointId: point.pointId,
        measurementId: measurement.measurementId,
        selectable: false,
        isLeaf: true,
        nodeType: ENodeType.MEASUREMENT,
        icon: ({ checked }) => <SwitcherIconElement checked={checked} />,
        title: (
          <PlanMeasurementItem
            dataAllNodesState={dataAllNodesState}
            name={measurement.name}
            paused={measurement.paused}
            id={measurement.measuringPointId}
          />
        )
      }))
    }))
  }))

  return treeData
}

export default generatePlansTreeData
