import { SliceName } from '@/constants/store/slices.constant'
import type { IMachineInfo } from '@/types/machine.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { TPlan, TPlanSlice, TSortingValues } from '@/types/plan/plan.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: TPlanSlice = {
  selectedPlanId: null,
  plans: []
}

export const planSlice = createSlice({
  name: SliceName.Plan,
  initialState,
  reducers: {
    resetInitial(state) {
      state.selectedPlanId = null
      state.plans = []
    },

    addPlan(state, action: PayloadAction<TPlan>) {
      state.plans.push(action.payload)
    },

    deletePlan(state) {
      state.plans = state.plans.filter((plan) => plan.planId !== state.selectedPlanId)
      state.selectedPlanId = null
    },

    updatePlan(state, action: PayloadAction<TPlan>) {
      state.plans = state.plans.map((plan) => {
        if (action.payload.planId === plan.planId) {
          return action.payload
        }

        return plan
      })
    },

    sortingPlans(state, action: PayloadAction<TSortingValues>) {
      switch (action.payload) {
        case 'name':
          state.plans = state.plans.sort((a, b) =>
            a.name && b.name ? (a.name > b.name ? 1 : b.name > a.name ? -1 : 0) : 0
          )
          break

        case 'nameReverse':
          state.plans = state.plans.sort((a, b) =>
            a.name && b.name ? (a.name < b.name ? 1 : b.name < a.name ? -1 : 0) : 0
          )
          break

        case 'device':
          state.plans = state.plans.sort((a, b) =>
            a.deviceType && b.deviceType ? (a.deviceType > b.deviceType ? 1 : b.deviceType > a.deviceType ? -1 : 0) : 0
          )
          break

        case 'deviceReverse':
          state.plans = state.plans.sort((a, b) =>
            a.deviceType && b.deviceType ? (a.deviceType < b.deviceType ? 1 : b.deviceType < a.deviceType ? -1 : 0) : 0
          )
          break

        case 'statusReverse':
          state.plans = state.plans.sort((a, b) =>
            a.status && b.status ? (a.status < b.status ? 1 : b.status < a.status ? -1 : 0) : 0
          )
          break

        case 'status':
          state.plans = state.plans.sort((a, b) =>
            a.status && b.status ? (a.status > b.status ? 1 : b.status > a.status ? -1 : 0) : 0
          )
          break

        case 'modified':
          state.plans = state.plans.sort((a, b) =>
            a?.modified && b?.modified ? (a?.modified > b?.modified ? 1 : b?.modified > a?.modified ? -1 : 0) : 0
          )
          break

        case 'modifiedReverse':
          state.plans = state.plans.sort((a, b) =>
            a?.modified && b?.modified ? (a?.modified < b?.modified ? 1 : b?.modified < a?.modified ? -1 : 0) : 0
          )
          break

        case 'nextExecutionTimestampReverse':
          state.plans = state.plans.sort((a, b) =>
            a?.nextExecutionTimestamp && b?.nextExecutionTimestamp
              ? a?.nextExecutionTimestamp < b?.nextExecutionTimestamp
                ? 1
                : b?.nextExecutionTimestamp < a?.nextExecutionTimestamp
                ? -1
                : 0
              : 0
          )
          break

        case 'nextExecutionTimestamp':
          state.plans = state.plans.sort((a, b) =>
            a.nextExecutionTimestamp && b.nextExecutionTimestamp
              ? a.nextExecutionTimestamp > b.nextExecutionTimestamp
                ? 1
                : b.nextExecutionTimestamp > a.nextExecutionTimestamp
                ? -1
                : 0
              : 0
          )
          break

        case 'lastExecutionTimestampReverse':
          state.plans = state.plans.sort((a, b) =>
            a.lastExecutionTimestamp && b.lastExecutionTimestamp
              ? a.lastExecutionTimestamp < b.lastExecutionTimestamp
                ? 1
                : b.lastExecutionTimestamp < a.lastExecutionTimestamp
                ? -1
                : 0
              : 0
          )
          break

        case 'lastExecutionTimestamp':
          state.plans = state.plans.sort((a, b) =>
            a.lastExecutionTimestamp && b.lastExecutionTimestamp
              ? a.lastExecutionTimestamp > b.lastExecutionTimestamp
                ? 1
                : b.lastExecutionTimestamp > a.lastExecutionTimestamp
                ? -1
                : 0
              : 0
          )
          break

        default:
          state.plans = state.plans.sort((a, b) =>
            a.name && b.name ? (a.name > b.name ? 1 : b.name > a.name ? -1 : 0) : 0
          )
          break
      }
    },

    setSelectedPlan(state, action: PayloadAction<string>) {
      state.selectedPlanId = action.payload
    },

    addMachineInPlan(state, action: PayloadAction<IMachineInfo>) {
      const selectedMachine = action.payload

      state.plans = state.plans.map((plan) => {
        const isHaveMachine = plan?.machineIds?.includes(selectedMachine?.machineId)

        if (plan.planId === state.selectedPlanId && !isHaveMachine) {
          plan?.machineIds?.push(selectedMachine?.machineId)

          plan?.machines?.push({
            ...selectedMachine,
            points: []
          })
        }

        return plan
      })
    },

    addPoints(
      state,
      action: PayloadAction<{
        machine: IMachineInfo
        points: ISelectedMeasuringPoint[]
      }>
    ) {
      state.plans = state.plans.map((plan) => {
        if (plan.planId === state.selectedPlanId) {
          plan?.machines?.forEach((machine) => {
            if (machine.machineId === action.payload.machine.machineId) {
              if (action.payload.points?.length === 0) {
                machine.points = null

                return
              }

              action.payload.points.forEach((point) => {
                if (!machine?.points?.find((pointData) => pointData.pointId === point.pointId)) {
                  machine?.points?.push({
                    ...point,
                    measurements: []
                  })
                }
              })
            }
          })
        }

        return plan
      })
    },

    addMeasurements(
      state,
      action: PayloadAction<{
        selectedPlan: TPlan
        machine: IMachineInfo
        point: ISelectedMeasuringPoint
        measurements: IMeasurement[]
      }>
    ) {
      state.plans = state.plans.map((plan) => {
        if (plan.planId === action.payload.selectedPlan?.planId) {
          plan?.machines?.forEach((machine) => {
            if (machine.machineId === action.payload.machine.machineId) {
              machine?.points?.forEach((point) => {
                if (action.payload.point.pointId === point.pointId) {
                  if (action.payload.measurements.length === 0) {
                    point.measurements = null

                    return
                  }

                  action.payload.measurements.forEach((measurement) => {
                    if (
                      !point?.measurements?.find(
                        (measurementData) => measurementData.measurementId === measurement.measuringPointId
                      )
                    ) {
                      point?.measurements?.push({
                        ...measurement
                      })
                    }
                  })
                }
              })
            }
          })
        }

        return plan
      })
    },

    excludeEquipment(
      state,
      action: PayloadAction<{
        plan: TPlan
      }>
    ) {
      state.plans = state.plans.map((plan) => {
        if (plan.planId === state.selectedPlanId) {
          plan = action.payload.plan
        }

        return plan
      })
    }
  }
})
