import {
  CdParamName,
  CdTypeOut
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form.constant'
import type {
  ICdOuts,
  IFormCd
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form.type'
import {
  KbParam,
  TypeSource
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { ITableDataOuts } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import {
  calculateValue,
  formatOutValues,
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import type { IIn, IKinematicElement, IOut } from '@/types/kinematic/kinematic.type'
import { isNumber } from '@/utils/common'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockCD, outs } = kinematicElement

  interface IResult {
    fields: IFormCd
    ins: IIn[] | null
    outs: ICdOuts | null
  }

  const result: IResult = {
    fields: {
      [KbParam.Description]: description,
      [KbParam.TypeSource]: TypeSource.Missing,
      [KbParam.FreqCoefForIn]: ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [KbParam.KinematicBlockSourceId]: null,
      [KbParam.FreqOutSource]: null,
      [CdParamName.Z1]: null,
      [CdParamName.Z2]: null,
      [CdParamName.Z3]: null
    },
    ins: null,
    outs: null
  }

  if (kinematicBlockCD) {
    for (const key in kinematicBlockCD) {
      type TKey = keyof typeof kinematicBlockCD
      result.fields[key as TKey] = kinematicBlockCD[key as TKey]
    }
  }

  if (ins) {
    result.fields[KbParam.TypeSource] = ins[0].kinematicBlockSourseId ? TypeSource.Kb : TypeSource.Missing
    result.fields[KbParam.KinematicBlockSourceId] = ins[0].kinematicBlockSourseId
    result.fields[KbParam.FreqOutSource] = ins[0].freqInValue
    result.ins = ins
  }

  if (outs) {
    result.outs = Object.values(CdTypeOut).reduce((acc, value, index) => {
      acc[value] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<CdTypeOut, IOut>)
  }
  return result
}

export const getCalculatedResults = (fieldsValue: IFormCd, ins: IIn[], kinematicBlockName: string) => {
  const { freqOutSource, freqCoefForIn, z1, z2, z3 } = fieldsValue
  const { freqOutCoefMachineSourse, freqOutNameSourse } = ins[0]

  const freqInValue = calculateValue(freqOutSource, freqCoefForIn)
  const freqInCoefMachine = calculateValue(freqOutCoefMachineSourse, freqCoefForIn)

  const coefKbFo2 = isNumber(z1) && isNumber(z2) ? z1 / z2 : null
  const coefKbFz = isNumber(z1) && isNumber(z3) ? z1 / z3 : null

  const result: ICdOuts = {
    [CdTypeOut.Frcd]: {
      freqOutName: freqOutNameSourse,
      freqOutCoefKb: freqCoefForIn || null,
      freqOutCoefMachine: freqInCoefMachine,
      freqOutValue: freqInValue
    },
    [CdTypeOut.Fo2]: {
      freqOutName: `${kinematicBlockName}_Fo2`,
      freqOutCoefKb: coefKbFo2,
      freqOutCoefMachine: calculateValue(freqInCoefMachine, coefKbFo2),
      freqOutValue: calculateValue(freqInValue, freqInValue)
    },
    [CdTypeOut.Fz]: {
      freqOutName: `${kinematicBlockName}_Fz`,
      freqOutCoefKb: coefKbFz,
      freqOutCoefMachine: calculateValue(freqInCoefMachine, coefKbFz),
      freqOutValue: calculateValue(freqInValue, freqInValue)
    }
  }

  return formatOutValues<ICdOuts>(result)
}

export const parseDataForChange = (
  kinematicElement: IKinematicElement,
  fields: IFormCd,
  ins: IIn[],
  tgbOuts: ICdOuts
): IKinematicElement => {
  const { description, kinematicBlockSourseId, freqCoefForIn } = fields

  return {
    ...kinematicElement,
    description: description,
    kinematicBlockCD: {
      z1: fields.z1,
      z2: fields.z2,
      z3: fields.z3
    },
    ins: [
      {
        freqInName: ins[0].freqOutNameSourse,
        freqInValue: ins[0].freqInValue,
        freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
        freqInCoefMachine:
          isNumber(freqCoefForIn) && isNumber(ins[0].freqOutCoefMachineSourse)
            ? ins[0].freqOutCoefMachineSourse * freqCoefForIn
            : 1,
        kinematicBlockSourseId: kinematicBlockSourseId,
        freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse || 0,
        freqOutNameSourse: ins[0].freqOutNameSourse || 'Fin1',
        freqCoefForIn: isNumber(freqCoefForIn) ? freqCoefForIn : 1,
        measurementSourseId: null
      }
    ],
    outs: Object.values(tgbOuts)
  }
}

export const formatDataForTable = (outs: ICdOuts): ITableDataOuts[] => {
  const { frcd, fo2, fz } = outs

  const data: Omit<ITableDataOuts, 'key'>[] = [
    {
      label: 'Частота вращения',
      freqOutName: handleName(frcd.freqOutName),
      freqOutCoefKb: handleValue(frcd.freqOutCoefKb),
      freqOutCoefMachine: handleValue(frcd.freqOutCoefMachine),
      freqOutValue: handleValue(frcd.freqOutValue)
    },
    {
      label: 'Частота вращения второго (выходного) вала',
      freqOutName: handleName(fo2.freqOutName),
      freqOutCoefKb: handleValue(fo2.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fo2.freqOutCoefMachine),
      freqOutValue: handleValue(fo2.freqOutValue)
    },
    {
      label: 'Зубцовая частота',
      freqOutName: handleName(fz.freqOutName),
      freqOutCoefKb: handleValue(fz.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fz.freqOutCoefMachine),
      freqOutValue: handleValue(fz.freqOutValue)
    }
  ]

  return data.map<ITableDataOuts>((item, key) => ({ ...item, key }))
}
