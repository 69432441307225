import { calculateDecibel } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import { EAmplitudeMode } from '@/enums/charts/chart-value-mode.enum'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IChartSpectrum, IValuesMode } from '@/types/chart/chart-spectrun.type'
import { vibrationConverter } from '@/utils/unit/unit-converter.util'
import { max, min } from 'lodash'

export const getSpectrumChartData = (
  sourceData: number[],
  deltaFrequency: number,
  valuesMode: IValuesMode,
  sourceUnit: EUnitType,
  targetUnit: EUnitType
): IChartSpectrum[] =>
  sourceData.map((sourceItem, index) => {
    // const amplitude = Math.sqrt(sourceItem)
    const frequency = deltaFrequency * index + deltaFrequency
    const amplitude = vibrationConverter(sourceItem, frequency, sourceUnit, targetUnit)

    const mapConvertAmplitude: Record<EAmplitudeMode, () => number> = {
      [EAmplitudeMode.LINER]: () => amplitude,
      [EAmplitudeMode.DB]: () => calculateDecibel(amplitude),
      [EAmplitudeMode.LOG]: () => amplitude
    }

    const convertedAmplitudeByMode = mapConvertAmplitude[valuesMode.amplitudeMode]()

    return {
      amplitude: convertedAmplitudeByMode,
      frequency: frequency,
      index: index
    }
  })

export const getAmplitudeRange = (
  chartData: IChartSpectrum[],
  sliderIndexes: number[],
  amplitudeSliderDisabled: boolean
): number[] => {
  let amplitudes = chartData.map((element) => element.amplitude)
  if (amplitudeSliderDisabled) {
    amplitudes = amplitudes.slice(sliderIndexes[0], sliderIndexes[1])
  }

  const minAmplitude = min(amplitudes) as number
  const maxAmplitude = max(amplitudes) as number

  return [minAmplitude, maxAmplitude]
}
