import Loader from '@/components/UI/elements/loader/loader'
import ContextMenu from '@/components/mnemonic/ui/context-menu/context-menu'
import { useGetCurrentMnemoScheme } from '@/hooks/api/use-get-current-mnemo-scheme'
import { useGetMnemoStats } from '@/hooks/api/use-get-mnemo-stats'
import useActions from '@/hooks/use-actions'
import { useChangeMnemonicElementMutation, useGetMnemonicElementsQuery } from '@/store/api/mnemo.api'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import { errorNotificationCreate } from '@/utils/notification-creators'
import type { FC } from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Layer, Rect, Stage } from 'react-konva'

import styles from '@/components/mnemonic/mnemonic-main/mnemonic-main.module.css'

import DangerStateIndicator from '../mnemonic-elements/danger-state-indicator/danger-state-indicator'
import MeasurementLastValueElement from '../mnemonic-elements/measurement-last-value-element/measurement-last-value-element'
import MeasurementWithThresholdIndicatorElement from '../mnemonic-elements/measurement-with-threshold-indicator-element/measurement-with-threshold-indicator-element'
import StaticImageElement from '../mnemonic-elements/static-image-element/static-image-element'
import StaticLabelElement from '../mnemonic-elements/static-label-element/static-label-element'
import StatisticalIndicatorElement from '../mnemonic-elements/statistical-indicator-element/statistical-indicator-element'

type TProps = {
  condition?: boolean
}

export type TContextOptions = {
  isOpen: boolean
  position: { x: number; y: number }
}

const MnemonicMain: FC<TProps> = ({ condition }) => {
  const mainRef = useRef<HTMLDivElement>(null)

  const CANVAS_VIRTUAL_WIDTH = mainRef.current?.clientWidth
  const CANVAS_VIRTUAL_HEIGHT = mainRef.current?.clientHeight
  const PADDING = 20
  const parentSize =
    CANVAS_VIRTUAL_WIDTH && CANVAS_VIRTUAL_HEIGHT
      ? { width: CANVAS_VIRTUAL_WIDTH, height: CANVAS_VIRTUAL_HEIGHT }
      : undefined

  const scale = parentSize
    ? Math.min((parentSize.width - PADDING) / parentSize.width, (parentSize.height - PADDING) / parentSize.height)
    : undefined

  const { setMnemoSchemeItem } = useActions()

  const [sortedMnemonicElements, setSortedMnemonicElements] = useState<TMnemoSchemeItem[]>([])
  const [contextMenuOptions, setContextMenuOptions] = useState<TContextOptions>({
    isOpen: false,
    position: { x: 0, y: 0 }
  })

  const { data: currentMnemoSchemeData, mnemonicSchemeData } = useGetCurrentMnemoScheme()

  const [changeMnemonicElement] = useChangeMnemonicElementMutation()

  const { refetch: refetchMnemoStats, isLoading: isLoadingMnemoStats } = useGetMnemoStats()

  const {
    data: mnemonicElements,
    isLoading: isLoadingMnemonic,
    isSuccess: isSuccessMnemonic,
    isFetching: isFetchingMnemonic
  } = useGetMnemonicElementsQuery(currentMnemoSchemeData?.mnemoSchemeId || '', {
    skip: !currentMnemoSchemeData?.mnemoSchemeId
  })

  const updateMnemonicElement = async (mnemonicElement: TMnemoSchemeItem) => {
    if (mnemonicElement?.mnemoElementId) {
      try {
        const data = await changeMnemonicElement(mnemonicElement).unwrap()

        if (data !== null) throw new Error('Ошибка соединения')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  useLayoutEffect(() => {
    if (isSuccessMnemonic && mnemonicElements?.content && !isFetchingMnemonic) {
      setSortedMnemonicElements([...mnemonicElements.content].sort((prev) => (prev?.staticImageElement ? -1 : 1)))
    }
  }, [isSuccessMnemonic, mnemonicElements?.content, isFetchingMnemonic])

  useEffect(() => {
    if (condition && currentMnemoSchemeData?.mnemoSchemeId) {
      const autoQueries = setInterval(refetchMnemoStats, 2000)

      return () => {
        clearInterval(autoQueries)
      }
    }
  }, [condition, currentMnemoSchemeData?.mnemoSchemeId, refetchMnemoStats])

  useEffect(() => {
    function closeContextMenu() {
      setContextMenuOptions({ isOpen: false, position: { x: 0, y: 0 } })
    }

    window.addEventListener('click', closeContextMenu)

    return () => {
      window.removeEventListener('click', closeContextMenu)
    }
  }, [])

  return (
    <div
      className={styles.main}
      ref={mainRef}
      onClick={(evt) => {
        if (evt.target === evt.currentTarget) {
          setMnemoSchemeItem({
            mnemoSchemeId: '',
            mnemoElementId: '',
            name: '',
            x: 0,
            y: 0
          })
        }
      }}
    >
      <Loader isLoading={isLoadingMnemonic || isLoadingMnemoStats || isFetchingMnemonic} />

      {contextMenuOptions.isOpen ? (
        <ContextMenu
          draggable={!condition}
          contextMenuOptions={contextMenuOptions}
          setContextMenuOptions={setContextMenuOptions}
        />
      ) : null}
      <Stage
        scaleX={scale}
        scaleY={scale}
        width={mainRef.current?.clientWidth}
        height={mainRef.current?.clientHeight}
        className={styles['stage']}
      >
        <Layer>
          <Rect
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setMnemoSchemeItem({
                  x: 0,
                  y: 0,
                  name: '',
                  mnemoSchemeId: ''
                })
              }
            }}
            x={0}
            y={0}
            width={CANVAS_VIRTUAL_WIDTH}
            height={CANVAS_VIRTUAL_HEIGHT}
            strokeWidth={1}
          />

          {mnemonicSchemeData?.content.length
            ? sortedMnemonicElements.map((tool) => {
                if (tool.dangerStateIndicatorElement) {
                  return (
                    <DangerStateIndicator
                      setContextMenuOptions={setContextMenuOptions}
                      updateMnemonicElement={updateMnemonicElement}
                      draggable={!condition}
                      key={tool.mnemoElementId}
                      element={tool}
                      parentSize={parentSize}
                    />
                  )
                } else if (tool.measurementLastValueElement) {
                  return (
                    <MeasurementLastValueElement
                      updateMnemonicElement={updateMnemonicElement}
                      setContextMenuOptions={setContextMenuOptions}
                      draggable={!condition}
                      key={tool.mnemoElementId}
                      element={tool}
                      parentSize={parentSize}
                    />
                  )
                } else if (tool.staticImageElement) {
                  return (
                    <StaticImageElement
                      updateMnemonicElement={updateMnemonicElement}
                      draggable={!condition}
                      key={tool.mnemoElementId}
                      element={tool}
                      parentSize={parentSize}
                    />
                  )
                } else if (tool.staticLabelElement) {
                  return (
                    <StaticLabelElement
                      updateMnemonicElement={updateMnemonicElement}
                      setContextMenuOptions={setContextMenuOptions}
                      parentSize={parentSize}
                      key={tool.mnemoElementId}
                      draggable={!condition}
                      element={tool}
                    />
                  )
                } else if (tool.measurementWithThresholdIndicatorElement) {
                  return (
                    <MeasurementWithThresholdIndicatorElement
                      updateMnemonicElement={updateMnemonicElement}
                      setContextMenuOptions={setContextMenuOptions}
                      parentSize={parentSize}
                      key={tool.mnemoElementId}
                      draggable={!condition}
                      element={tool}
                    />
                  )
                } else if (tool.statisticalIndicatorElement) {
                  return (
                    <StatisticalIndicatorElement
                      updateMnemonicElement={updateMnemonicElement}
                      setContextMenuOptions={setContextMenuOptions}
                      parentSize={parentSize}
                      key={tool.mnemoElementId}
                      draggable={!condition}
                      element={tool}
                    />
                  )
                }
              })
            : null}
        </Layer>
      </Stage>
    </div>
  )
}

export default MnemonicMain
