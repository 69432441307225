import CharFreqTreeSelect from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/char-freq-tree-select/char-freq-tree-select'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/UI/panel/panel-title/panel-title'
import { useGetKinematicElements } from '@/hooks/api/use-get-kinematic-elements'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { ICharFreqTree } from '@/types/kinematic/char-freq.type'
import type { ModalProps } from 'antd'
import { InputNumber, Modal } from 'antd'
import Form, { useForm } from 'antd/es/form/Form'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'

type TProps = {
  onClose: () => void
} & ModalProps

interface IForm {
  charFreq: number
  strobeStart: number
  strobePeriod: number
  strobeDuration: number
}

const StrobeModal: FC<TProps> = ({ onClose, ...props }) => {
  const [form] = useForm<IForm>()
  const { setStrobeData } = useActions()
  const [expressFreq, setExpressFreq] = useState(0)
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { kinematicElementsQuery } = useGetKinematicElements(selectedMachineId)
  const { data: elements } = kinematicElementsQuery

  const { strobes, selectedResultIndex } = useTypedSelector(
    (state) => ({
      ...state.expressStrobeReducer,
      ...state.chartsUiReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )
  const strobeData = strobes[selectedResultIndex]?.strobeData

  useEffect(() => {
    const frequency = strobeData?.period !== undefined ? Number((1 / strobeData.period)?.toPrecision(2)) : 0
    const start = strobeData?.start !== undefined ? Number(strobeData.start.toPrecision(3)) : 0
    const period = strobeData?.period !== undefined ? Number(strobeData.period.toPrecision(3)) : 0
    const duration = strobeData?.duration !== undefined ? Number(strobeData.duration.toPrecision(3)) : 0

    setExpressFreq(frequency)
    form.setFieldsValue({
      strobeStart: start,
      strobePeriod: period,
      strobeDuration: duration
    })
  }, [form, strobeData])

  const handleOnFinish = () => {
    const values = form.getFieldsValue()
    setStrobeData({
      index: selectedResultIndex,
      strobeData: {
        start: Number(values.strobeStart),
        duration: Number(values.strobeDuration),
        period: Number(values.strobePeriod)
      }
    })
    onClose()
  }

  const getValidMaxValue = () => {
    const strobePeriod = form.getFieldValue('strobePeriod')
    return strobePeriod - strobePeriod * 0.01
  }

  const handleCharFreqForMainSelect = (value: ICharFreqTree['value'], options: ICharFreqTree) => {
    const frequencyOutValue = options?.payload?.freqOutValue

    if (frequencyOutValue) {
      const frequency = Number(frequencyOutValue.toPrecision(2))
      const period = 1 / frequency
      setExpressFreq(frequency)
      form.setFieldsValue({
        strobeStart: 0,
        strobePeriod: Number(period.toPrecision(3)),
        strobeDuration: Number((period / 2).toPrecision(3))
      })
    }
  }

  return (
    <Modal onCancel={onClose} {...props} centered={true} width={800} footer={false} maskClosable={false}>
      <Form onFinish={handleOnFinish} form={form}>
        <PanelTitle>Управление стробами</PanelTitle>
        <FormItemApp label='Характреные частоты' name='charFreq'>
          <CharFreqTreeSelect kinematicElements={elements?.content} onSelect={handleCharFreqForMainSelect} />
        </FormItemApp>
        <TextStatic label='Частота, Гц' value={expressFreq} />
        <FormItemApp label='Старт, cек' name='strobeStart' required={true}>
          <InputNumber min={0} />
        </FormItemApp>

        <FormItemApp label='Период, cек' name='strobePeriod' required={true}>
          <InputNumber min={0} />
        </FormItemApp>

        <FormItemApp label='Длительность, cек' name='strobeDuration' required={true}>
          <InputNumber min={0} max={getValidMaxValue()} />
        </FormItemApp>

        <ButtonsWrapper>
          <ButtonPrimary htmlType={'button'} title={'Отмена'} onClick={onClose} />
          <ButtonPrimary htmlType={'submit'} title={'Сохранить'} />
        </ButtonsWrapper>
      </Form>
    </Modal>
  )
}

export default StrobeModal
