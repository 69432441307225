import type { FC } from 'react'
import type { LabelProps } from 'recharts'

type TProps = {
  value?: number
} & LabelProps

const OFFSET = 10

const CustomLabel: FC<TProps> = ({ value, viewBox }) => {
  const { x, y } = viewBox as { x: number; y: number }
  const textWidth = Number(String(value)?.length || 1) * OFFSET // Ширина фона на основе длины текста

  return (
    <g>
      <rect x={x + OFFSET} y={y - 18} width={textWidth} height={15} fill='white' opacity={0.9} />

      <text x={OFFSET + x + textWidth / 2} y={y - 5} fontSize='14' fill='black' textAnchor='middle'>
        {value ?? ''}
      </text>
    </g>
  )
}

export default CustomLabel
