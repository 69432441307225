import {
  AdParamName,
  optionsFreqRegul
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.constant'
import type {
  IFormAd,
  IInitialData,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.type'
import {
  getCalculatedResults,
  parseDataForChangeAd,
  parseServerData
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.util'
import { OutTable } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/components/out-table/out-table'
import { ControlPanel } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateMinValue } from '@/utils/validation-rules'
import { Form, InputNumber, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '../../drawer-kinematic.module.css'

type TAdForm = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const AdForm: FC<TAdForm> = ({ onClose, kinematicElement, image }) => {
  const [changeKinematicElement, { isLoading: isUpdating }] = useChangeKinematicElementMutation()
  const [adForm] = useForm<IFormAd>()
  const [initialData, setInitialData] = useState<IInitialData | null>(null)
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [disabledCalculateButton, setDisabledCalculateButton] = useState(true)
  const [outs, setOuts] = useState<IOuts | null>(null)
  const fieldsObservable = useWatch([], adForm)

  // Валидация через отключение кнопки
  useEffect(() => {
    const { validateFields, getFieldsValue } = adForm
    const isInitialData = isEqual(initialData?.fields, getFieldsValue())

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields([AdParamName.Fu, AdParamName.N2p, AdParamName.Nr, AdParamName.Ns], {
          validateOnly: true,
          recursive: true
        })
        if (!isInitialData) {
          setDisabledCalculateButton(false)
        }
      } catch (e) {
        setDisabledCalculateButton(true)
      }
    }

    if (!disabledCancelButton) {
      checkValidateFields()
    }
  }, [adForm, initialData, fieldsObservable, disabledCancelButton])

  // Отключение кнопки "Отмена"
  useEffect(() => {
    const { getFieldsValue } = adForm
    const isInitialData = isEqual(initialData?.fields, getFieldsValue())

    setDisabledCancelButton(isInitialData)
    setDisabledCalculateButton(isInitialData)
  }, [adForm, fieldsObservable, initialData])

  useEffect(() => {
    setDisabledFinishButton(true)
  }, [fieldsObservable])

  // Загрузка данных в форму
  useEffect(() => {
    const { setFieldsValue, resetFields } = adForm
    resetFields()
    setOuts(null)

    if (kinematicElement) {
      const { fieldsData, outsData } = parseServerData(kinematicElement)

      setFieldsValue(fieldsData)
      setOuts(outsData)
      setInitialData({
        fields: fieldsData,
        outs: outsData
      })
    }
  }, [adForm, kinematicElement])

  const handleFinish = async (): Promise<void> => {
    const { getFieldsValue, validateFields } = adForm
    const fieldValues = getFieldsValue()

    try {
      try {
        await validateFields()
      } catch (e) {
        errorNotificationCreate(e, {
          message: 'Форма содержит ошибки'
        })
        return
      }
      if (kinematicElement && outs) {
        const requestDataParsed = parseDataForChangeAd(kinematicElement, fieldValues, outs)

        const { kinematicBlockName } = await changeKinematicElement(requestDataParsed).unwrap()

        successNotificationCreate(`Кинематический блок ${kinematicBlockName} сохранён`)

        onClose()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCalculation = async () => {
    const { getFieldsValue, validateFields } = adForm
    const fieldValues = getFieldsValue()

    try {
      await validateFields([AdParamName.Fu, AdParamName.N2p, AdParamName.Nr])

      const { values, names } = getCalculatedResults(fieldValues, kinematicElement?.kinematicBlockName)

      setOuts({
        names,
        values
      })
      successNotificationCreate('Коэффициенты успешно рассчитаны')

      setDisabledFinishButton(false)
      setDisabledCalculateButton(true)
    } catch (e) {
      setDisabledFinishButton(true)
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = adForm
      resetFields()
      setFieldsValue(initialData.fields)
      setOuts(initialData.outs)
      setDisabledFinishButton(true)
    }
  }

  return (
    <>
      <Form className={styles['drawer-kinematic-form']} form={adForm}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
        <div className={styles['drawer-kinematic-header']}>
          <Form.Item name={AdParamName.Descriptipn} className={styles['drawer-kinematic-text-area']}>
            <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} disabled={isUpdating} />
          </Form.Item>
          <div className={styles['drawer-kinematic-image-wrapper']}>
            <img className={styles['drawer-kinematic-image']} src={image} alt='' />
          </div>
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Вход</h2>
        <Form.Item
          label='Коэффициент частоты КБ'
          name={AdParamName.FreqCoefForIn}
          tooltip='Не реализовано!'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Введите коэффициент входной частоты'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            className={styles['drawer-kinematic-input-number']}
            placeholder='Введите коэффициент входной частоты'
            min={0}
            disabled={true}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Параметры</h2>
        <Form.Item
          name={AdParamName.Fu}
          label='Частота питающей сети, Гц'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать частоту питающей сети'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            min={0}
            placeholder='Введите частоту питающей сети'
            className={styles['drawer-kinematic-input-number']}
            disabled={isUpdating}
          />
        </Form.Item>
        <Form.Item
          name={AdParamName.N2p}
          label='Количество пар полюсов'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество пар полюсов'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            min={0}
            placeholder='Введите количество пар полюсов'
            className={styles['drawer-kinematic-input-number']}
            disabled={isUpdating}
          />
        </Form.Item>
        <Form.Item
          label='Частотное регулирование'
          name={AdParamName.FreqRegulField}
          className={styles['drawer-kinematic-input-item']}
          tooltip='Не реализовано!'
        >
          <Select placeholder='Не реализовано' options={optionsFreqRegul} disabled={true} />
        </Form.Item>
        <Form.Item
          name={AdParamName.Nr}
          label='Количество пазов ротора'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество пазов ротора'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество пазов ротора'
            className={styles['drawer-kinematic-input-number']}
            min={0}
            disabled={isUpdating}
          />
        </Form.Item>
        <Form.Item
          name={AdParamName.Ns}
          label='Количество пазов статора'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            min={0}
            placeholder='Введите количество пазов стартора'
            className={styles['drawer-kinematic-input-number']}
            disabled={isUpdating}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Выход (out1)</h2>
        <OutTable outs={outs} />
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickCalculation={handleCalculation}
        onClickSave={handleFinish}
        disabledCalculation={disabledCalculateButton}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </>
  )
}

export default AdForm
