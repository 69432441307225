import { EMnemoElementType } from '@/enums/mnemo/indicator-type.enum'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'

export const prepareIndicatorData = (
  mnemoElementType: EMnemoElementType,
  mnemoSchemeId: string,
  totalElement?: number,
  selectedMachineId?: string,
  selectedPointId?: string,
  selectedMeasurementId?: string
): TMnemoSchemeItem => {
  const name = `Индикатор ${totalElement ? totalElement + 1 : 1}`
  const currentId = selectedMeasurementId
    ? { measurementId: selectedMeasurementId }
    : selectedPointId
    ? { measuringPointId: selectedPointId }
    : { machineId: selectedMachineId }

  let dangerStateIndicatorElement = undefined
  let statisticalIndicatorElement = undefined
  let measurementLastValueElement = undefined
  let measurementWithThresholdIndicatorElement = undefined
  let staticLabelElement = undefined

  if (mnemoElementType === EMnemoElementType.CIRCLE_INDICATOR) {
    dangerStateIndicatorElement = {
      ...currentId,
      width: 30,
      height: 30
    }
  }

  if (mnemoElementType === EMnemoElementType.STATISTICAL_INDICATOR) {
    statisticalIndicatorElement = {
      ...currentId,
      width: 30,
      height: 30
    }
  }

  if (mnemoElementType === EMnemoElementType.MEASUREMENT_LAST_VALUE) {
    measurementLastValueElement = {
      ...currentId
    }
  }

  if (mnemoElementType === EMnemoElementType.MEASUREMENT_WITH_THRESHOLD_INDICATOR) {
    measurementWithThresholdIndicatorElement = {
      measurementId: selectedMeasurementId,
      height: 80,
      width: 15,
      horizontal: false
    }
  }

  if (mnemoElementType === EMnemoElementType.TEXT_INDICATOR) {
    staticLabelElement = {
      text: 'Текст',
      fontStyle: { fontSize: 14, color: '#000' }
    }
  }

  return {
    mnemoSchemeId: mnemoSchemeId,
    name: name,
    x: 10,
    y: 10,
    dangerStateIndicatorElement,
    statisticalIndicatorElement,
    measurementLastValueElement,
    measurementWithThresholdIndicatorElement,
    staticLabelElement
  }
}
