import ZoneItem from '@/app/configuration/components/equipments/components/measurements/components/threshold/zone-item/zone-item'
import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import { riftCalcTypeLabels } from '@/constants/threshold/threshold.constant'
import { EFormMode } from '@/enums/threshold/form-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IThresholdForm } from '@/types/threshold/threshold.type'
import type { FormInstance, RadioChangeEvent } from 'antd'
import { Radio } from 'antd'
import { isUndefined } from 'lodash'
import type { FC } from 'react'
import React from 'react'

import styles from './zones-table.module.css'

interface IProps {
  thresholdForm: FormInstance<IThresholdForm>
}

export const ZonesTable: FC<IProps> = ({ thresholdForm }) => {
  const { setIsOpenThresholdPopup, setFormMode, setSelectedRift } = useActions()
  const { zones, rifts, selectedRift, riftCalcEnabled, riftCalcType } = useTypedSelector(
    (state) => state.thresholdReducer
  )
  const handleAddZone = () => {
    setFormMode(EFormMode.ADD)
    setIsOpenThresholdPopup(true)
  }

  const handleRemoveZone = () => {
    setFormMode(EFormMode.REMOVE)
    setIsOpenThresholdPopup(true)
  }

  const handleEditZone = () => {
    setFormMode(EFormMode.EDIT)
    setIsOpenThresholdPopup(true)
  }

  const onChange = (e: RadioChangeEvent) => {
    setSelectedRift(e.target.value)
  }

  return (
    <Radio.Group onChange={onChange} value={selectedRift} className={styles['table']}>
      <table>
        <thead>
          <tr>
            <th colSpan={4}>
              <div className={styles['table-header']}>
                <div className={styles['table-controls']}>
                  <ButtonToolbar placement={'top'} icon={'add'} onClick={handleAddZone} title={'Добавить границу'} />
                  <ButtonToolbar
                    placement={'top'}
                    icon={'edit'}
                    title={'Редактировать границу'}
                    onClick={handleEditZone}
                    disabled={isUndefined(selectedRift)}
                  />
                  <ButtonToolbar
                    placement={'top'}
                    icon={'delete'}
                    title={'Удалить порог'}
                    onClick={handleRemoveZone}
                    disabled={rifts.length === 1}
                  />
                </div>
                <h4>Зоны контроля</h4>
              </div>
            </th>
          </tr>
          <tr>
            <th />
            {riftCalcEnabled && <th>{riftCalcTypeLabels[riftCalcType]}</th>}
            <th>Граница</th>
            <th>Гистерезис</th>
          </tr>
        </thead>
        <tbody>
          {rifts.length !== 0 ? (
            zones.map((_, index) => (
              <ZoneItem key={index} index={index} rift={rifts[index]} thresholdForm={thresholdForm} />
            ))
          ) : (
            <tr>
              <td colSpan={3}>Зоны контроля отсутствуют</td>
            </tr>
          )}
        </tbody>
      </table>
    </Radio.Group>
  )
}

export default ZonesTable
