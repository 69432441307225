import cn from 'classnames'
import type { FC } from 'react'

import styles from './status-indicator.module.css'

interface IProps {
  isOnline?: boolean
  isWorking?: boolean
}

const StatusIndicator: FC<IProps> = ({ isOnline, isWorking }) => (
  <div
    className={cn(styles['indicator'], {
      [styles['indicator-online']]: isOnline,
      [styles['indicator-online-working']]: isWorking,
      [styles['indicator-offline']]: !isOnline
    })}
  />
)

export default StatusIndicator
