import { CLOUD_NAME, CLOUD_URL, LOCAL_NAME, LOCAL_URL } from '@/config/connection.config'
import { ACTIVE_SERVER_KEY, SERVERS_KEY } from '@/constants/settings/server/server.constant'
import type { IServer } from '@/types/settings/server/server.interface'
import { v4 as uuidv4 } from 'uuid'

export class ServerStorage {
  private readonly serversKey: string
  private readonly activeServer: string

  constructor(serversKey: string, activeServer: string) {
    this.serversKey = serversKey
    this.activeServer = activeServer
  }

  // Получение массива адресов из localStorage
  public getServers(): IServer[] {
    const savedServers = localStorage.getItem(this.serversKey)
    if (!savedServers) {
      const defaultServers: IServer[] = [
        {
          id: uuidv4(),
          name: CLOUD_NAME,
          url: CLOUD_URL
        },
        {
          id: uuidv4(),
          name: LOCAL_NAME,
          url: LOCAL_URL
        }
      ]

      this.setServers(defaultServers)
      return defaultServers
    }
    return savedServers ? JSON.parse(savedServers) : []
  }

  public setServers(servers: IServer[]) {
    localStorage.setItem(this.serversKey, JSON.stringify(servers))
  }

  public setActiveServer(activeServer: IServer) {
    localStorage.setItem(this.activeServer, JSON.stringify(activeServer))
  }

  public getActiveServer(): IServer {
    const savedAddresses = localStorage.getItem(this.activeServer)

    if (!savedAddresses) {
      const activeDefaultServer = this.getServers().find((element) => element.url === LOCAL_URL) as IServer
      this.setActiveServer(activeDefaultServer)
      return activeDefaultServer
    }
    return JSON.parse(savedAddresses)
  }
}

// Создаем экземпляр класса с указанным ключом для localStorage
export const serverStorage = new ServerStorage(SERVERS_KEY, ACTIVE_SERVER_KEY)
