import ConditionBlock from '@/components/kinematic-space/components/condition-block/condition-block'
import KinematicPoints from '@/components/kinematic-space/components/kinematic-condition/components/kinematic-points/kinematic-points'
import TableKinematics from '@/components/kinematic-space/components/kinematic-condition/components/table-kinematics/table-kinematics'
import { EKinematicViewMode } from '@/enums/kinematic/kinematic-view-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { IKinematicElement, IKinematicScheme } from '@/types/kinematic/kinematic.type'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Layer, Stage } from 'react-konva'
import { shallowEqual } from 'react-redux'

import styles from './kinematic-condition.module.css'
import stylesKinematicSpace from '@/components/kinematic-space/kinematic-space.module.css'

type TProps = {
  kinematicScheme: IKinematicScheme
  kinematicElements?: IKinematicElement[]
}

const KinematicCondition: FC<TProps> = ({ kinematicScheme, kinematicElements }) => {
  const { viewMode } = useTypedSelector((state) => state.kinematicUiReducer)
  const { data: dataAllNodesState } = useGetAllNodesQuery()
  const { stageOptions } = useTypedSelector(
    (state) => ({
      ...state.kinematicReducer
    }),
    {
      equalityFn: shallowEqual
    }
  )

  const { setStageOptions } = useActions()

  useEffect(() => {
    if (kinematicScheme?.height && kinematicScheme.width) {
      setStageOptions({
        width: kinematicScheme.width,
        height: kinematicScheme.height
      })
    }
  }, [kinematicScheme, kinematicScheme.height, kinematicScheme.width, setStageOptions])

  return (
    <div className={styles['container']}>
      <Stage
        className={stylesKinematicSpace.stage}
        width={stageOptions.width * stageOptions.scale}
        height={stageOptions.height * stageOptions.scale}
        scaleX={stageOptions.scale}
        scaleY={stageOptions.scale}
      >
        <Layer>
          {kinematicScheme
            ? kinematicElements?.map((kinematicElement, index) => (
                <ConditionBlock
                  key={kinematicElement.kinematicBlockId}
                  kinematicElement={kinematicElement}
                  index={index}
                />
              ))
            : null}
        </Layer>
      </Stage>
      {viewMode === EKinematicViewMode.Status && (
        <KinematicPoints kinematicElements={kinematicElements} dataAllNodesState={dataAllNodesState} />
      )}
      {viewMode === EKinematicViewMode.Information && <TableKinematics kinematicElements={kinematicElements} />}
    </div>
  )
}

export default KinematicCondition
