import ConnectionDeviceContainer from '@/app/configuration/components/connections/components/connection-device-container/connection-device-container'
import PathConnections from '@/app/configuration/components/connections/components/connection-panel/components/path-connections/path-connections'
import type { IConnectionElement } from '@/app/configuration/components/connections/components/connection-panel/connection-panel.type'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import { ButtonTitle } from '@/constants/core/common.constant'
import { mapTypePoint, mapVp3701ChannelType } from '@/constants/point/point.constant'
import { ETargetType } from '@/enums/connection/target-type.enum'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useCreateConnectionsMutation,
  useDeleteConnectionsMutation,
  useGetConnectionsQuery
} from '@/store/api/connections.api'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import type { TCreateConnection } from '@/types/connection.type'
import { getParamDeviceByContent } from '@/utils/device/get-param-device-by-content.util'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { FC } from 'react'
import { useMemo } from 'react'

import styles from './connection-panel.module.css'

interface IProps {
  pointId?: string
  deviceId?: string
  deviceContent?: EDeviceContent
}

const allowedDeviceTypes = [
  EDeviceContent.VP_3701_CHANNEL,
  EDeviceContent.VP_3711_CHANNEL,
  EDeviceContent.GENERATOR_DEVICE
]

const hasAllowedType = (deviceContent?: EDeviceContent) => allowedDeviceTypes.some((type) => type === deviceContent)

export const ConnectionPanel: FC<IProps> = ({ pointId, deviceId, deviceContent }) => {
  const { deviceNode } = useTypedSelector((state) => state.devicesReducer)
  const { connectionElement } = useTypedSelector((state) => state.connectionReducer)
  const optionsForGettingConnection = getParamDeviceByContent(deviceContent, deviceId)
  const { data: pagedConnectionByDevice } = useGetConnectionsQuery(optionsForGettingConnection, {
    skip: !optionsForGettingConnection
  })
  const { data: pagedConnectionByPoint } = useGetConnectionsQuery({ measuringPoint: pointId }, { skip: !pointId })
  const { data: point } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })

  const connectionsByDevice = pagedConnectionByDevice?.content
  const connectionsByPoint = pagedConnectionByPoint?.content

  const [createConnection, { isLoading: isLoadingCreateConnection }] = useCreateConnectionsMutation()
  const [deleteConnection, { isLoading: isLoadingRemoveConnection }] = useDeleteConnectionsMutation()

  const selectedChannel = useMemo(() => {
    if (hasAllowedType(deviceNode?.content)) {
      if (deviceNode) {
        return deviceNode
      }
    }
  }, [deviceNode])

  const connectionsPointsElements = useMemo((): IConnectionElement[] | undefined => {
    if (connectionsByPoint) {
      return connectionsByPoint?.map((connection) => {
        const vp3701Name = connection.vp3701ChannelType
          ? `${connection.vp3701Id} / ${mapVp3701ChannelType[connection.vp3701ChannelType]}`
          : connection.vp3701Id

        return {
          ...connection,
          name: vp3701Name || connection.generatorId || connection.ltrModuleId || ''
        }
      })
    }
  }, [connectionsByPoint])

  const connectionsDeviceElements = useMemo((): IConnectionElement[] | undefined => {
    if (selectedChannel && connectionsByDevice) {
      const result = connectionsByDevice.map<IConnectionElement>((connection) => ({
        ...connection,
        name: connection.measuringPointId || ''
      }))

      if (selectedChannel.content === EDeviceContent.VP_3701_CHANNEL) {
        return result.filter(
          (connection) =>
            connection.vp3701Id === selectedChannel.key.toString().split('-')[0] &&
            connection.vp3701ChannelType === selectedChannel?.vp3701ChannelType
        )
      }

      if (selectedChannel.content === EDeviceContent.GENERATOR_DEVICE) {
        return result.filter((connection) => connection.generatorId)
      }

      if (selectedChannel.content === EDeviceContent.VP_3711_CHANNEL) {
        return result.filter(
          (connection) => selectedChannel.ltr24Channel?.channelNumber === connection.ltrModuleChannelNumber
        )
      }

      return result
    }
  }, [connectionsByDevice, selectedChannel])

  const foundConnectedPoint = connectionsDeviceElements?.find((deviceConnect) =>
    connectionsPointsElements?.some((pointConnect) => deviceConnect.connectionId === pointConnect.connectionId)
  )

  // Отфильтрованы по каналам
  const foundConnectedChannel = connectionsDeviceElements?.find((deviceConnect) =>
    connectionsPointsElements?.some((pointConnect) => deviceConnect.connectionId === pointConnect.connectionId)
  )

  const isDifferentPhysicalQuantityType =
    point?.physicalQuantityType !== selectedChannel?.physicalQuantityType &&
    selectedChannel?.content !== EDeviceContent.GENERATOR_DEVICE
  const disabledConnectButton =
    !selectedChannel || !point || connectionsByPoint?.length !== 0 || isDifferentPhysicalQuantityType

  const disabledDisconnectButton = !point || !foundConnectedChannel

  const handleCreateConnectionClick = async () => {
    if (!deviceNode || !point) {
      return
    }
    try {
      if (selectedChannel?.content === EDeviceContent.VP_3701_CHANNEL) {
        const vp3701Id = deviceNode?.key.toString().split('-')[0]

        const dataForConnection: TCreateConnection = {
          vp3701Id: vp3701Id,
          vp3701ChannelType: deviceNode.vp3701ChannelType,
          targetType: ETargetType.VP3701,
          signalType: point.physicalQuantityType,
          measuringPointId: point.pointId
        }

        await createConnection(dataForConnection).unwrap()

        successNotificationCreate('Связь установлена')
      }

      if (selectedChannel?.content === EDeviceContent.GENERATOR_DEVICE) {
        const dataForConnection: TCreateConnection = {
          generatorId: selectedChannel?.key as string,
          targetType: ETargetType.GENERATOR,
          measuringPointId: point.pointId
        }

        await createConnection(dataForConnection).unwrap()

        successNotificationCreate('Связь установлена')
      }

      if (selectedChannel?.content === EDeviceContent.VP_3711_CHANNEL) {
        const [moduleId, channelNumber] = (selectedChannel.key as string).split('-')
        const dataForConnection: TCreateConnection = {
          ltrModuleChannelNumber: Number(channelNumber),
          ltrModuleId: moduleId,
          measuringPointId: point.pointId,
          signalType: point.physicalQuantityType,
          targetType: ETargetType.LTR24
        }

        await createConnection(dataForConnection).unwrap()

        successNotificationCreate('Связь установлена')
      }
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  const handleRemoveConnection = async () => {
    try {
      if (foundConnectedChannel) {
        await deleteConnection(foundConnectedChannel.connectionId).unwrap()
        successNotificationCreate('Связь разорвана')
      }

      if (connectionElement) {
        await deleteConnection(connectionElement.connectionId).unwrap()
      }
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  return (
    <>
      <HeaderConfiguration content='Параметры связей' />
      <div className={styles['container']}>
        <div className={styles.panel}>
          <ControlsWrapper>
            <PathConnections
              undefinedText='Выберите точку каналы'
              connections={connectionsPointsElements}
              title='Подключённые каналы'
              foundConnectedElement={foundConnectedPoint}
            />
          </ControlsWrapper>
          <ControlsWrapper>
            <div className={styles['control-panel']}>
              <div className={styles['container-items']}>
                <div className={styles['label']}>{point?.name}</div>
                <div className={styles['label']}>{point && mapTypePoint[point.physicalQuantityType]}</div>
              </div>
              <div className={styles['container-items']}>
                <ButtonPrimary
                  title='Разорвать связь'
                  htmlType={'button'}
                  isLoadingSkeleton={isLoadingRemoveConnection}
                  disabled={disabledDisconnectButton}
                  onClick={handleRemoveConnection}
                />
                <ButtonPrimary
                  title={ButtonTitle.SetConnection}
                  htmlType={'button'}
                  disabled={disabledConnectButton}
                  onClick={handleCreateConnectionClick}
                  loading={isLoadingCreateConnection}
                />
              </div>
              <div className={styles['container-items']}>
                <div className={styles['label']}>{selectedChannel?.title as string}</div>
                <div className={styles['label']}>
                  {selectedChannel?.physicalQuantityType && mapTypePoint[selectedChannel.physicalQuantityType]}
                </div>
              </div>
            </div>
          </ControlsWrapper>
          <ControlsWrapper>
            <PathConnections
              undefinedText='Выберите измерительный канал или генератор'
              title={'Подключённые точки измерения'}
              connections={connectionsDeviceElements}
              foundConnectedElement={foundConnectedPoint}
            />
          </ControlsWrapper>
        </div>
        <ConnectionDeviceContainer />
      </div>
    </>
  )
}

export default ConnectionPanel
