import Spinner from '@/components/UI/elements/spinner/spinner'
import ConfigurationBlock from '@/components/kinematic-space/components/configuration-block/configuration-block'
import DrawerKinematic from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic'
import {
  getMaxHeightScheme,
  getMaxWidthScheme
} from '@/components/kinematic-space/components/kinematic-configuration/kinematic-configuration.function'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import {
  useChangeKinematicElementMutation,
  useChangeKinematicMutation,
  useGetKinematicElementsQuery
} from '@/store/api/kinematic.api'
import type { IKinematicElement, IKinematicScheme } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate } from '@/utils/notification-creators'
import type { KonvaEventObject } from 'konva/lib/Node'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Layer, Stage } from 'react-konva'
import { shallowEqual } from 'react-redux'

import stylesKinematicSpace from '@/components/kinematic-space/kinematic-space.module.css'
import styles from '@/components/kinematic-space/kinematic-space.module.css'

type TProps = {
  kinematicScheme: IKinematicScheme
  kinematicElements?: IKinematicElement[]
}

const KinematicConfiguration: FC<TProps> = ({ kinematicScheme, kinematicElements }) => {
  const [changeKinematicScheme] = useChangeKinematicMutation()
  const [changeKinematicElement] = useChangeKinematicElementMutation()
  const { stageOptions } = useTypedSelector((state) => state.kinematicReducer, {
    equalityFn: shallowEqual
  })

  const { isFetching: isFetchingKinematic } = useGetKinematicElementsQuery(kinematicScheme?.kinematicSchemeId || '', {
    skip: !kinematicScheme?.kinematicSchemeId
  })
  const { selectedKinematic } = useTypedSelector((state) => state.kinematicUiReducer)
  const { setStageOptions, setSelectedKinematic } = useActions()
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    if (kinematicScheme.height && kinematicScheme.width) {
      setStageOptions({
        width: kinematicScheme.width,
        height: kinematicScheme.height
      })
    }
  }, [kinematicScheme.height, kinematicScheme.width, setStageOptions])

  const handleOnSelect = (evt: KonvaEventObject<MouseEvent>, element: IKinematicElement) => {
    evt.cancelBubble = true // Остановка всплытия события
    setSelectedKinematic(element)
  }

  function handleOnDragMove(e: KonvaEventObject<DragEvent>) {
    const absPos = e.target.absolutePosition()
    const step = 40 * stageOptions.scale

    if (absPos.x / stageOptions.scale + e.target.attrs.width / stageOptions.scale > stageOptions.width) {
      setStageOptions({
        ...stageOptions,
        width: stageOptions.width + e.target.attrs.width
      })
    }
    if (absPos.y / stageOptions.scale + e.target.attrs.height / stageOptions.scale > stageOptions.height) {
      setStageOptions({
        ...stageOptions,
        height: stageOptions.height + e.target.attrs.height
      })
    }

    if (absPos.x % step !== 0) {
      absPos.x = absPos.x - (absPos.x % step)
    }

    if (absPos.y % step !== 0) {
      absPos.y = absPos.y - (absPos.y % step)
    }

    if (absPos.x < 0) {
      absPos.x = 0
    }

    if (absPos.y < 0) {
      absPos.y = 0
    }

    e.target.absolutePosition(absPos)
  }

  const handleClose = () => {
    setOpenDrawer(false)
  }

  async function handleOnDragEnd(kinematicElement: IKinematicElement) {
    if (kinematicElement.kinematicBlockId) {
      try {
        await changeKinematicElement(kinematicElement).unwrap()

        // Реализация динамического обновления ширины и высоты рабочей области.
        if (kinematicElements) {
          const padding = 160

          const maxWidth = getMaxWidthScheme(kinematicElements, kinematicElement)
          const maxHeight = getMaxHeightScheme(kinematicElements, kinematicElement)

          await changeKinematicScheme({
            ...kinematicScheme,
            width: maxWidth + padding,
            height: maxHeight + padding
          })
        }
      } catch (error) {
        console.error(error)
        errorNotificationCreate(error)
      }
    }
  }

  const handleStageClick = () => {
    setSelectedKinematic(null)
  }

  const handleDblClick = (element: IKinematicElement) => {
    setOpenDrawer(true)
    setSelectedKinematic(element)
  }

  return (
    <>
      <Stage
        className={stylesKinematicSpace.stage}
        width={stageOptions.width * stageOptions.scale}
        height={stageOptions.height * stageOptions.scale}
        scaleX={stageOptions.scale}
        scaleY={stageOptions.scale}
        onClick={handleStageClick}
      >
        <Layer>
          {kinematicScheme
            ? kinematicElements?.map((kinematicElement) => (
                <ConfigurationBlock
                  key={kinematicElement.kinematicBlockId}
                  kinematicElement={kinematicElement}
                  onDragMove={handleOnDragMove}
                  onDragEnd={handleOnDragEnd}
                  onDblClick={() => handleDblClick && handleDblClick(kinematicElement)}
                  onSelect={handleOnSelect}
                />
              ))
            : null}
        </Layer>
      </Stage>
      <DrawerKinematic openDrawer={openDrawer} kinematicElement={selectedKinematic} onClose={handleClose} />
      {isFetchingKinematic && <Spinner className={styles.spinner} />}
    </>
  )
}

export default KinematicConfiguration
