import CellEditableSquare from '@/app/configuration/components/devices/generator/generator-square/cell-editable-square/cell-editable-square'
import type { DataItem } from '@/app/configuration/components/devices/generator/generator.type'
import TableEditable from '@/app/configuration/components/devices/generator/table-editable/table-editable'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import TableWrapper from '@/components/UI/elements/table-wrapper/table-wrapper'
import { GeneratorKey, StatusValue } from '@/constants/core/common.constant'
import { GeneratorLabel } from '@/constants/device/device-options.constant'
import { EGeneratorType } from '@/enums/device/generator/generator-type.enum'
import useActions from '@/hooks/use-actions'
import { useUpdateGeneratorMutation } from '@/store/api/devices.api'
import type { IGenerator } from '@/types/devices.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Flex, Form, Table } from 'antd'
import type { FC } from 'react'
import { useEffect } from 'react'

import styles from './generator-square.module.css'

type TProps = {
  generatorSquare: IGenerator
  isEditGenerator: boolean
  currentGeneratorID: IGenerator['generatorId']
}

const GeneratorSquare: FC<TProps> = ({ generatorSquare, isEditGenerator, currentGeneratorID }) => {
  const { setIsEditGenerator } = useActions()
  const [updateGenerator] = useUpdateGeneratorMutation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (generatorSquare && generatorSquare.squareSettings) {
      const fieldsValue = {
        [GeneratorKey.Name]: generatorSquare.name,
        [GeneratorKey.Fs]: generatorSquare.fs || 0,
        [GeneratorKey.TSig]: generatorSquare.tsig || 0,
        [GeneratorKey.NormAmp]: generatorSquare.squareSettings.normAmp,
        [GeneratorKey.SquareBaseFreq]: generatorSquare.squareSettings.squareBaseFreq,
        [GeneratorKey.SquareLowLevel]: generatorSquare.squareSettings.squareLowLevel,
        [GeneratorKey.SquareHighToLow]: generatorSquare.squareSettings.squareHighToLow,
        [GeneratorKey.SquareDuty]: generatorSquare.squareSettings.squareDuty,
        [GeneratorKey.SquarePhase]: generatorSquare.squareSettings.squarePhase
      }
      form.setFieldsValue(fieldsValue)
    }
  }, [form, generatorSquare])

  if (!generatorSquare) {
    return null
  }

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'label'
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      onCell: (record: DataItem) => ({
        editing: isEditGenerator,
        record,
        dataIndex: 'label',
        title: 'Наименование'
      })
    }
  ]

  const columnsGeneratorSquare = [
    {
      title: 'Параметры генератора',
      children: columns
    }
  ]

  const columnsSquareSettings = [
    {
      title: 'Параметры сигнала',
      children: columns
    }
  ]

  const dataGeneratorSquare: DataItem[] = [
    {
      key: GeneratorKey.Name,
      label: GeneratorLabel.Name,
      value: generatorSquare?.name || StatusValue.Null
    },
    {
      key: GeneratorKey.Fs,
      label: GeneratorLabel.Fs,
      value: generatorSquare?.fs || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.TSig,
      label: GeneratorLabel.TSig,
      value: generatorSquare?.tsig || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.NormAmp,
      label: GeneratorLabel.NormAmp,
      value: generatorSquare?.squareSettings?.normAmp || StatusValue.NullNumber
    }
  ]

  const dataSquareSettings: DataItem[] = [
    {
      key: GeneratorKey.SquareBaseFreq,
      label: GeneratorLabel.SquareBaseFreq,
      value: generatorSquare?.squareSettings?.squareBaseFreq || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.SquareLowLevel,
      label: GeneratorLabel.SquareLowLevel,
      value: generatorSquare?.squareSettings?.squareLowLevel || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.SquareDuty,
      label: GeneratorLabel.SquareDuty,
      value: generatorSquare?.squareSettings?.squareDuty || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.SquareHighToLow,
      label: GeneratorLabel.SquareHighToLow,
      value: generatorSquare?.squareSettings?.squareHighToLow || StatusValue.NullNumber
    },
    {
      key: GeneratorKey.SquarePhase,
      label: GeneratorLabel.SquarePhase,
      value: generatorSquare?.squareSettings?.squarePhase || StatusValue.NullNumber
    }
  ]

  const handleFinish = async () => {
    const fieldsValue = form.getFieldsValue()
    const updatedGenerator = {
      [GeneratorKey.Name]: fieldsValue.name,
      [GeneratorKey.Fs]: fieldsValue.fs,
      [GeneratorKey.TSig]: fieldsValue.tsig,
      [GeneratorKey.GeneratorId]: currentGeneratorID,
      [GeneratorKey.Type]: EGeneratorType.SQUARE,
      squareSettings: {
        [GeneratorKey.NormAmp]: fieldsValue.normAmp,
        [GeneratorKey.SquareBaseFreq]: fieldsValue.squareBaseFreq,
        [GeneratorKey.SquareLowLevel]: fieldsValue.squareLowLevel,
        [GeneratorKey.SquareHighToLow]: fieldsValue.squareHighToLow,
        [GeneratorKey.SquareDuty]: fieldsValue.squareDuty,
        [GeneratorKey.SquarePhase]: fieldsValue.squarePhase
      }
    } as IGenerator

    try {
      await updateGenerator(updatedGenerator).unwrap()
      setIsEditGenerator(!isEditGenerator)
      successNotificationCreate('Генератор обновлён')
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  const handleEditGenerator = () => {
    setIsEditGenerator(!isEditGenerator)
  }

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex>
            <span className='material-symbols-outlined'>error_med</span>
            <span>Дискретный генератор {generatorSquare.name}</span>
          </Flex>
        }
      />
      <div className={styles['table-container']}>
        <TableEditable form={form} onFinish={handleFinish} onReset={handleEditGenerator} isEdit={isEditGenerator}>
          <TableWrapper>
            <Table
              components={{
                body: {
                  cell: CellEditableSquare
                }
              }}
              bordered={true}
              dataSource={dataGeneratorSquare}
              columns={columnsGeneratorSquare}
              pagination={false}
            />
          </TableWrapper>
          <TableWrapper>
            <Table
              components={{
                body: {
                  cell: CellEditableSquare
                }
              }}
              bordered={true}
              dataSource={dataSquareSettings}
              columns={columnsSquareSettings}
              pagination={false}
            />
          </TableWrapper>
        </TableEditable>
      </div>
    </>
  )
}
export default GeneratorSquare
