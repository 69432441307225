import { chartsUiSlice } from '@/store/reducers/charts/charts-ui.slice'
import { expressAnalysisSlice } from '@/store/reducers/charts/express-analysis.slice'
import { expressBarsSlice } from '@/store/reducers/charts/express-bars.slice'
import { expressMarkersSlice } from '@/store/reducers/charts/express-markers.slice'
import { expressSectionSlice } from '@/store/reducers/charts/express-section.slice'
import { expressStrobeSlice } from '@/store/reducers/charts/express-strobe.slice'
import { configurationSlice } from '@/store/reducers/configuration.slice'
import { connectionUiSlice } from '@/store/reducers/connection/connection.slice'
import { devicesSlice } from '@/store/reducers/device/devices.slice'
import { equipmentsUiSlice } from '@/store/reducers/equipments/equipments-ui.slice'
import { generatorSlice } from '@/store/reducers/generator.slice'
import { globalSlice } from '@/store/reducers/global-states/global.slice'
import { kinematicUiSlice } from '@/store/reducers/kinematic/kinematic-ui/kinematic-ui.slice'
import { kinematicSlice } from '@/store/reducers/kinematic/kinematic.slice'
import { measurementResultsSlice } from '@/store/reducers/measurementResults.slice'
import { mnemonicSlice } from '@/store/reducers/mnemonic/mnemonic.slice'
import { planSlice } from '@/store/reducers/plan.slice'
import { reportsSlice } from '@/store/reducers/reports.slice'
import { serverSettingsSlice } from '@/store/reducers/server-settings.slice'
import { thresholdSlice } from '@/store/reducers/threshold/threshold.slice'
import { bindActionCreators } from '@reduxjs/toolkit'
import { useMemo } from 'react'

import { useAppDispatch } from './use-app-dispatch'

const useActions = () => {
  const dispatch = useAppDispatch()

  return useMemo(
    () =>
      bindActionCreators(
        {
          ...thresholdSlice.actions,
          ...serverSettingsSlice.actions,
          ...planSlice.actions,
          ...generatorSlice.actions,
          ...configurationSlice.actions,
          ...devicesSlice.actions,
          ...measurementResultsSlice.actions,
          ...reportsSlice.actions,
          ...kinematicSlice.actions,
          ...expressStrobeSlice.actions,
          ...expressSectionSlice.actions,
          ...expressMarkersSlice.actions,
          ...expressBarsSlice.actions,
          ...kinematicUiSlice.actions,
          ...chartsUiSlice.actions,
          ...globalSlice.actions,
          ...equipmentsUiSlice.actions,
          ...mnemonicSlice.actions,
          ...connectionUiSlice.actions,
          ...expressAnalysisSlice.actions
        },
        dispatch
      ),
    [dispatch]
  )
}

export default useActions
