import MenuTree from '@/app/configuration/components/menu-tree/menu-tree'
import MnemonicToolbar from '@/app/configuration/components/mnemonic-configuration/mnemonic-toolbar/mnemonic-toolbar'
import Submenu from '@/components/UI/elements/submenu/submenu'
import TextInfo from '@/components/UI/elements/text-info/text-info'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import MnemonicCommands from '@/components/mnemonic/mnemonic-commands/mnemonic-commands'
import MnemonicMain from '@/components/mnemonic/mnemonic-main/mnemonic-main'
import MnemonicTools from '@/components/mnemonic/mnemonic-tools/mnemonic-tools'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const MnemonicConfiguration: FC = () => {
  const { selectedMachineId, selectedMeasurementId, selectedPointId } = useTypedSelector((state) => state.globalReducer)

  return (
    <Container>
      <ToolBar>
        <MnemonicCommands />
      </ToolBar>
      <LeftBar>
        <MenuTree pointId={selectedPointId} measurementId={selectedMeasurementId} machineId={selectedMachineId} />
        <MnemonicTools />
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />
        {selectedMachineId ? (
          <>
            <MnemonicToolbar />
            <MnemonicMain />
          </>
        ) : (
          <TextInfo>Машина не выбрана</TextInfo>
        )}
      </WorkSpace>
    </Container>
  )
}

export default MnemonicConfiguration
