import { EDeviceContent } from '@/enums/device/device-content.enum'

export const getParamDeviceByContent = (deviceContent?: EDeviceContent, deviceId?: string) => {
  if (!deviceId && deviceContent) {
    return undefined
  }

  switch (deviceContent) {
    case EDeviceContent.VP_3711_CHANNEL:
      return { ltrModuleId: deviceId?.split('-')[0] }
    case EDeviceContent.VP_3701_CHANNEL:
      return { vp3701Id: deviceId?.split('-')[0] }
    case EDeviceContent.GENERATOR_DEVICE:
      return { generatorId: deviceId }
    default:
      return undefined
  }
}
