import { getTreeCharFreqs } from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/char-freq-tree-select/char-freq-tree-select.service'
import type { ICharFreqTree } from '@/types/kinematic/char-freq.type'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import type { TreeSelectProps } from 'antd'
import { TreeSelect } from 'antd'
import type { FC } from 'react'

import styles from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/bar/components/express-bar-popup.module.css'

type TProps = {
  kinematicElements?: IKinematicElement[]
} & TreeSelectProps<string | null | undefined, ICharFreqTree>

const CharFreqTreeSelect: FC<TProps> = ({ kinematicElements, ...props }) => {
  const treeCharFreqs = kinematicElements ? getTreeCharFreqs(kinematicElements) : undefined

  return (
    <TreeSelect
      {...props}
      treeData={treeCharFreqs}
      popupClassName={styles['popup-select']}
      placeholder={'Выберите характерную частоту'}
      treeDefaultExpandAll={true}
      showSearch={true}
      treeNodeLabelProp='labelTitle'
    />
  )
}

export default CharFreqTreeSelect
