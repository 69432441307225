import type { IPointForm } from '@/app/configuration/components/equipments/components/point/point.interface'
import {
  getTypePoint,
  mapFromResponsePoint,
  mapToRequestPoint
} from '@/app/configuration/components/equipments/components/point/point.service'
import { GroupTree } from '@/app/configuration/components/menu-tree/menu-tree.constant'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import DocTooltip from '@/components/doc-tooltip/doc-tooltip'
import pointIcon from '@/components/icons/equipments/point-icon'
import { ButtonTitle } from '@/constants/core/common.constant'
import { FormKey } from '@/constants/doc-tooltip.constant'
import { PointLabel, PointName } from '@/constants/point/point-form.constant'
import { pointTypes } from '@/constants/point/point.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import UseActions from '@/hooks/use-actions'
import {
  useCreateMeasuringPointsMutation,
  useGetMeasuringPointQuery,
  useUpdateMeasuringPointsMutation,
  useUpdateMeasuringPointsStatusMutation
} from '@/store/api/points.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Input, Select, Switch } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './points.module.css'

interface IProps {
  pointId?: string
  machineId?: string
  isAddMode: boolean
  isEditMode: boolean
}

const Point: FC<IProps> = ({ pointId, machineId, isAddMode, isEditMode }) => {
  const { setEquipmentView, setSelectedPointId, updateTreeMenuTitle, updateTreeMenuItem, setSelectedTreeItems } =
    UseActions()
  const [pointForm] = useForm<IPointForm>()
  const [savedData, setSavedData] = useState<IPointForm | undefined>(undefined)
  const [createMeasurementPoint, { isLoading: isLoadingCreateMeasurementPoint }] = useCreateMeasuringPointsMutation()
  const [updatePoint, { isLoading: isLoadingUpdateMeasurementPoint }] = useUpdateMeasuringPointsMutation()
  const [updateMeasurementPointsStatus, { isLoading: isLoadingUpdatePointsStatus }] =
    useUpdateMeasuringPointsStatusMutation()
  const { data: point, isLoading: isLoadingPoint } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })

  useEffect(() => {
    if (point && isEditMode) {
      const formData = mapFromResponsePoint(point)
      setSavedData(formData)
      pointForm.setFieldsValue(formData)
    }
  }, [isEditMode, point, pointForm])

  const handleChangePaused = async () => {
    if (pointId && isEditMode) {
      const isActivated = pointForm.getFieldValue(PointName.isActivated)

      try {
        await updateMeasurementPointsStatus({
          statusData: {
            paused: !isActivated
          },
          pointId
        }).unwrap()

        if (isActivated) {
          successNotificationCreate('Точка измерения запущена')
        } else {
          successNotificationCreate('Точка измерения выключена')
        }
      } catch (err) {
        errorNotificationCreate(err)
      }
    }
  }

  const handlePointFinish = async () => {
    const { getFieldsValue, validateFields } = pointForm
    try {
      try {
        await validateFields({ recursive: true })
      } catch (e) {
        errorNotificationCreate(e)
        return
      }

      if (machineId) {
        const dataForRequest = mapToRequestPoint(getFieldsValue(), machineId)

        if (isAddMode) {
          const { id } = await createMeasurementPoint(dataForRequest).unwrap()
          setSelectedPointId(id)
          setEquipmentView({
            mode: EViewMode.EDITING,
            content: EEquipmentContent.POINT
          })
          successNotificationCreate('Точка измерения успешно добавлена')
          updateTreeMenuItem({
            id,
            name: dataForRequest.name as string,
            group: GroupTree.Points,
            parentKey: machineId,
            icon: pointIcon
          })
          setSelectedTreeItems([id])
        }

        if (isEditMode && pointId) {
          await updatePoint({
            updatedData: dataForRequest,
            pointId: pointId
          }).unwrap()

          updateTreeMenuTitle({
            id: pointId,
            newTitle: dataForRequest.name as string
          })
          successNotificationCreate('Конфигурация точки измерения успешно обновлена')
        }
      }
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const getTitle = () => {
    if (isAddMode) {
      return 'Добавление новой точки измерения'
    }

    return `Точка измерения ${point?.name}`
  }

  const resetPointForm = () => {
    if (savedData) {
      pointForm.setFieldsValue(savedData)
    }
  }

  const typePoint = getTypePoint(point)

  if (isLoadingPoint) {
    return <Spinner />
  }

  return (
    <>
      <HeaderConfiguration content={getTitle()} />
      <ContentWrapper>
        <Form onFinish={handlePointFinish} layout={'horizontal'} form={pointForm} className={styles.container}>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            <FormItemApp
              name={PointName.ShortName}
              label={PointLabel.ShortName}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Input placeholder='Введите наименование' />
            </FormItemApp>
            <FormItemApp name={PointName.FullName} label={PointLabel.FullName}>
              <Input placeholder='Введите полное наименование' />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            {isAddMode && (
              <FormItemApp
                name={PointName.PhysicalQuantityType}
                label={PointLabel.PhysicalQuantityType}
                rules={[{ required: true, message: 'Поле обязательно' }]}
              >
                <Select options={pointTypes} placeholder='Выберите тип измерения' />
              </FormItemApp>
            )}
            {isEditMode && <TextStatic label={PointLabel.PhysicalQuantityType} value={typePoint} />}
            {isEditMode && (
              <FormItemApp
                name={PointName.isActivated}
                label={PointLabel.isActivated}
                rules={[{ required: true, message: 'Поле обязательно' }]}
              >
                <Switch
                  className={styles['switch']}
                  loading={isLoadingUpdatePointsStatus}
                  onChange={handleChangePaused}
                />
              </FormItemApp>
            )}
          </ControlsWrapper>
          <ControlsWrapper toolTip={<DocTooltip theme='light' formKey={FormKey.FormOne} />}>
            <FormItemApp name={PointName.Place} label={PointLabel.Place}>
              <Input placeholder='Введите расположение точки измерения' />
            </FormItemApp>
            <FormItemApp name={PointName.Comment} label={PointLabel.Comment}>
              <TextArea className={styles['text-area']} placeholder='Введите расположение' />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <ButtonsWrapper>
              <ButtonPrimary onClick={resetPointForm} htmlType={'button'} title={ButtonTitle.Reset} />
              <ButtonPrimary
                htmlType={'submit'}
                title={ButtonTitle.Save}
                isLoading={isLoadingCreateMeasurementPoint || isLoadingUpdateMeasurementPoint}
              />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}

export default Point
