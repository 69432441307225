export const bearingFormFields: {
  [key in string]: {
    name: string
    label: string
    placeholder: string
    tooltip?: string
    errorMessage?: string
  }
} = {
  modelType: {
    name: 'modelType',
    label: 'Тип подшипника',
    placeholder: 'Введите тип подшипника'
  },
  manufacturer: {
    name: 'manufacturer',
    label: 'Производитель',
    placeholder: 'Введите наименование производителя'
  },
  kftf: {
    name: 'kftf',
    label: 'kFTF',
    tooltip: 'Коэффициент частоты вращения сепаратора',
    placeholder: 'Введите значение',
    errorMessage: 'Необходимо указать коэффициент'
  },
  kftfo: {
    name: 'kftfo',
    label: 'kFTFo',
    tooltip: 'Коэффициент обратной частоты сепаратора',
    placeholder: 'Введите значение',
    errorMessage: 'Необходимо указать коэффициент'
  },
  kbsf: {
    name: 'kbsf',
    label: 'kBSF',
    tooltip: 'Коэффициент частоты контакта тел качения',
    placeholder: 'Введите значение',
    errorMessage: 'Необходимо указать коэффициент'
  },
  kbpfo: {
    name: 'kbpfo',
    label: 'kBPFO',
    tooltip: 'Коэффициент частоты перекатывания тел качения по наружному кольцу',
    placeholder: 'Введите значение',
    errorMessage: 'Необходимо указать коэффициент'
  },
  kbpfi: {
    name: 'kbpfi',
    label: 'kBPFI',
    tooltip: 'Коэффициент частоты перекатывания тел качения по внутреннему кольцу',
    placeholder: 'Введите значение',
    errorMessage: 'Необходимо указать коэффициент'
  },
  n: {
    name: 'n',
    label: 'Количество тел качения',
    placeholder: 'Введите значение',
    errorMessage: 'Введите количество тел качения'
  },
  din: {
    name: 'din',
    label: 'Диаметр внутреннего кольца',
    placeholder: 'Введите диаметр внутреннего кольца',
    errorMessage: 'Введите диаметр внутреннего кольца'
  },
  dout: {
    name: 'dout',
    label: 'Диаметр наружного кольца',
    placeholder: 'Введите диаметр наружного кольца',
    errorMessage: 'Введите диаметр наружного кольца'
  },
  dtk: {
    name: 'dtk',
    label: 'Диаметр тел качения',
    placeholder: 'Введите диаметр тел качения',
    errorMessage: 'Введите диаметр тел качения'
  },
  alfa: {
    name: 'alfa',
    label: 'Угол контакта, градусов',
    placeholder: 'Введите угол контакта, градусов',
    errorMessage: 'Введите угол контакта в градусах'
  }
}

export const enum InputMode {
  Geometry = 'geometry',
  Coefficient = 'Coefficient'
}
