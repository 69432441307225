import type { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'

export type TMnemoState = {
  mnemoElementId: string
  mnemoSchemeId: string
  dangerLevelStateDto: {
    dangerLevelType: EThresholdLevel
  }
  nodeNameStateDto: {
    nodeName: string
  }
  measurementLastValueStateDto: {
    lastValue: number
    dangerLevelType: EThresholdLevel
  }
  statisticalNodeStateDto: {
    level4Count: number
    level3Count: number
    level2Count: number
    level1Count: number
    levelNoneCount: number
  }
  thresholdStateDto: {
    lastValue: number
    dangerLevel: EThresholdLevel
    rifts: [
      {
        riftValue: number
        hysteresisCoef: number
      }
    ]
    zones: [
      {
        dangerousType: EThresholdLevel
      }
    ]
  }
}

export const enum EToolType {
  MEASUREMENT_LAST_VALUE_ELEMENT = 'measurementLastValueElement',
  STATIC_IMAGE_ELEMENT = 'staticImageElement',
  STATIC_LABEL_ELEMENT = 'staticLabelElement',
  MEASUREMENT_WITH_THRESHOLD_INDICATOR_ELEMENT = 'measurementWithThresholdIndicatorElement',
  DANGER_STATE_INDICATOR_ELEMENT = 'dangerStateIndicatorElement',
  MEASUREMENT_WITH_THRESHOLD_SPEEDOMETER_INDICATOR_ELEMENT = 'measurementWithThresholdSpeedometerIndicatorElement',
  STATISTICAL_INDICATOR_ELEMENT = 'statisticalIndicatorElement'
}

export type TMnemonicConfig = {
  tools: TMnemoSchemeItem[]
  mnemoScheme: TMnemoScheme[]
}

export type TMnemoScheme = {
  mnemoSchemeId?: string
  name: string
  nodeId?: string
  backgroundColor?: string
  width?: number
  height?: number
  resizeStrategy?: 'FULL_RESIZE' | 'RESIZE_WITH_PROPORTIONS' | 'FIXED_SIZE'
}

export type TMnemoSchemeItem = {
  mnemoElementId?: string
  mnemoSchemeId?: string
  name?: string
  x: number
  y: number
  resizeStrategy?: 'FULL_RESIZE' | 'RESIZE_WITH_PROPORTIONS' | 'FIXED_SIZE'
  pointerPosition?: {
    x: number
    y: number
  } | null
  measurementLastValueElement?: {
    measurementId?: string
    fontStyle?: {
      color?: string
      backgroundColor?: string
      fontSize?: number
      bold?: boolean
      italic?: boolean
    }
    borderStyle?: {
      color?: string
      size?: number
    }
  } | null
  staticImageElement?: {
    fileId?: string
    width: number | null
    height: number | null
  } | null
  staticLabelElement?: {
    text?: string
    fontStyle?: {
      color?: string
      backgroundColor?: string
      fontSize?: number
      bold?: boolean
      italic?: boolean
    }
    borderStyle?: {
      color?: string
      size?: number
    }
  } | null
  dangerStateIndicatorElement?: {
    machineId?: string
    measuringPointId?: string
    measurementId?: string
    width: number
    height: number
  } | null
  measurementWithThresholdIndicatorElement?: {
    measurementId?: string
    width: number
    height: number
    horizontal: boolean
  } | null
  measurementWithThresholdSpeedometerIndicatorElement?: {
    measurementId: string
    width: number
    height: number
  } | null
  statisticalIndicatorElement?: {
    machineId?: string
    measuringPointId?: string
    width: number
    height: number
  } | null
}
