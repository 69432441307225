import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import { baseApi } from '@/store/api/api-base-query'
import type { ILtr24ChannelUpdate } from '@/types/ltr/channel/ltr24-channel-update.interface'
import type { ICrateUpdate } from '@/types/ltr/crate/crate-update.interface'
import type { ICrate } from '@/types/ltr/crate/crate.interface'
import type { ILtrModuleUpdate } from '@/types/ltr/ltr-module/ltr-module-update.interface'
import type { ILtrModule } from '@/types/ltr/ltr-module/ltr-module.interface'
import type { IPagedResponse } from '@/types/paged-response.interface'

export const ltrApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCrates: builder.query<IPagedResponse<ICrate>, void>({
      query: () => ({ url: `${ApiResource.Ltr}/crates` }),
      providesTags: [ApiTag.Ltr]
    }),
    getCrate: builder.query<ICrate, { ltrCrateId?: string }>({
      query: ({ ltrCrateId }) => `${ApiResource.Ltr}/crates/${ltrCrateId}`,
      providesTags: [ApiTag.Ltr]
    }),
    getLtrModules: builder.query<IPagedResponse<ILtrModule>, void>({
      query: () => ({ url: `${ApiResource.Ltr}/modules` }),
      providesTags: [ApiTag.Ltr]
    }),
    getLtrModule: builder.query<ILtrModule, { ltrModuleId: string | undefined }>({
      query: ({ ltrModuleId }) => ({ url: `${ApiResource.Ltr}/modules/${ltrModuleId}` }),
      providesTags: [ApiTag.Ltr]
    }),
    getLtrModulesByCrate: builder.query<ILtrModule[], { ltrCrateId?: string }>({
      query: ({ ltrCrateId }) => ({ url: `${ApiResource.Ltr}/crates/${ltrCrateId}/modules` }),
      providesTags: [ApiTag.Ltr]
    }),
    updateCrate: builder.mutation<null, { ltrCrateId: string; body: ICrateUpdate }>({
      query: ({ ltrCrateId, body }) => ({
        url: `${ApiResource.Ltr}/crates/${ltrCrateId}`,
        body: body,
        method: 'PATCH'
      }),
      invalidatesTags: [ApiTag.Ltr]
    }),
    updateModule: builder.mutation<null, { ltrModuleId: string; body: ILtrModuleUpdate }>({
      query: ({ ltrModuleId, body }) => ({
        url: `${ApiResource.Ltr}/modules/${ltrModuleId}`,
        body: body,
        method: 'PATCH'
      }),
      invalidatesTags: [ApiTag.Ltr]
    }),
    updateChannelInModule: builder.mutation<
      null,
      { ltrModuleId: string; channelNumber: string; body: ILtr24ChannelUpdate }
    >({
      query: ({ ltrModuleId, channelNumber, body }) => ({
        url: `${ApiResource.Ltr}/modules/${ltrModuleId}/channel/${channelNumber}`,
        body: body,
        method: 'PUT'
      }),
      invalidatesTags: [ApiTag.Ltr]
    }),
    dropAllChannelConnections: builder.mutation<null, { ltrModuleId: string; channelNumber: string }>({
      query: ({ ltrModuleId, channelNumber }) => ({
        url: `${ApiResource.Ltr}/modules/${ltrModuleId}/channel/${channelNumber}/drop-all-connections`,
        method: 'POST'
      }),
      invalidatesTags: [ApiTag.Ltr, ApiTag.Connections]
    })
  })
})

export const {
  useGetCratesQuery,
  useGetCrateQuery,
  useGetLtrModulesQuery,
  useGetLtrModuleQuery,
  useGetLtrModulesByCrateQuery,
  useLazyGetLtrModulesByCrateQuery,
  useUpdateCrateMutation,
  useUpdateModuleMutation,
  useUpdateChannelInModuleMutation,
  useDropAllChannelConnectionsMutation
} = ltrApi
