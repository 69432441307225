export const Vp3711Label = {
  Name: 'Наименование крейта',
  CrateSerial: 'Серийный номер крейта',
  Comment: 'Комментарий',
  Location: 'Местоположение',
  StatusUpdatingTimestamp: 'Время обновления статуса',
  Status: 'Статус',
  BoardRevision: 'Ревизия платы',
  BootloaderVersion: 'Версия загрузчика',
  CpuType: 'Тип процессора',
  SoftwareVersion: 'Версия ПО',
  FpgaName: 'Наименование FPGA',
  FpgaVersion: 'Версия FPGA',
  CrateTypeName: 'Тип крейта'
} as const
