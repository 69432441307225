import ConnectionEquipmentsTree from '@/app/configuration/components/connections/components/connection-equipments-tree/connection-equipments-tree'
import ConnectionPanel from '@/app/configuration/components/connections/components/connection-panel/connection-panel'
import Submenu from '@/components/UI/elements/submenu/submenu'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const Connections: FC = () => {
  const { selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { selectedDeviceId, deviceContent } = useTypedSelector((state) => state.devicesReducer)
  return (
    <Container>
      <ToolBar />
      <LeftBar>
        <ConnectionEquipmentsTree />
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />
        <ConnectionPanel pointId={selectedPointId} deviceId={selectedDeviceId} deviceContent={deviceContent} />
      </WorkSpace>
    </Container>
  )
}

export default Connections
