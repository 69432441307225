import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetInfoQuery } from '@/store/api/info.api'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import type { FC, PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'

import styles from './status-board.module.css'

const FORMAT_DATE = 'D MMMM YYYY, HH:mm'

const StatusBoard: FC<PropsWithChildren> = ({ children }) => {
  const { version } = useTypedSelector((state) => state.serverSettingsReducer)
  const { data: dataInfo } = useGetInfoQuery()
  const { server } = useTypedSelector((state) => state.serverSettingsReducer)
  const [currentDate, setCurrentDate] = useState('')

  useEffect(() => {
    // Обновляем дату каждую минуту
    const intervalId = setInterval(() => {
      setCurrentDate(dayjs().locale('ru').format(FORMAT_DATE))
    }, 1000)

    // Чистим интервал при размонтировании компонента
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className={styles['status-board']}>
      <Flex gap={5} align='center'>
        <p className={styles['text']}>{currentDate}</p>
        <p className={styles['text']}>Клиент {version}</p>
        <p className={styles['text']}>Сервер {dataInfo?.version}</p>
        <p className={styles['text']}>Адр. сервера {server?.url}</p>
      </Flex>
      {children}
    </div>
  )
}

export default StatusBoard
