import TablePlans from '@/app/plans/components/plans-main-content/components/table-plans/table-plans'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import { EMachineDroppable } from '@/enums/machines/machine-droppable.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachinesQuery } from '@/store/api/machines.api'
import { useGetPlansQuery } from '@/store/api/plans.api'
import type { IMachineInfo } from '@/types/machine.type'
import { formatDate } from '@/utils/format-date'
import cn from 'classnames'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import styles from '@/app/plans/components/plans-main-content/plans-main-content.module.css'

import PlansList from './components/plans-list/PlansList'

const PlansMainContent: FC = () => {
  const { addPlan, updatePlan } = useActions()

  const { plans } = useTypedSelector((state) => state.planReducer)

  const {
    data: dataPlans,
    isSuccess: isSuccessPlans,
    isFetching: isFetchingPlans,
    refetch: refetchGetPlans
  } = useGetPlansQuery()
  const { data: dataMachines, isSuccess: isSuccessMachines } = useGetMachinesQuery()

  //TODO переделать в будущем
  useEffect(() => {
    if (isSuccessPlans && isSuccessMachines) {
      const planDefaultSettings = {
        data: []
      }

      dataPlans?.content.forEach((plan) => {
        const duplicatePlan = plans.find((planInStore) => planInStore.planId === plan.planId)
        const changedPlan = { ...plan }
        const machines: IMachineInfo[] = []
        const dateNone = '---------- --:--:--'

        changedPlan.modified = formatDate(String(changedPlan?.modified), DATE_TEMPLATE)

        changedPlan.lastExecutionTimestamp =
          changedPlan.lastExecutionTimestamp === null
            ? dateNone
            : formatDate(String(changedPlan?.lastExecutionTimestamp), DATE_TEMPLATE)

        changedPlan.nextExecutionTimestamp =
          changedPlan.nextExecutionTimestamp === null
            ? dateNone
            : formatDate(String(changedPlan?.nextExecutionTimestamp), DATE_TEMPLATE)

        if (changedPlan?.machineIds && changedPlan?.machineIds?.length > 0) {
          changedPlan?.machineIds?.forEach((machineId) => {
            const foundMachine = dataMachines?.content?.find((machine) => machine.machineId === machineId)

            if (foundMachine) {
              machines.push({ ...foundMachine, points: [] })
            }
          })
        }

        if (duplicatePlan) {
          updatePlan({ ...duplicatePlan, ...changedPlan })
        } else {
          addPlan({ ...planDefaultSettings, ...changedPlan, machines })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccessPlans,
    isSuccessMachines,
    dataPlans?.content,
    dataMachines?.content,
    updatePlan,
    addPlan,
    isFetchingPlans
  ])

  useEffect(() => {
    const intervalGetPlans = setInterval(refetchGetPlans, 10000)

    return () => {
      clearInterval(intervalGetPlans)
    }
  }, [refetchGetPlans])

  return (
    <Droppable droppableId={EMachineDroppable.PLANS}>
      {(droppableProvided, snapshot) => (
        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={styles.container}>
          <WorkSpace>
            <div className={styles['inner-wrapper']}>
              <div className={styles['main-left-container']}>
                <TablePlans plans={plans} />
              </div>
              <div
                className={cn(styles['main-right-container'], {
                  [styles['main-right-container-on-drag-active']]: snapshot.isDraggingOver
                })}
              >
                {snapshot.isDraggingOver && (
                  <div className={styles['drop-info']}>
                    <span className={cn('material-symbols-outlined', styles['place-item'])}>place_item</span>
                    <p className={styles['drop-info-text']}>Перенести машину сюда</p>
                  </div>
                )}
                <PlansList />
              </div>
            </div>
          </WorkSpace>
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default PlansMainContent
