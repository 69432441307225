import Vp3701Errors from '@/app/configuration/components/devices/vp3701-panel/components/vp3701-erros/vp3701-errors'
import {
  mapDataToForm,
  mapDataToStatic
} from '@/app/configuration/components/devices/vp3701-panel/vp3701-panel.service'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import PanelButton from '@/components/UI/buttons/panel-button/panel-button'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Popup from '@/components/UI/elements/popup/popup'
import Spinner from '@/components/UI/elements/spinner/spinner'
import SwitchIndicator from '@/components/UI/elements/switch-indicator/switch-indicator'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/UI/panel/panel-title/panel-title'
import { ButtonTitle, DATE_TEMPLATE } from '@/constants/core/common.constant'
import { PowerType } from '@/constants/device/power-type.constant'
import { Vp3701Labels } from '@/constants/device/vp3701/vp3701-labels.constant'
import { Vp3701Params } from '@/constants/device/vp3701/vp3701-params.constant'
import { mapUnitType } from '@/constants/measurement/unit.constant'
import { EDefviceStatus } from '@/enums/device/defvice-staatus.enum'
import { EMeasuringPointConnectionStatus } from '@/enums/device/measuring-point-connection-status.enum'
import useActions from '@/hooks/use-actions'
import { useGetVp3701Query, useUpdateVp3701Mutation } from '@/store/api/devices.api'
import type { TVp3701Form } from '@/types/device/vp3701/vp3701-form.type'
import type { TVp3701Static } from '@/types/device/vp3701/vp3701-static.type'
import { formatDate } from '@/utils/format-date'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { ProfileOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Flex, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import cn from 'classnames'
import { isUndefined } from 'lodash'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './vp3701.module.css'

interface IProps {
  deviceId: string | undefined
}

const Vp3701Panel: FC<IProps> = ({ deviceId }) => {
  const { setSelectedDeviceId } = useActions()
  const { data: vp3701, isLoading } = useGetVp3701Query(deviceId, {
    skip: !deviceId,
    pollingInterval: 1000
  })
  const [triggerUpdateVp3701, { isLoading: isLoadingUpdateVp3701 }] = useUpdateVp3701Mutation()
  const [staticData, setStaticData] = useState<TVp3701Static>({})
  const [openedModal, setOpenedModal] = useState<boolean>(false)
  const [vp3701Form] = useForm<TVp3701Form>()

  useEffect(() => {
    if (vp3701) {
      setStaticData(mapDataToStatic(vp3701))
      vp3701Form.setFieldsValue(mapDataToForm(vp3701))
    }
  }, [vp3701, vp3701Form])

  if (isLoading) {
    return <Spinner />
  }

  if (!vp3701 || !deviceId) {
    return null
  }

  const handleUpdateVp3701Finish = async () => {
    const fields = vp3701Form.getFieldsValue()
    try {
      if (fields.name) {
        await triggerUpdateVp3701({ deviceId: deviceId, body: fields }).unwrap()
        successNotificationCreate('Параметры устройства обновлены')
      }
    } catch (e) {
      console.error(e)
      errorNotificationCreate(e)
    }
  }

  const statusUpdatingTimestamp = staticData.statusUpdatingTimestamp
    ? formatDate(staticData.statusUpdatingTimestamp, DATE_TEMPLATE)
    : undefined

  const batteryCharge = staticData.batteryCharge ? staticData.batteryCharge.toFixed(2) : undefined

  const isOnlineDevice = staticData.status === EDefviceStatus.ONLINE

  const status = staticData.status ? (
    <SwitchIndicator sourceBoolean={isOnlineDevice} trueContent='Устройство онлайн' falseContent='Устройство оффлайн' />
  ) : undefined

  const connectionStatus = staticData.connectionStatus ? (
    <SwitchIndicator
      sourceBoolean={staticData.connectionStatus === EMeasuringPointConnectionStatus.MEASURING_POINT_CONNECTED}
      trueContent='Устройство подключено к ТИ'
      falseContent='Связь с ТИ отсутствует'
    />
  ) : undefined

  const channelUnitType1 = staticData.channelUnitType1 ? mapUnitType[staticData.channelUnitType1] : undefined
  const channelUnitType2 = staticData.channelUnitType2 ? mapUnitType[staticData.channelUnitType2] : undefined
  const channelUnitType3 = staticData.channelUnitType3 ? mapUnitType[staticData.channelUnitType3] : undefined
  const channelUnitType4 = staticData.channelUnitType4 ? mapUnitType[staticData.channelUnitType4] : undefined
  const channelUnitType5 = staticData.channelUnitType5 ? mapUnitType[staticData.channelUnitType5] : undefined

  const powerType = staticData.powerType ? PowerType[staticData.powerType] : undefined

  const handleExitClick = () => {
    setSelectedDeviceId(undefined)
  }

  const handleToggleModalClick = () => {
    setOpenedModal(!openedModal)
  }

  const totalCountErros = vp3701.lastErrors?.reduce(
    (previousValue, currentValue) => (previousValue += currentValue.count),
    0
  )

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex gap={5} align='center'>
            <VideoCameraOutlined rotate={90} />
            <span>Измерительное устройство {vp3701.name}</span>
          </Flex>
        }
      />
      <ContentWrapper>
        <Form
          className={styles.container}
          form={vp3701Form}
          disabled={isLoadingUpdateVp3701}
          onFinish={handleUpdateVp3701Finish}
        >
          <ControlsWrapper>
            <PanelTitle>Параметры устройства</PanelTitle>
            <FormItemApp name={Vp3701Params.Name} label={Vp3701Labels.Name}>
              <Input />
            </FormItemApp>
            <TextStatic label={Vp3701Labels.SerialNumber} value={staticData.serialNumber} />
            <TextStatic label={Vp3701Labels.Modification} value={staticData.modification} />
            <TextStatic label={Vp3701Labels.Ssid} value={staticData.ssid} />
            <TextStatic label={Vp3701Labels.Firmware} value={staticData.firmware} />
            <TextStatic label={Vp3701Labels.PowerType} value={powerType} />
            <TextStatic label={Vp3701Labels.AccelType} value={staticData.accelType} />
            <TextStatic label={Vp3701Labels.ThermoType} value={staticData.thermoType} />
            <FormItemApp name={Vp3701Params.Location} label={Vp3701Labels.Location}>
              <TextArea className={styles['text-area']} />
            </FormItemApp>
            <FormItemApp name={Vp3701Params.Comment} label={Vp3701Labels.Comment}>
              <TextArea className={styles['text-area']} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <PanelTitle>Измерительные каналы VP-3701</PanelTitle>
            <TextStatic label={Vp3701Labels.ChannelUnitType1} value={channelUnitType1} />
            <TextStatic label={Vp3701Labels.ChannelUnitType2} value={channelUnitType2} />
            <TextStatic label={Vp3701Labels.ChannelUnitType3} value={channelUnitType3} />
            <TextStatic label={Vp3701Labels.ChannelUnitType4} value={channelUnitType4} />
            <TextStatic label={Vp3701Labels.ChannelUnitType5} value={channelUnitType5} />
            <PanelTitle className={styles['title']}>Статус устройства</PanelTitle>
            <TextStatic label={Vp3701Labels.Status} value={status} />
            <TextStatic label={Vp3701Labels.BatteryCharge} value={batteryCharge} />
            <TextStatic label={Vp3701Labels.ConnectionStatus} value={connectionStatus} />
            <TextStatic label={Vp3701Labels.StatusUpdatingTimestamp} value={statusUpdatingTimestamp} />
            <Flex justify='space-between' align='center' className={styles['container-error']}>
              <p className={styles['error-text']}>
                Количество ошибок:{' '}
                {!isUndefined(totalCountErros) && (
                  <span className={cn({ [styles['red-count']]: totalCountErros > 0 })}>{totalCountErros}</span>
                )}
              </p>
              <PanelButton icon={<ProfileOutlined />} onClick={handleToggleModalClick}>
                Открыть журнал ошибок
              </PanelButton>
            </Flex>
          </ControlsWrapper>
          <ControlsWrapper>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                title={ButtonTitle.Exit}
                isLoadingSkeleton={false}
                isLoading={false}
                disabled={false}
                onClick={handleExitClick}
              />
              <ButtonPrimary htmlType='submit' title={ButtonTitle.Save} loading={isLoadingUpdateVp3701} />
            </ButtonsWrapper>
          </ControlsWrapper>
          <Popup isOpen={openedModal} title={`Журнал ошибок ${vp3701.name}`} onCancel={handleToggleModalClick}>
            <Vp3701Errors errors={vp3701.lastErrors} />
          </Popup>
        </Form>
      </ContentWrapper>
    </>
  )
}

export default Vp3701Panel
