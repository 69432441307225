import { GroupTree, KEY_GROUP } from '@/app/configuration/components/menu-tree/menu-tree.constant'
import {
  findParentKeys,
  mapMachinesToTree,
  mapMeasurementToTree,
  mapPointsToTree
} from '@/app/configuration/components/menu-tree/menu-tree.service'
import MachineIcon from '@/components/icons/equipments/machine-icon'
import measurementIcon from '@/components/icons/equipments/measurement-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useLazyGetMachinesQuery } from '@/store/api/machines.api'
import { useLazyGetMeasurementsQuery } from '@/store/api/measurements.api'
import { useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { IMachineInfo } from '@/types/machine.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import { updateTreeData } from '@/utils/tree/update-tree-data'
import { Tree } from 'antd'
import type { EventDataNode } from 'antd/es/tree'
import cn from 'classnames'
import type { FC, Key } from 'react'
import { useCallback, useEffect, useState } from 'react'

import styles from './menu-tree.module.css'

const initialTreeStructure = [
  {
    key: KEY_GROUP,
    title: 'Группа машин',
    group: GroupTree.Group,
    icon: <span className={cn('material-symbols-outlined', styles['icon'])}>factory</span>
  }
]

interface IProps {
  machineId?: string
  pointId?: string
  measurementId?: string
}

const MenuTree: FC<IProps> = ({ machineId, pointId, measurementId }) => {
  const { treeMenu, selectedTreeItems } = useTypedSelector((state) => state.equipmentsUiReducer)
  const [getMachines] = useLazyGetMachinesQuery()
  const {
    setSelectedMachineId,
    setSelectedMeasurementId,
    setSelectedPointId,
    setEquipmentView,
    resetSelectedForMachine,
    setTreeMenu,
    setSelectedTreeItems
  } = useActions()
  const [getPoints] = useLazyGetMeasuringPointsQuery()
  const [getMeasurements] = useLazyGetMeasurementsQuery()
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])

  const updateTreeByMachines = useCallback(
    (machines: IMachineInfo[], key: string) => {
      const machinesTree = mapMachinesToTree(machines, MachineIcon)
      const updatedTreeData = updateTreeData(treeMenu, key, machinesTree)
      setTreeMenu(updatedTreeData)
    },
    [setTreeMenu, treeMenu]
  )

  const updateTreeByPoints = useCallback(
    (points: ISelectedMeasuringPoint[], key: string) => {
      const pointsTree = mapPointsToTree(points, PointIcon)
      const updatedTreeData = updateTreeData(treeMenu, key, pointsTree)
      setTreeMenu(updatedTreeData)
    },
    [setTreeMenu, treeMenu]
  )

  const updateTreeByMeasurements = useCallback(
    (measurements: IMeasurement[], key: string) => {
      const machinesTree = mapMeasurementToTree(measurements, measurementIcon)
      const updatedTreeData = updateTreeData(treeMenu, key, machinesTree)
      setTreeMenu(updatedTreeData)
    },
    [setTreeMenu, treeMenu]
  )

  const handleDataLoad = useCallback(
    async (treeNode: EventDataNode<ITreeNode>) => {
      const { key } = treeNode
      const id = key as string
      if (treeNode.group === GroupTree.Group) {
        const { content: machines } = await getMachines().unwrap()
        updateTreeByMachines(machines, id)
      }

      if (treeNode.group === GroupTree.Machine) {
        const { content: points } = await getPoints(id).unwrap()
        updateTreeByPoints(points, id)
      }

      if (treeNode.group === GroupTree.Points) {
        const { content: measurements } = await getMeasurements(id).unwrap()
        updateTreeByMeasurements(measurements, id)
      }
    },
    [getMachines, updateTreeByMachines, getPoints, updateTreeByPoints, getMeasurements, updateTreeByMeasurements]
  )

  useEffect(() => {
    setTreeMenu(initialTreeStructure)
  }, [setTreeMenu])

  useEffect(() => {
    if (expandedKeys.length === 0) {
      if (machineId && pointId && measurementId) {
        setExpandedKeys([KEY_GROUP, machineId, pointId])
        setSelectedTreeItems([measurementId])
        setEquipmentView({
          content: EEquipmentContent.MEASUREMENT,
          mode: EViewMode.EDITING
        })
        return
      }

      if (pointId && machineId) {
        setExpandedKeys([KEY_GROUP, machineId])
        setSelectedTreeItems([pointId])
        setEquipmentView({
          content: EEquipmentContent.POINT,
          mode: EViewMode.EDITING
        })
        return
      }

      if (machineId) {
        setExpandedKeys([KEY_GROUP])
        setSelectedTreeItems([machineId])
        setEquipmentView({
          content: EEquipmentContent.MACHINE,
          mode: EViewMode.EDITING
        })
        return
      }

      setExpandedKeys([KEY_GROUP])
      return
    }
  }, [expandedKeys.length, machineId, measurementId, pointId, setSelectedTreeItems])

  const handleExpand = (keys: Key[]) => {
    setExpandedKeys(keys)
  }

  const handleSelect = (_: Key[], info: { node: EventDataNode<ITreeNode> }) => {
    const { group, key } = info.node
    const selectedId = key as string
    setSelectedTreeItems([selectedId])
    const parentsKeys = findParentKeys(selectedId, treeMenu)
    setExpandedKeys([...expandedKeys, selectedId])

    switch (group) {
      case GroupTree.Group:
        setEquipmentView({
          content: EEquipmentContent.NULL,
          mode: EViewMode.NULL
        })
        resetSelectedForMachine()
        break

      case GroupTree.Machine:
        setEquipmentView({
          content: EEquipmentContent.MACHINE,
          mode: EViewMode.EDITING
        })
        setSelectedMachineId(selectedId)
        setSelectedPointId(undefined)
        setSelectedMeasurementId(undefined)
        break

      case GroupTree.Points:
        setEquipmentView({
          content: EEquipmentContent.POINT,
          mode: EViewMode.EDITING
        })
        setSelectedMachineId(parentsKeys[1] as string)
        setSelectedPointId(selectedId)
        setSelectedMeasurementId(undefined)
        break

      case GroupTree.Measurement:
        setEquipmentView({
          content: EEquipmentContent.MEASUREMENT,
          mode: EViewMode.EDITING
        })
        setSelectedMachineId(parentsKeys[1] as string)
        setSelectedPointId(parentsKeys[2] as string)
        setSelectedMeasurementId(selectedId)
        break

      default:
        break
    }
  }

  return (
    <Tree
      showIcon={true}
      treeData={treeMenu}
      showLine={true}
      loadData={handleDataLoad}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      onSelect={handleSelect}
      selectedKeys={selectedTreeItems}
      className={styles['tree']}
    />
  )
}

export default MenuTree
