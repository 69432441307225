import type { FC } from 'react'

import styles from './common-title.module.css'

interface IProps {
  title: string
}

const CommonTitle: FC<IProps> = ({ title }) => (
  <div className={styles['common-title']}>
    <h2 className={styles['title']}>{title}</h2>
  </div>
)

export default CommonTitle
