import { CdParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form.constant'
import type {
  ICdOuts,
  IFormCd
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form.type'
import {
  formatDataForTable,
  getCalculatedResults,
  parseDataForChange,
  parseServerData
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/cd-form/cd-form.util'
import { ControlPanel } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import { KbSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/kb-source/kb-source'
import { OutTable } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/out-table/out-table'
import {
  KbParam,
  TypeSource,
  optionsTypeSourceWithKb
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type {
  IInitialData,
  IOptionOutSource,
  ITableDataOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IIn, IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateExcludedValue, validateMinValue } from '@/utils/validation-rules'
import { Form, InputNumber, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'

import styles from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TWheelType = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const CdForm: FC<TWheelType> = ({ onClose, kinematicElement, image }) => {
  const [changeKinematicElement, { isLoading: isUpdating }] = useChangeKinematicElementMutation()
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [disabledCalculateButton, setDisabledCalculateButton] = useState(false)
  const [tableDataOuts, setTableDataOuts] = useState<ITableDataOuts[] | undefined>(undefined)
  const [initialData, setInitialData] = useState<IInitialData<IFormCd, ICdOuts> | null>(null)
  const [outs, setOuts] = useState<ICdOuts | null>(null)
  const [ins, setIns] = useState<IIn[] | null>(null)
  const [cdForm] = useForm<IFormCd>()
  const typeSourceObservable = useWatch(KbParam.TypeSource, cdForm)
  const fieldsObservable = useWatch([], cdForm)
  const isInitialData = isEqual(initialData?.fields, cdForm.getFieldsValue()) && isEqual(initialData?.outs, outs)

  const resetForm = useCallback(() => {
    cdForm.resetFields()
    setOuts(null)
    setTableDataOuts(undefined)
  }, [cdForm])

  useEffect(() => {
    if (outs) {
      const result = formatDataForTable(outs)
      setTableDataOuts(result)
    }
  }, [outs])

  useEffect(() => {
    const { setFieldsValue } = cdForm
    resetForm()

    if (kinematicElement) {
      const result = parseServerData(kinematicElement)
      setFieldsValue(result.fields)
      setOuts(result.outs)
      setIns(result.ins)
      setInitialData(result)
    }
  }, [cdForm, kinematicElement, resetForm])

  useEffect(() => {
    setDisabledFinishButton(true)
  }, [cdForm, fieldsObservable])

  useEffect(() => {
    const { validateFields } = cdForm

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields({
          validateOnly: true,
          recursive: true
        })
        if (!isInitialData) {
          setDisabledCalculateButton(false)
        }
      } catch (e) {
        setDisabledCalculateButton(true)
      }
    }

    if (!disabledCancelButton) {
      checkValidateFields()
    }
  }, [cdForm, fieldsObservable, disabledCancelButton, initialData, isInitialData])

  useEffect(() => {
    setDisabledCancelButton(isInitialData)
    setDisabledCalculateButton(isInitialData)
  }, [isInitialData])

  const handleChangeSelect = (): void => {
    cdForm.resetFields([KbParam.KinematicBlockSourceId, KbParam.FreqOutSource])
  }

  const handleCalculation = async () => {
    const { getFieldsValue, validateFields } = cdForm
    const fieldsValue = getFieldsValue()

    try {
      if (ins && kinematicElement?.kinematicBlockName) {
        await validateFields()
        const result = getCalculatedResults(fieldsValue, ins, kinematicElement.kinematicBlockName)
        setOuts(result)
        successNotificationCreate('Коэффициенты успешно расчитаны')
        setDisabledFinishButton(false)
        setDisabledCalculateButton(true)
      }
    } catch (e) {
      setDisabledFinishButton(true)
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
    }
  }

  const handleSelectOutFreq = (_: number, option: IOptionOutSource) => {
    setIns([
      {
        freqInValue: option.value,
        freqOutNameSourse: option.freqOutNameSourse,
        freqOutCoefMachineSourse: option.freqOutCoefMachineSourse,
        freqOutCoefIndexSourse: option.freqOutCoefIndexSourse,
        freqInName: null,
        measurementSourseId: null,
        freqCoefForIn: null,
        kinematicBlockSourseId: null,
        freqInCoefMachine: null
      }
    ])
  }

  const handleFinish = async () => {
    const { getFieldsValue, validateFields } = cdForm
    const fieldValues = getFieldsValue()

    try {
      try {
        await validateFields()
      } catch (e) {
        errorNotificationCreate(e, {
          message: 'Форма содержит ошибки'
        })
        return
      }

      if (kinematicElement && ins && outs) {
        const requestDataParsed = parseDataForChange(kinematicElement, fieldValues, ins, outs)
        await changeKinematicElement(requestDataParsed).unwrap()

        successNotificationCreate(`Кинематический блок ${kinematicElement.kinematicBlockName} сохранён`)

        onClose()
        resetForm()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = cdForm
      resetFields()
      setFieldsValue(initialData.fields)
      setIns(initialData.ins)
      setOuts(initialData.outs)
    }
  }

  return (
    <>
      <Form className={styles['drawer-kinematic-form']} onFinish={handleFinish} form={cdForm}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
        <div className={styles['drawer-kinematic-header']}>
          <Form.Item name={KbParam.Description} className={styles['drawer-kinematic-text-area']}>
            <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} />
          </Form.Item>
          <div className={styles['drawer-kinematic-image-wrapper']}>
            <img className={styles['drawer-kinematic-image-vertical']} src={image} alt='' />
          </div>
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Источник</h2>
        <Form.Item
          label='Тип источника'
          name={KbParam.TypeSource}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо выбрать тип источника'
            },
            {
              validator: validateExcludedValue({
                excludedValue: TypeSource.Missing,
                errorMessage: 'Необходимо выбрать тип источника'
              })
            }
          ]}
        >
          <Select
            placeholder='Выберите тип источника'
            options={optionsTypeSourceWithKb}
            onChange={handleChangeSelect}
          />
        </Form.Item>
        {typeSourceObservable !== TypeSource.Missing && (
          <>
            <h2 className={styles['drawer-kinematic-subtitle']}>Вход 1 (in1)</h2>

            <KbSource
              typeSource={typeSourceObservable}
              kinematicSchemeId={kinematicElement?.kinematicSchemeId}
              form={cdForm}
              onSelectOutFreq={handleSelectOutFreq}
              currentKinematicBlockId={kinematicElement?.kinematicBlockId}
            />
            <Form.Item
              label='Коэффициент частоты КБ'
              name={KbParam.FreqCoefForIn}
              className={styles['drawer-kinematic-input-item']}
              rules={[
                {
                  required: true,
                  message: 'Введите коэффициент входной частоты'
                },
                {
                  validator: validateMinValue(0)
                }
              ]}
            >
              <InputNumber
                decimalSeparator={','}
                className={styles['drawer-kinematic-input-number']}
                placeholder='Введите коэффициент входной частоты'
                min={0}
              />
            </Form.Item>
          </>
        )}
        <h2 className={styles['drawer-kinematic-subtitle']}>Параметры</h2>
        <Form.Item
          name={CdParamName.Z1}
          label='Количество зубьев ведущего колеса'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество зубьев ведущего колеса'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество зубьев ведущего колеса'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={CdParamName.Z2}
          label='Количество зубьев ведомого колеса'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество зубьев ведомого колеса'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество зубьев ведомого колеса'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={CdParamName.Z3}
          label='Количество звеньев цепи'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество звеньев цепи'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество звеньев цепи'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Выход (out1)</h2>
        <OutTable tableDataOuts={tableDataOuts} />
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickCalculation={handleCalculation}
        onClickSave={handleFinish}
        disabledCalculation={disabledCalculateButton}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </>
  )
}

export default CdForm
