import cn from 'classnames'
import type { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/UI/panel/panel-title/form-title.module.css'

type FormTitleProps = {
  withoutBorder?: boolean
  className?: string
  size?: 'S' | 'M' | 'L'
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

const PanelTitle: FC<PropsWithChildren<FormTitleProps>> = ({
  children,
  className,
  size = 'M',
  withoutBorder,
  ...props
}) => (
  <h4
    className={cn(styles['form-title'], className || '', {
      [styles['panel-title-small']]: size === 'S',
      [styles['panel-title--medium']]: size === 'M',
      [styles['panel-title-large']]: size === 'L',
      [styles['without-border']]: withoutBorder
    })}
    {...props}
  >
    {children}
  </h4>
)

export default PanelTitle
