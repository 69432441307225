import CollapseGenerator from '@/app/reports/components/collapse-generator/collapse-generator'
import CollapseVp3701 from '@/app/reports/components/collapse-vp3701/collapse-vp3701'
import MenuCollapsed from '@/app/reports/components/menu-collapsed/menu-collapsed'
import ReportsToolbar from '@/app/reports/components/reports-right-toolbar/reports-toolbar'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import { ReportKey } from '@/constants/reports.constant'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetGeneratorsQuery, useGetVp3701ListQuery } from '@/store/api/devices.api'
import type { FC } from 'react'

const Reports: FC = () => {
  const { selectedReport } = useTypedSelector((state) => state.reportsReducer)
  const { checkedData } = useTypedSelector((state) => state.reportsReducer)
  const { data: dataVp3701, isLoading: isLoadingVp3701 } = useGetVp3701ListQuery()
  const { data: dataGenerators, isLoading: isLoadingGenerators } = useGetGeneratorsQuery()
  const vp3701 = dataVp3701?.content
  const generators = dataGenerators?.content

  const isVp3701 = selectedReport?.key === ReportKey.Vp3701
  const isGenerator = selectedReport?.key === ReportKey.Square || selectedReport?.key === ReportKey.Harmonic

  return (
    <Container>
      <ReportsToolbar />
      <LeftBar>
        <MenuCollapsed selectedKeys={[selectedReport?.key || '']} />
      </LeftBar>
      <WorkSpace>
        {isVp3701 && <CollapseVp3701 checkedData={checkedData} dataVp3701={vp3701} isLoading={isLoadingVp3701} />}
        {isGenerator && (
          <CollapseGenerator
            selectedReport={selectedReport}
            checkedData={checkedData}
            dataGenerator={generators}
            isLoading={isLoadingGenerators}
          />
        )}
      </WorkSpace>
    </Container>
  )
}

export default Reports
