import { BdParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.constant'
import type {
  IFormBd,
  IInitialData,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.type'
import {
  getCalculatedResults,
  parseDataForChangeBd,
  parseServerData
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.util'
import { OutTable } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/components/out-table/out-table'
import { BrParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.constant'
import { ControlPanel } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import { KbSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/kb-source/kb-source'
import {
  TypeSource,
  optionsTypeSourceWithKb
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IOptionOutSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateExcludedValue, validateMinValue } from '@/utils/validation-rules'
import { Form, InputNumber, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TSdForm = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const BdForm: FC<TSdForm> = ({ onClose, kinematicElement, image }) => {
  const [changeKinematicElement, { isLoading: isUpdating }] = useChangeKinematicElementMutation()
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [disabledCalculateButton, setDisabledCalculateButton] = useState(false)
  const [initialData, setInitialData] = useState<IInitialData | null>(null)
  const [outs, setOuts] = useState<IOuts | null>(null)
  const [bdForm] = useForm<IFormBd>()
  const typeSourceObservable = useWatch(BrParamName.TypeSource, bdForm)
  const fieldsObservable = useWatch([], bdForm)

  useEffect(() => {
    const { setFieldsValue, resetFields } = bdForm
    resetFields()
    setOuts(null)

    if (kinematicElement) {
      const { fieldsData, outsData } = parseServerData(kinematicElement)
      setFieldsValue(fieldsData)
      setOuts(outsData)
      setInitialData({
        fields: fieldsData,
        outs: outsData
      })
    }
  }, [bdForm, kinematicElement])

  useEffect(() => {
    setDisabledFinishButton(true)
  }, [bdForm, fieldsObservable])

  useEffect(() => {
    const { validateFields, getFieldsValue } = bdForm
    const isInitialData = isEqual(initialData?.fields, getFieldsValue())

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields({
          validateOnly: true,
          recursive: true
        })
        if (!isInitialData) {
          setDisabledCalculateButton(false)
        }
      } catch (e) {
        setDisabledCalculateButton(true)
      }
    }

    if (!disabledCancelButton) {
      checkValidateFields()
    }
  }, [bdForm, fieldsObservable, disabledCancelButton, initialData])

  useEffect(() => {
    const { getFieldsValue } = bdForm
    const isInitialData = isEqual(initialData?.fields, getFieldsValue())
    setDisabledCancelButton(isInitialData)
    setDisabledCalculateButton(isInitialData)
  }, [bdForm, initialData, fieldsObservable])

  const handleCalculation = async () => {
    const { getFieldsValue, validateFields } = bdForm
    const fieldsValue = getFieldsValue()
    try {
      await validateFields([
        BdParamName.TypeSource,
        BdParamName.KinematicBlockSourceId,
        BdParamName.FreqOutSource,
        BdParamName.FreqCoefForIn,
        BdParamName.D1,
        BdParamName.D2,
        BdParamName.L
      ])

      const result = getCalculatedResults(fieldsValue, kinematicElement?.kinematicBlockName)

      setOuts(result)
      successNotificationCreate('Коэффициенты успешно расчитаны')
      setDisabledFinishButton(false)
      setDisabledCalculateButton(true)
    } catch (e) {
      setDisabledFinishButton(true)
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
    }
  }

  const handleSelectOutFreq = (_: number, options: IOptionOutSource) => {
    bdForm.setFieldValue(BdParamName.FreqOutSource, options)
  }

  const handleFinish = async () => {
    const { getFieldsValue, validateFields } = bdForm
    const fieldValues = getFieldsValue()

    try {
      try {
        await validateFields()
      } catch (e) {
        errorNotificationCreate(e, {
          message: 'Форма содержит ошибки'
        })
        return
      }

      if (kinematicElement && outs) {
        const requestDataParsed = parseDataForChangeBd(kinematicElement, fieldValues, outs)

        const { kinematicBlockName } = await changeKinematicElement(requestDataParsed).unwrap()

        successNotificationCreate(`Кинематический блок ${kinematicBlockName} сохранён`)

        onClose()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = bdForm
      resetFields()
      setFieldsValue(initialData.fields)
      setOuts(initialData.outs)
    }
  }

  return (
    <>
      <Form className={styles['drawer-kinematic-form']} onFinish={handleFinish} form={bdForm}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
        <div className={styles['drawer-kinematic-header']}>
          <Form.Item name={BdParamName.Description} className={styles['drawer-kinematic-text-area']}>
            <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} />
          </Form.Item>
          <div className={styles['drawer-kinematic-image-wrapper']}>
            <img className={styles['drawer-kinematic-image-vertical']} src={image} alt='' />
          </div>
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Вход (in1)</h2>
        <Form.Item
          label='Тип источника'
          name={BdParamName.TypeSource}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо выбрать тип источника'
            },
            {
              validator: validateExcludedValue({
                excludedValue: TypeSource.Missing,
                errorMessage: 'Необходимо выбрать тип источника'
              })
            }
          ]}
        >
          <Select placeholder='Выберите тип источника' options={optionsTypeSourceWithKb} />
        </Form.Item>
        <KbSource
          typeSource={typeSourceObservable}
          kinematicSchemeId={kinematicElement?.kinematicSchemeId}
          form={bdForm}
          onSelectOutFreq={handleSelectOutFreq}
          currentKinematicBlockId={kinematicElement?.kinematicBlockId}
        />
        <Form.Item
          label='Коэффициент частоты КБ'
          name={BdParamName.FreqCoefForIn}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Введите коэффициент входной частоты'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            className={styles['drawer-kinematic-input-number']}
            placeholder='Введите коэффициент входной частоты'
            min={0}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Параметры</h2>
        <Form.Item
          name={BdParamName.D1}
          label='Диаметр ведущего шкива (D1)'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать диаметр ведущего шкива'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите диаметр ведущего шкива'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={BdParamName.D2}
          label='Диаметр ведомого шкива (D2)'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать диаметр ведомого шкива'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите диаметр ведомого шкива'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={BdParamName.L}
          label='Длина ремня (L)'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать длину ремня'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите длина ремня'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Выход (out1)</h2>
        <OutTable outs={outs} />
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickCalculation={handleCalculation}
        onClickSave={handleFinish}
        disabledCalculation={disabledCalculateButton}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </>
  )
}

export default BdForm
