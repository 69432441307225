import type { TMeasurementStatic } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import { freqLimitOptions, specterOptions } from '@/constants/measurement/measurements.constant'
import { ESpecterLineCount } from '@/enums/measurment/specter-line-count.enum'
import { EVisualFreqLimit } from '@/enums/measurment/visual-freq-limit.enum'
import { Select } from 'antd'
import type { FC } from 'react'

type TProps = {
  isEditMode: boolean
  isAddMode: boolean
  staticData?: TMeasurementStatic
}

const SpecterForm: FC<TProps> = ({ isEditMode, isAddMode, staticData }) => {
  const staticFreqLimitOptions = freqLimitOptions.find(
    (item) => item.value === staticData?.programSpecterDescription?.visualFreqLimit
  )
  const staticSpecterLineCountOptions = specterOptions.find(
    (item) => item.value === staticData?.programSpecterDescription?.specterLineCount
  )

  return (
    <>
      {isAddMode && (
        <>
          <FormItemApp
            name={['programSpecterDescription', 'visualFreqLimit']}
            label='Граничная частота'
            initialValue={EVisualFreqLimit.RATE_5000}
            rules={[{ required: true, message: 'Поле обязательно' }]}
          >
            <Select options={freqLimitOptions} />
          </FormItemApp>

          <FormItemApp
            name={['programSpecterDescription', 'specterLineCount']}
            label='Количество линий'
            initialValue={ESpecterLineCount.COUNT_2_6400_8192}
            rules={[{ required: true, message: 'Поле обязательно' }]}
          >
            <Select options={specterOptions} />
          </FormItemApp>
        </>
      )}
      {isEditMode && (
        <>
          <TextStatic label='Граничная частота' value={staticFreqLimitOptions?.label} />
          <TextStatic label='Количество линий' value={staticSpecterLineCountOptions?.label} />
        </>
      )}
    </>
  )
}

export default SpecterForm
