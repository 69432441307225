import DocTooltip from '@/components/doc-tooltip/doc-tooltip'
import { bearingFormFields } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bearing-form/bearing-form.constant'
import { bearingCoefficients } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.constant'
import { handleValue } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IBearing, ICoefficientsTableBearing } from '@/types/bearing/bearing.type'
import { formatFloat } from '@/utils/common'
import { validateMaxValue, validateMinValue } from '@/utils/validation-rules'
import { Form, type FormInstance, InputNumber, Table } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import type { ColumnsType } from 'antd/es/table'
import type { FC } from 'react'

import styles from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TCoefficientTable = {
  editable: boolean
  formAddBearing: FormInstance<IBearing>
}
export const CoefficientTable: FC<TCoefficientTable> = ({ editable, formAddBearing }) => {
  const kftf = useWatch(bearingFormFields.kftf.name, formAddBearing)
  const kftfo = useWatch(bearingFormFields.kftfo.name, formAddBearing)
  const kbsf = useWatch(bearingFormFields.kbsf.name, formAddBearing)
  const kbpfo = useWatch(bearingFormFields.kbpfo.name, formAddBearing)
  const kbpfi = useWatch(bearingFormFields.kbpfi.name, formAddBearing)
  const coefficients: { [key in string]: number | null } = {
    kftf,
    kftfo,
    kbsf,
    kbpfo,
    kbpfi
  }

  const tableData = bearingCoefficients.map<ICoefficientsTableBearing>((element, index) => {
    const coefficientValue = coefficients[element.type]

    return {
      key: index,
      label: {
        name: element.name,
        tooltip: element.tooltip
      },
      coefficient: {
        value: handleValue(coefficientValue),
        typeField: element.type
      }
    }
  })

  const handleChangeKftf = (value: number | null): void => {
    if (value) {
      const result = formatFloat(1 - value, REAL_NUMBER)
      formAddBearing.setFieldValue(bearingFormFields.kftfo.name, result)
      formAddBearing.validateFields([bearingFormFields.kftfo.name])
    }
  }

  const handleChangeKftfo = (value: number | null): void => {
    if (value) {
      const result = formatFloat(1 - value, REAL_NUMBER)
      formAddBearing.setFieldValue(bearingFormFields.kftf.name, result)
      formAddBearing.validateFields([bearingFormFields.kftf.name])
    }
  }

  const columns: ColumnsType<ICoefficientsTableBearing> = [
    {
      dataIndex: 'label',
      render: (content) => (
        <div className={styles['drawer-kinematic-space-table-column']}>
          <DocTooltip simpleText={content.tooltip} />
          <strong>{content.name}</strong>
        </div>
      )
    },
    {
      title: 'Коэффициенты',
      dataIndex: 'coefficient',
      render: (content) => {
        const typeField = bearingFormFields[content.typeField]

        const getInputElement: FC<{
          name: string
          placeholder: string
          value: string
        }> = ({ name, placeholder, value }) => {
          switch (name) {
            case 'kftf':
              return (
                <Form.Item
                  name={name}
                  className={styles['drawer-kinematic-input-item-table']}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: typeField.errorMessage
                    },
                    {
                      validator: validateMinValue(0)
                    },
                    {
                      validator: validateMaxValue(1)
                    }
                  ]}
                >
                  {editable ? (
                    <InputNumber
                      className={styles['drawer-kinematic-input-number']}
                      placeholder={placeholder}
                      min={0}
                      max={1}
                      decimalSeparator={','}
                      onChange={handleChangeKftf}
                      step={0.01}
                    />
                  ) : (
                    <span>{value}</span>
                  )}
                </Form.Item>
              )
            case 'kftfo':
              return (
                <Form.Item
                  name={name}
                  className={styles['drawer-kinematic-input-item-table']}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: typeField.errorMessage
                    },
                    {
                      validator: validateMinValue(0)
                    },
                    {
                      validator: validateMaxValue(1)
                    }
                  ]}
                >
                  {editable ? (
                    <InputNumber
                      className={styles['drawer-kinematic-input-number']}
                      placeholder={placeholder}
                      decimalSeparator={','}
                      onChange={handleChangeKftfo}
                      min={0}
                      max={1}
                      step={0.01}
                    />
                  ) : (
                    <span>{value}</span>
                  )}
                </Form.Item>
              )
            default:
              return (
                <Form.Item
                  name={name}
                  className={styles['drawer-kinematic-input-item-table']}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: typeField.errorMessage
                    },
                    {
                      validator: validateMinValue(0)
                    }
                  ]}
                >
                  {editable ? (
                    <InputNumber
                      className={styles['drawer-kinematic-input-number']}
                      placeholder={placeholder}
                      decimalSeparator={','}
                      step={0.01}
                    />
                  ) : (
                    <span>{value}</span>
                  )}
                </Form.Item>
              )
          }
        }
        return getInputElement({
          name: typeField.name,
          placeholder: typeField.placeholder,
          value: content.value
        })
      }
    }
  ]

  return (
    <Table
      className={styles['drawer-kinematic-table']}
      columns={columns}
      dataSource={tableData}
      bordered={true}
      pagination={false}
    />
  )
}
