import { ELtr24RangeType } from '@/enums/ltr/ltr24-range-type.enum'
import type { IOptions } from '@/types/common/options.interface'

export const scaleTypeOptions: IOptions<ELtr24RangeType>[] = [
  {
    label: '2',
    value: ELtr24RangeType.LTR24_RANGE_2
  },
  {
    label: '10',
    value: ELtr24RangeType.LTR24_RANGE_10
  },
  {
    label: '1',
    value: ELtr24RangeType.LTR24_ICP_RANGE_1
  },
  {
    label: '5',
    value: ELtr24RangeType.LTR24_ICP_RANGE_5
  }
]

// ЕСЛИ ICP включен, то два последних.
