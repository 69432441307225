import { ELtr24ChannelType } from '@/enums/ltr/ltr24-channel-type.enum'
import { ELtr24RangeType } from '@/enums/ltr/ltr24-range-type.enum'

interface IValuesByChannelType {
  conditions: {
    ltr24ChanelType: ELtr24ChannelType
  }
  data: {
    gainCoefficient?: number
    scaleType?: ELtr24RangeType
    icpMode?: boolean
  }
  disabled: {
    icpMode: boolean
  }
}

export const valuesByChannelTypes: IValuesByChannelType[] = [
  {
    conditions: {
      ltr24ChanelType: ELtr24ChannelType.LIN
    },
    data: {
      icpMode: false
    },
    disabled: {
      icpMode: false
    }
  },
  {
    conditions: {
      ltr24ChanelType: ELtr24ChannelType.ICP
    },
    data: {
      icpMode: false
    },
    disabled: {
      icpMode: true
    }
  },
  {
    conditions: {
      ltr24ChanelType: ELtr24ChannelType.CURRENT
    },
    data: {
      scaleType: ELtr24RangeType.LTR24_RANGE_2,
      icpMode: false
    },
    disabled: {
      icpMode: true
    }
  },
  {
    conditions: {
      ltr24ChanelType: ELtr24ChannelType.EDDY
    },
    data: {
      icpMode: false
    },
    disabled: {
      icpMode: true
    }
  }
]
