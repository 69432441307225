import TablePlansTitleCell from '@/app/plans/components/plans-main-content/components/table-plans-title-cell/table-plans-title-cell'
import useActions from '@/hooks/use-actions'
import type { TSortingValues } from '@/types/plan/plan.type'
import type { FC } from 'react'
import React, { useState } from 'react'

import styles from './table-plans-title.module.css'

const TablePlansTitle: FC = () => {
  const { sortingPlans } = useActions()

  const [sortingState, setSortingState] = useState<TSortingValues | ''>('')

  const handleSortingButton = (
    sortingTag: 'name' | 'device' | 'status' | 'modified' | 'nextExecutionTimestamp' | 'lastExecutionTimestamp'
  ) => {
    const changedSortingTag: TSortingValues = sortingState === sortingTag ? `${sortingTag}Reverse` : sortingTag

    setSortingState(changedSortingTag)

    sortingPlans(changedSortingTag)
  }

  return (
    <ul className={styles['sorting-container']}>
      <TablePlansTitleCell
        sortingState={sortingState}
        title={'Наименование'}
        handleSortingButton={handleSortingButton}
        tag={'name'}
      />

      <TablePlansTitleCell
        sortingState={sortingState}
        title={'Устройство'}
        handleSortingButton={handleSortingButton}
        tag={'device'}
      />

      <TablePlansTitleCell
        sortingState={sortingState}
        title={'Статус'}
        handleSortingButton={handleSortingButton}
        tag={'status'}
      />

      <TablePlansTitleCell
        sortingState={sortingState}
        title={'Дата'}
        handleSortingButton={handleSortingButton}
        tag={'modified'}
      />

      <TablePlansTitleCell
        sortingState={sortingState}
        title={'Плановая дата'}
        handleSortingButton={handleSortingButton}
        tag={'nextExecutionTimestamp'}
      />

      <TablePlansTitleCell
        sortingState={sortingState}
        title={'Время измерения'}
        handleSortingButton={handleSortingButton}
        tag={'lastExecutionTimestamp'}
      />
    </ul>
  )
}

export default TablePlansTitle
