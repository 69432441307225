import { KbType } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { EKinematicBlockType } from '@/types/kinematic/kinematic.type'

export const mapTypeBr: { [key in EKinematicBlockType]: string } = {
  [KbType.BR]: 'Подшипник качения',
  [KbType.CP]: 'Муфта',
  [KbType.SH]: 'Вал',
  [KbType.BS]: 'Подшипниковая опора скольжения',
  [KbType.BF]: 'Подшипниковая опора жидкостного трения (ПЖТ)',
  [KbType.AD]: 'Асинхронный двигатель',
  [KbType.SD]: 'Синхронный двигатель',
  [KbType.SG]: 'Синхронный генератор',
  [KbType.DCM]: 'Двигатель постоянного тока',
  [KbType.DCG]: 'Генератор постоянного тока',
  [KbType.GB]: 'Одноступенчатая зубчатая передача',
  [KbType.GBD]: 'Двухступенчатая зубчатая передача со сменой направления вращения',
  [KbType.GBT]: 'Двухступенчатая зубчатая передача с разделением момента',
  [KbType.TGB]: 'Двухвходовая двухступенчатая зубчатая передача',
  [KbType.TW]: 'Рабочее колесо турбины / вентилятора',
  [KbType.CW]: 'Рабочее колесо компрессор / насос',
  [KbType.TN]: 'Турбина',
  [KbType.CN]: 'Компрессор',
  [KbType.BD]: 'Ременная передача',
  [KbType.CD]: 'Цепная передача',
  [KbType.HD]: 'Гидропривод',
  [KbType.PD]: 'Пневмо / газовый привод'
}
