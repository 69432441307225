import type { ICharFreqTree } from '@/types/kinematic/char-freq.type'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'

export const getTreeCharFreqs = (kinematicElements: IKinematicElement[]): ICharFreqTree[] =>
  kinematicElements.map<ICharFreqTree>((element) => ({
    key: element.kinematicBlockId,
    title: element.kinematicBlockName,
    value: element.kinematicBlockId,
    selectable: false,
    children: element.outs
      ? element.outs.map((out) => {
          const key = `${element.kinematicBlockId}-${out.freqOutName}-${out.freqOutValue}`
          return {
            title: `${out.freqOutName} ${out.freqOutValue}`,
            labelTitle: `${out.freqOutValue}`,
            value: key,
            key: key,
            payload: out
          }
        })
      : []
  }))
