export const LtrModuleLabel = {
  Name: 'Наименование модуля',
  Comment: 'Комментарий',
  CrateSerial: 'Серийный номер крейта',
  SlotNumber: 'Номер слота',
  ModuleType: 'Тип модуля',
  Serial: 'Серийный номер',
  Status: 'Статус модуля',
  VersionPLD: 'Версия PLD',
  SupportICP: 'Поддержка ICP',
  TestMode: 'Режим тестирования',
  CountChannels: 'Количество каналов'
}
