import About from '@/app/about/about'
import Connections from '@/app/configuration/components/connections/connections'
import KinematicsConfiguration from '@/app/configuration/components/kinematics-configuration/kinematics-configuration'
import MnemonicConfiguration from '@/app/configuration/components/mnemonic-configuration/mnemonic-configuration'
import Configuration from '@/app/configuration/configuration'
import EmptyComponent from '@/app/machine-condition/components/empty-component/empty-component'
import MnemonicCondition from '@/app/machine-condition/components/mnemonic-condition/mnemonic-condition'
import Results from '@/app/machine-condition/components/results/results'
import NotImplemented from '@/app/not-implemented/not-implemented'
import ComponentSettings from '@/app/settings/components/component-settings/component-settings'
import ServerSettings from '@/app/settings/components/server-settings/server-settings'
import StatusBoard from '@/components/UI/layouts/status-board/status-board'
import Header from '@/components/header/header'
import AnalysisModal from '@/components/modals/analysis-modal'
import { DesignTokensAntd } from '@/constants/core/design-tokens-antd.constant'
import {
  ConfigurationRoute,
  MachineConditionRoute,
  MainRoute,
  SettingsRoute
} from '@/constants/navigation/routes.constant'
import withDragDropContextHoc from '@/hocs/with-drag-drop-context.hoc'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/locale/ru_RU'
import type { FC } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'

import Devices from './configuration/components/devices/devices'
import Equipments from './configuration/components/equipments/equipments'
import Home from './home/home'
import Description from './machine-condition/components/description/description'
import KinematicsCondition from './machine-condition/components/kinematics-condition/kinematics-condition'
import MachineCondition from './machine-condition/machine-condition'
import Plans from './plans/plans'
import Reports from './reports/reports'
import Settings from './settings/settings'

const MachineConditionWithDnd = withDragDropContextHoc(MachineCondition)
const PlanWithDnd = withDragDropContextHoc(Plans)

const AppRoutes: FC = () => (
  <>
    <Header />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path={MainRoute.MachineCondition} element={<MachineConditionWithDnd />}>
        <Route path={MachineConditionRoute.DescriptionCondition} element={<Description />} />
        <Route path={`${MachineConditionRoute.DescriptionCondition}/:machineId`} element={<Description />} />
        <Route path={MachineConditionRoute.MnemonicCondition} element={<MnemonicCondition />} />
        <Route path={`${MachineConditionRoute.MnemonicCondition}/:machineId`} element={<MnemonicCondition />} />
        <Route path={MachineConditionRoute.Results} element={<Results />} />
        <Route path={MachineConditionRoute.WorkCondition} element={<EmptyComponent />} />
        <Route path={MachineConditionRoute.KinematicsCondition} element={<KinematicsCondition />} />
        <Route path={`${MachineConditionRoute.KinematicsCondition}/:machineId`} element={<KinematicsCondition />} />
      </Route>
      <Route path={MainRoute.Configuration} element={<Configuration />}>
        <Route path={ConfigurationRoute.Equipments} element={<Equipments />} />
        <Route path={ConfigurationRoute.MnemonicMap} element={<MnemonicConfiguration />} />
        <Route path={ConfigurationRoute.KinematicsConfig} element={<KinematicsConfiguration />} />
        <Route path={ConfigurationRoute.Devices} element={<Devices />} />
        <Route path={`${ConfigurationRoute.Devices}`} element={<Devices />} />
        <Route path={`${ConfigurationRoute.Connections}`} element={<Connections />} />
        <Route path={`${ConfigurationRoute.Connections}/:equipmentId`} element={<Connections />} />
      </Route>
      <Route path={MainRoute.Plans} element={<PlanWithDnd />} />
      <Route path={MainRoute.Reports} element={<Reports />} />
      <Route path={MainRoute.Settings} element={<Settings />}>
        <Route path={SettingsRoute.Main} element={<ComponentSettings />} />
        <Route path={SettingsRoute.Profile} element={<ComponentSettings />} />
        <Route path={SettingsRoute.Server} element={<ServerSettings />} />
        <Route path={SettingsRoute.License} element={<ComponentSettings />} />
      </Route>
      <Route path={MainRoute.About} element={<About />} />
      <Route path='*' element={<NotImplemented />} />
    </Routes>
    <StatusBoard>
      <AnalysisModal />
    </StatusBoard>
  </>
)

const App: FC = () => {
  const { isDesktop } = useTypedSelector((state) => state.serverSettingsReducer)

  return (
    <ConfigProvider locale={ru_RU} theme={DesignTokensAntd}>
      {isDesktop ? (
        <HashRouter>
          <AppRoutes />
        </HashRouter>
      ) : (
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      )}
    </ConfigProvider>
  )
}

export default App
