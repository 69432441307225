import { ETypeControl } from '@/enums/plan/type-control.enum'
import type { SelectProps } from 'antd'

export const controlTypeOptions: SelectProps['options'] = [
  {
    label: 'Оперативный',
    value: ETypeControl.OPERATIONAL
  },
  {
    label: 'Циклический',
    value: ETypeControl.CYCLIC
  },
  {
    label: 'Периодический',
    value: ETypeControl.PERIODIC
  }
] as const

export const AddPlanFieldName = {
  Name: 'name',
  DeviceType: 'deviceType',
  PlanType: 'planType'
} as const

export const AddPlanFieldLabel = {
  Name: 'Наименование маршрута',
  DeviceType: 'Тип устройства',
  PlanType: 'Тип маршрута'
} as const
