export const MainRoute = {
  MachineCondition: 'machine-condition',
  ExpertAnalysis: 'expert-analysis',
  Plans: 'plans',
  OffRoute: 'off-route',
  Reports: 'reports',
  Configuration: 'configuration',
  Settings: 'settings',
  Support: 'support',
  About: 'about',
  Exit: 'exit'
}

export const MachineConditionRoute = {
  DescriptionCondition: 'description-condition',
  MnemonicCondition: 'mnemonic-condition',
  Results: 'results',
  WorkCondition: 'work-condition',
  KinematicsCondition: 'kinematics-condition'
}

export const ConfigurationRoute = {
  Equipments: 'equipment',
  MnemonicMap: 'mnemonic-configuration',
  KinematicsConfig: 'kinematics-config',
  Devices: 'devices',
  Connections: 'connections'
}

export const SettingsRoute = {
  Main: 'main',
  Profile: 'profile',
  Server: 'server',
  License: 'license'
}
