import Spinner from '@/components/UI/elements/spinner/spinner'
import { ApiTag } from '@/constants/store/api.constants'
import useActions from '@/hooks/use-actions'
import { useAppDispatch } from '@/hooks/use-app-dispatch'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { baseApi } from '@/store/api/api-base-query'
import { useGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import cn from 'classnames'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import styles from './points-list.module.css'

import PointItem from './components/point-item'

const PointsList: FC = () => {
  const dispatch = useAppDispatch()
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const [pointsForDnd, setPointsForDnd] = useState<ISelectedMeasuringPoint[]>([])

  const { setSelectedPointId } = useActions()

  const { data: pointsResponse, isFetching } = useGetMeasuringPointsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const points = pointsResponse?.content

  useEffect(() => {
    dispatch(baseApi.util.invalidateTags([ApiTag.MeasurementsResult]))
    if (points) {
      setPointsForDnd(points)
    }
  }, [dispatch, points])

  const handlePointSelect = (id: ISelectedMeasuringPoint['pointId']) => {
    if (id) {
      setSelectedPointId(id)
    }
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const newPoints = [...pointsForDnd]
    const [removed] = newPoints.splice(result.source.index, 1)
    newPoints.splice(result.destination.index, 0, removed)

    setPointsForDnd(newPoints)
  }

  if (isFetching) {
    return <Spinner />
  }

  return selectedMachineId ? (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='points' direction='vertical'>
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={styles.container}>
            {pointsForDnd.map((point, index) => (
              <Draggable key={point.pointId} draggableId={point.pointId} index={index}>
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    className={cn(styles.draggable, {
                      [styles.dragging]: draggableSnapshot.isDragging
                    })}
                  >
                    <PointItem onSelect={handlePointSelect} key={point.pointId} point={point} />
                  </div>
                )}
              </Draggable>
            ))}
            {pointsForDnd.length === 0 && (
              <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>Точки измерения отсутствуют</p>
            )}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : null
}

export default PointsList
