import type { TMeasurementStatic } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import { commonLevels, detectors } from '@/constants/measurement/measurements.constant'
import { ECommonLevelType } from '@/enums/measurment/common-level-type.enum'
import { EDetector } from '@/enums/measurment/detector.enum'
import { Select } from 'antd'
import type { FC } from 'react'

type TProps = {
  isEditMode: boolean
  isAddMode: boolean
  staticData?: TMeasurementStatic
}

const CommonLevelForm: FC<TProps> = ({ isEditMode, isAddMode, staticData }) => {
  const commonLevelsOption = commonLevels.find(
    (item) => item.value === staticData?.commonLevelDescription?.commonLevelType
  )
  return (
    <>
      {isAddMode && (
        <>
          <FormItemApp
            label='Детектор'
            name={['commonLevelDescription', 'detector']}
            initialValue={EDetector.SKZ}
            rules={[{ required: true, message: 'Поле обязательно' }]}
          >
            <Select options={detectors} />
          </FormItemApp>

          <FormItemApp
            label='Полоса, Гц'
            name={['commonLevelDescription', 'commonLevelType']}
            initialValue={ECommonLevelType.LEVEL_2_1000}
            rules={[{ required: true, message: 'Поле обязательно' }]}
          >
            <Select options={commonLevels} />
          </FormItemApp>
        </>
      )}
      {isEditMode && (
        <>
          <TextStatic label='Детектор' value='СКЗ' />
          <TextStatic label='Полоса, Гц' value={commonLevelsOption?.label} />
        </>
      )}
    </>
  )
}

export default CommonLevelForm
