import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import PopupMnemonic from '@/components/mnemonic/popup-mnemonic/popup-mnemonic'
import { useGetMnemonics } from '@/hooks/api/use-get-mnemonics'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { useState } from 'react'

const MnemonicCommands: FC = () => {
  const [isPopupMnemonicOpen, setIsPopupMnemonicOpen] = useState(false)
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { isLoading: isLoadingMnemonic } = useGetMnemonics()
  if (!selectedMachineId || isLoadingMnemonic) {
    return null
  }

  return (
    <>
      <PopupMnemonic setIsPopupOpen={setIsPopupMnemonicOpen} isPopupOpen={isPopupMnemonicOpen} />
      <ButtonToolbar
        icon={'add'}
        onClick={() => {
          setIsPopupMnemonicOpen(true)
        }}
        title={'Добавить мнемосхему'}
      />
    </>
  )
}

export default MnemonicCommands
