import { FormBranch } from '@/constants/devices.constant'
import { SliceName } from '@/constants/store/slices.constant'
import type { EDeviceContent } from '@/enums/device/device-content.enum'
import type { IDeviceInitialState } from '@/types/device/device-initial-state.interface'
import type { TDeviceNode } from '@/types/device/device-node.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IDeviceInitialState = {
  handleForceUpdate: () => null,
  isEditGenerator: false,
  isOpenPopupGenerator: false,
  formGeneratorStep: 1,
  formGeneratorBranch: FormBranch.Common,
  formGeneratorData: null,
  moduleOneActive: 0,
  moduleTwoActive: 0,
  isLoadingData: false,
  selectedGeneratorId: undefined,
  selectedDeviceId: undefined,
  deviceContent: undefined,
  deviceNode: undefined
}

export const devicesSlice = createSlice({
  name: SliceName.Devices,
  initialState,
  reducers: {
    setIsEditGenerator(state, action: PayloadAction<boolean>) {
      state.isEditGenerator = action.payload
    },
    setIsOpenPopupGenerator(state, action: PayloadAction<boolean>) {
      state.isOpenPopupGenerator = action.payload
    },
    setFormGeneratorBranch(state, action: PayloadAction<FormBranch>) {
      state.formGeneratorBranch = action.payload
    },
    setFormGeneratorStep(state, action: PayloadAction<number>) {
      state.formGeneratorStep = action.payload
    },
    setModuleOneActive(state, action: PayloadAction<number>) {
      const value = action.payload
      state.moduleOneActive = value
    },
    setModuleTwoActive(state, action: PayloadAction<number>) {
      state.moduleTwoActive = action.payload
    },
    setSelectedDeviceId(state, action: PayloadAction<string | undefined>) {
      state.selectedDeviceId = action.payload
    },
    setDeviceContent(state, action: PayloadAction<EDeviceContent | undefined>) {
      state.deviceContent = action.payload
    },
    setDeviceNode(state, action: PayloadAction<TDeviceNode | undefined>) {
      state.deviceNode = action.payload
    }
  }
})
