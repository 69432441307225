import { EUnitType } from '@/enums/measurment/unit-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { IOptions } from '@/types/common/options.interface'

export const mapUnitType = {
  [EUnitType.NULL_UNIT_TYPE]: 'Без типа',
  [EUnitType.MS2]: 'м/с²',
  [EUnitType.G]: 'g',
  [EUnitType.MMS2]: 'мм/с²',
  [EUnitType.MMS]: 'мм/с',
  [EUnitType.MS]: 'м/с',
  [EUnitType.MKM]: 'мкм',
  [EUnitType.MM]: 'мм',
  [EUnitType.M]: 'м',
  [EUnitType.A]: 'А',
  [EUnitType.MA]: 'мА',
  [EUnitType.MKA]: 'мкА',
  [EUnitType.VOLT]: 'В',
  [EUnitType.M_VOLT]: 'мВ',
  [EUnitType.U_VOLT]: 'мкВ',
  [EUnitType.DEGREES]: '°C',
  [EUnitType.ROTATION_COUNT_IN_SEC]: 'Гц',
  [EUnitType.ROTATION_COUNT_IN_MINUTE]: 'об/мин',
  [EUnitType.D_ROTATION_COUNT_IN_SEC]: 'Гц',
  [EUnitType.D_ROTATION_COUNT_IN_MINUTE]: 'об/мин',
  [EUnitType.DEGREES_CELSIUS]: '°C',
  [EUnitType.DEGREES_FAHRENHEIT]: '°F',
  [EUnitType.DEGREES_KELVIN]: '°K'
}

export const mapPhysicalQuantity = {
  [EUnitType.MS2]: EPhysicalQuantityType.VIBRO_ACCELERATION,
  [EUnitType.G]: EPhysicalQuantityType.VIBRO_ACCELERATION,
  [EUnitType.MMS2]: EPhysicalQuantityType.VIBRO_ACCELERATION,

  [EUnitType.MMS]: EPhysicalQuantityType.VIBRO_VELOCITY,
  [EUnitType.MS]: EPhysicalQuantityType.VIBRO_VELOCITY,

  [EUnitType.MKM]: EPhysicalQuantityType.VIBRO_DISPLACEMENT,
  [EUnitType.MM]: EPhysicalQuantityType.VIBRO_DISPLACEMENT,
  [EUnitType.M]: EPhysicalQuantityType.VIBRO_DISPLACEMENT,

  [EUnitType.A]: EPhysicalQuantityType.AMPERAGE,
  [EUnitType.MA]: EPhysicalQuantityType.AMPERAGE,
  [EUnitType.MKA]: EPhysicalQuantityType.AMPERAGE,

  [EUnitType.VOLT]: EPhysicalQuantityType.VOLTAGE,
  [EUnitType.M_VOLT]: EPhysicalQuantityType.VOLTAGE,
  [EUnitType.U_VOLT]: EPhysicalQuantityType.VOLTAGE,

  [EUnitType.DEGREES]: EPhysicalQuantityType.TEMPERATURE,
  [EUnitType.DEGREES_CELSIUS]: EPhysicalQuantityType.TEMPERATURE,
  [EUnitType.DEGREES_FAHRENHEIT]: EPhysicalQuantityType.TEMPERATURE,
  [EUnitType.DEGREES_KELVIN]: EPhysicalQuantityType.TEMPERATURE,

  [EUnitType.ROTATION_COUNT_IN_SEC]: EPhysicalQuantityType.RPM,
  [EUnitType.ROTATION_COUNT_IN_MINUTE]: EPhysicalQuantityType.RPM,

  [EUnitType.D_ROTATION_COUNT_IN_SEC]: EPhysicalQuantityType.DRPM,
  [EUnitType.D_ROTATION_COUNT_IN_MINUTE]: EPhysicalQuantityType.DRPM,

  [EUnitType.NULL_UNIT_TYPE]: EPhysicalQuantityType.NULL
}

export const unitBaseAccelerationOptions: IOptions<EUnitType>[] = [
  {
    value: EUnitType.MS2,
    label: mapUnitType[EUnitType.MS2],
    title: `Виброускорение, ${mapUnitType[EUnitType.MS2]}`
  },
  {
    value: EUnitType.G,
    label: mapUnitType[EUnitType.G],
    title: `Виброускорение, ${mapUnitType[EUnitType.G]}`
  },
  {
    value: EUnitType.MMS2,
    label: mapUnitType[EUnitType.MMS2],
    title: `Виброускорение, ${mapUnitType[EUnitType.MMS2]}`
  }
]

export const unitBaseVelocityOptions: IOptions<EUnitType>[] = [
  {
    value: EUnitType.MMS,
    label: mapUnitType[EUnitType.MMS],
    title: `Скорость, ${mapUnitType[EUnitType.MMS]}`
  },
  {
    value: EUnitType.MS,
    label: mapUnitType[EUnitType.MS],
    title: `Скорость, ${mapUnitType[EUnitType.MS]}`
  }
]

export const unitBaseDisplacementOptions: IOptions<EUnitType>[] = [
  {
    value: EUnitType.MKM,
    label: mapUnitType[EUnitType.MKM],
    title: `Перемещение, ${mapUnitType[EUnitType.MKM]}`
  },
  {
    value: EUnitType.MM,
    label: mapUnitType[EUnitType.MM],
    title: `Перемещение, ${mapUnitType[EUnitType.MM]}`
  },
  {
    value: EUnitType.M,
    label: mapUnitType[EUnitType.M],
    title: `Перемещение, ${mapUnitType[EUnitType.M]}`
  }
]

export const unitOptionsAcceleration: IOptions<EUnitType>[] = [
  {
    value: EUnitType.MS2,
    label: mapUnitType[EUnitType.MS2],
    title: `Виброускорение, ${mapUnitType[EUnitType.MS2]}`
  },
  {
    value: EUnitType.MMS,
    label: mapUnitType[EUnitType.MMS],
    title: `Скорость, ${mapUnitType[EUnitType.MMS]}`
  },
  {
    value: EUnitType.MKM,
    label: mapUnitType[EUnitType.MKM],
    title: `Перемещение, ${mapUnitType[EUnitType.MKM]}`
  },
  {
    value: EUnitType.G,
    label: mapUnitType[EUnitType.G],
    title: `Виброускорение, ${mapUnitType[EUnitType.G]}`
  },
  {
    value: EUnitType.MMS2,
    label: mapUnitType[EUnitType.MMS2],
    title: `Виброускорение, ${mapUnitType[EUnitType.MMS2]}`
  },

  {
    value: EUnitType.MS,
    label: mapUnitType[EUnitType.MS],
    title: `Скорость, ${mapUnitType[EUnitType.MS]}`
  },

  {
    value: EUnitType.MM,
    label: mapUnitType[EUnitType.MM],
    title: `Перемещение, ${mapUnitType[EUnitType.MM]}`
  },
  {
    value: EUnitType.M,
    label: mapUnitType[EUnitType.M],
    title: `Перемещение, ${mapUnitType[EUnitType.M]}`
  }
]

export const unitOptionsByTemperature: IOptions<EUnitType>[] = [
  {
    value: EUnitType.DEGREES_CELSIUS,
    label: mapUnitType[EUnitType.DEGREES_CELSIUS]
  },
  {
    value: EUnitType.DEGREES_FAHRENHEIT,
    label: mapUnitType[EUnitType.DEGREES_FAHRENHEIT]
  },
  {
    value: EUnitType.DEGREES_KELVIN,
    label: mapUnitType[EUnitType.DEGREES_KELVIN]
  }
]

export const unitOptionsVoltage: IOptions<EUnitType>[] = [
  {
    value: EUnitType.VOLT,
    label: mapUnitType[EUnitType.VOLT]
  },
  {
    value: EUnitType.M_VOLT,
    label: mapUnitType[EUnitType.M_VOLT]
  },
  {
    value: EUnitType.U_VOLT,
    label: mapUnitType[EUnitType.U_VOLT]
  }
]

export const unitOptionsAmperage: IOptions<EUnitType>[] = [
  {
    value: EUnitType.A,
    label: mapUnitType[EUnitType.A]
  },
  {
    value: EUnitType.MA,
    label: mapUnitType[EUnitType.MA]
  },
  {
    value: EUnitType.MKA,
    label: mapUnitType[EUnitType.MKA]
  }
]

export const unitOptionsRPM: IOptions<EUnitType>[] = [
  {
    value: EUnitType.ROTATION_COUNT_IN_SEC,
    label: mapUnitType[EUnitType.ROTATION_COUNT_IN_SEC]
  },
  {
    value: EUnitType.ROTATION_COUNT_IN_MINUTE,
    label: mapUnitType[EUnitType.ROTATION_COUNT_IN_MINUTE]
  }
]

export const unitOptionsDRPM: IOptions<EUnitType>[] = [
  {
    value: EUnitType.D_ROTATION_COUNT_IN_SEC,
    label: mapUnitType[EUnitType.D_ROTATION_COUNT_IN_SEC]
  },
  {
    value: EUnitType.D_ROTATION_COUNT_IN_MINUTE,
    label: mapUnitType[EUnitType.D_ROTATION_COUNT_IN_MINUTE]
  }
]

export const mapBaseVibrationUnits: { [key in string]: EUnitType } = {
  [EUnitType.MS2]: EUnitType.MS2,
  [EUnitType.G]: EUnitType.MS2,
  [EUnitType.MMS2]: EUnitType.MS2,
  [EUnitType.MMS]: EUnitType.MMS,
  [EUnitType.MS]: EUnitType.MMS,
  [EUnitType.MKM]: EUnitType.MKM,
  [EUnitType.MM]: EUnitType.MKM,
  [EUnitType.M]: EUnitType.MKM
}
