import type { FC } from 'react'

import styles from '../../chart-spectrum.module.css'

interface IProps {
  skz: number
  am: number
  fm: number
}

const LegendSection: FC<IProps> = ({ skz, am, fm }) => (
  <div className={styles['legend-block']}>
    <p className={styles['block-text']}>
      <span className={styles['block-text-strong']}>СКЗ</span>: {skz.toPrecision(6)}
    </p>
    <p className={styles['block-text']}>
      <span className={styles['block-text-strong']}>A max</span>: {am.toPrecision(6)}
    </p>
    <p className={styles['block-text']}>
      <span className={styles['block-text-strong']}>F max</span>: {fm.toPrecision(6)}
    </p>
  </div>
)

export default LegendSection
