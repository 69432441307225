import type { TToleranceControlForm } from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold.interface'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import { ButtonTitle } from '@/constants/core/common.constant'
import { ThresholdLabel, ThresholdName } from '@/constants/threshold/threshold.constant'
import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Form, InputNumber } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { orderBy } from 'lodash'
import type { FC } from 'react'
import React, { useEffect } from 'react'

interface IProps {
  checkDuplicateRiftValue: () => (test: unknown, value: string) => Promise<void>
}

const FormEditToleranceControl: FC<IProps> = ({ checkDuplicateRiftValue }) => {
  const { setIsOpenThresholdPopup, setRifts, setSelectedRift } = useActions()
  const [toleranceControlEditForm] = useForm<TToleranceControlForm>()
  const {
    riftCalcType,
    riftCalcEnabled,
    rifts,
    selectedRift: selectedRiftIndex,
    baseRiftValue
  } = useTypedSelector((state) => state.thresholdReducer)
  const isIncrement = riftCalcType === ERiftCalcType.INCREMENT
  const isMultiplication = riftCalcType === ERiftCalcType.MULTIPLICATION

  useEffect(() => {
    const selectedRift = rifts[selectedRiftIndex]
    toleranceControlEditForm.setFieldsValue(selectedRift)
  }, [rifts, selectedRiftIndex, toleranceControlEditForm])

  const handleEditZone = () => {
    const { coef, hysteresisCoef, riftValue } = toleranceControlEditForm.getFieldsValue()

    const editableRift = {
      riftValue: riftCalcEnabled ? (isIncrement ? coef + baseRiftValue : coef * baseRiftValue) : riftValue,
      hysteresisCoef: hysteresisCoef,
      coef: riftCalcEnabled ? coef : 0
    }

    const newRifts = [...rifts]
    newRifts.splice(selectedRiftIndex, 1, editableRift)
    const orderedNewRifts = orderBy(newRifts, 'riftValue', 'desc')
    const foundIndexEditableRift = orderedNewRifts.indexOf(editableRift)

    setRifts(orderedNewRifts)
    setSelectedRift(foundIndexEditableRift)
    setIsOpenThresholdPopup(false)
    toleranceControlEditForm.resetFields()
  }

  const handlePopupClose = () => {
    setIsOpenThresholdPopup(false)
  }

  return (
    <Form form={toleranceControlEditForm} onFinish={handleEditZone}>
      {riftCalcEnabled ? (
        <FormItemApp
          name={ThresholdName.Coef}
          label={isMultiplication ? ThresholdLabel.Coef : ThresholdLabel.Increment}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <InputNumber />
        </FormItemApp>
      ) : (
        <FormItemApp
          label={ThresholdLabel.RiftValue}
          name={ThresholdName.RiftValue}
          rules={[{ required: true, message: 'Поле обязательно' }, { validator: checkDuplicateRiftValue() }]}
        >
          <InputNumber />
        </FormItemApp>
      )}
      <FormItemApp
        label={ThresholdLabel.HysteresisCoef}
        name={ThresholdName.HysteresisCoef}
        rules={[{ required: true, message: 'Поле обязательно' }]}
      >
        <InputNumber />
      </FormItemApp>
      <ButtonsWrapper>
        <ButtonPrimary title={ButtonTitle.Cancel} htmlType={'button'} onClick={handlePopupClose} />
        <ButtonPrimary title={ButtonTitle.Edit} htmlType={'submit'} />
      </ButtonsWrapper>
    </Form>
  )
}

export default FormEditToleranceControl
