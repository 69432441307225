import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TExpressSection } from '@/store/reducers/charts/express-section.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import { useEffect, useRef, useState } from 'react'
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart'

const useExpressSections = (chartData: IChartSpectrum[]) => {
  const { setExpressSection } = useActions()
  const { sections } = useTypedSelector((state) => state.expressSectionReducer)
  const [sectionOptions, setSectionOptions] = useState<TExpressSection[]>(sections)
  const refIndexSection = useRef(0)

  const [isAreaDrag, setIsAreaDrag] = useState(false)
  const [isResizeRight, setIsResizeRight] = useState(false)
  const [isResizeLeft, setIsResizeLeft] = useState(false)

  const resetDefaultSettings = () => {
    if (isAreaDrag || isResizeLeft || isResizeRight) {
      setExpressSection({
        section: sectionOptions[refIndexSection.current],
        index: refIndexSection.current
      })
      setIsAreaDrag(false)
      setIsResizeLeft(false)
      setIsResizeRight(false)
    }
  }

  const onMouseMove = (e: CategoricalChartState) => {
    if (!(isAreaDrag || isResizeRight || isResizeLeft)) {
      return
    }

    if (e?.activePayload === undefined) {
      return undefined
    }

    const targetFreq = e.activePayload[0].payload.frequency // Получаем частоту события
    const options = [...sectionOptions]
    const { fl, fh } = options[refIndexSection.current] // Деструктурируем границы текущей секции
    const chartMaxFrequency = chartData[chartData.length - 1].frequency // Максимальная частота на графике
    let newFl = fl
    let newFh = fh

    // Перемещение области
    if (isAreaDrag) {
      const width = Math.abs(fh - fl) // Ширина выделенной области
      let start = targetFreq - width / 2
      let end = start + width
      // Ограничиваем значения в пределах допустимого диапазона
      if (start < 0) {
        start = 0
        end = width
      }
      if (end > chartMaxFrequency) {
        end = chartMaxFrequency
        start = end - width
      }

      newFl = start
      newFh = end
    }
    // Изменение правого края области
    else if (isResizeRight) {
      newFh = targetFreq
    }
    // Изменение левого края области
    else if (isResizeLeft) {
      newFl = targetFreq
    }

    if (newFl > newFh) {
      ;[newFl, newFh] = [newFh, newFl]
    }

    options[refIndexSection.current] = {
      ...options[refIndexSection.current],
      fl: newFl,
      fh: newFh
    }

    setSectionOptions(options)
  }

  const onMouseUp = () => {
    resetDefaultSettings()
  }

  const onMouseLeave = () => {
    resetDefaultSettings()
  }

  useEffect(() => {
    setSectionOptions(sections)
  }, [sections])

  return {
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    setIsAreaDrag,
    setIsResizeLeft,
    setIsResizeRight,
    sectionOptions,
    refIndexSection
  }
}

export default useExpressSections
