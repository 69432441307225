import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useLazyGetMachineQuery } from '@/store/api/machines.api'
import { useLazyGetMeasurementsQuery } from '@/store/api/measurements.api'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import { useChangePlanMutation } from '@/store/api/plans.api'
import { useLazyGetMeasuringPointQuery, useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Tree } from 'antd'
import type { DataNode } from 'antd/es/tree'
import type { CheckInfo } from 'rc-tree/lib/Tree'
import type { Key } from 'react'
import { useEffect } from 'react'

import styles from './plans-list.module.css'

import generatePlansTreeData from './helpers/generate-plans-tree-data'

export const enum ENodeType {
  MACHINE = 'machine',
  POINT = 'point',
  MEASUREMENT = 'measurement'
}

export type TDataNode = DataNode & {
  machineId: string
  pointId?: string
  measurementId?: string
  nodeType: ENodeType
}

type TExcludedIds = {
  excludedMachineIds: string[]
  excludedMeasurementIds: string[]
  excludedMeasuringPointIds: string[]
}

const PlansList = () => {
  const { addPoints, addMeasurements, excludeEquipment } = useActions()
  const { plans, selectedPlanId } = useTypedSelector((state) => state.planReducer)

  const { data: dataAllNodesState, refetch: refetchGetAllNodesQuery } = useGetAllNodesQuery()

  const [changePlan] = useChangePlanMutation()

  const [getCurrentMachine] = useLazyGetMachineQuery()
  const [getCurrentPoint] = useLazyGetMeasuringPointQuery()
  const [getPoints] = useLazyGetMeasuringPointsQuery()
  const [getMeasurements] = useLazyGetMeasurementsQuery()

  const selectedPlan = plans.find((plan) => plan.planId === selectedPlanId)

  const loadItemsInSelectedPlan = async (node: TDataNode) => {
    if (selectedPlan !== undefined) {
      try {
        if (node.nodeType === ENodeType.MACHINE) {
          const nodeId = node.machineId
          const { content: points } = await getPoints(nodeId).unwrap()

          const machine = await getCurrentMachine(nodeId).unwrap()

          if (!points) throw new Error('error selectMachineInPlan')

          addPoints({
            machine,
            points: points
          })
        } else if (node.nodeType === ENodeType.POINT) {
          const machineId = node.machineId
          const pointId = node.pointId
          const { content: measurements } = await getMeasurements(pointId).unwrap()

          const machine = await getCurrentMachine(machineId).unwrap()
          const point = await getCurrentPoint(pointId).unwrap()

          if (!measurements) throw new Error('error togglePointInPlan')

          addMeasurements({
            selectedPlan: selectedPlan,
            machine,
            point,
            measurements: measurements
          })
        }
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const onCheck = async (_: { checked: Key[]; halfChecked: Key[] } | Key[], checkedInfo: CheckInfo<TDataNode>) => {
    try {
      // собирает все ключи исключенных id машин точек или видов измерения
      const excludedIds: TExcludedIds = checkedInfo.checkedNodes.reduce<TExcludedIds>(
        (acc, curr) => {
          if (curr.nodeType === ENodeType.MACHINE) {
            acc.excludedMachineIds.push(curr.machineId)
          } else if (curr.nodeType === ENodeType.POINT && curr.pointId) {
            acc.excludedMeasuringPointIds.push(curr.pointId)
          } else if (curr.nodeType === ENodeType.MEASUREMENT && curr.measurementId) {
            acc.excludedMeasurementIds.push(curr.measurementId)
          }

          return acc
        },
        {
          excludedMachineIds: [],
          excludedMeasurementIds: [],
          excludedMeasuringPointIds: []
        }
      )

      const plan = { ...selectedPlan, ...excludedIds }

      await changePlan({
        planId: plan.planId,
        name: plan.name,
        planType: plan.planType,
        deviceType: plan.deviceType,
        machineIds: plan.machineIds,
        excludedMachineIds: plan.excludedMachineIds,
        excludedMeasurementIds: plan.excludedMeasurementIds,
        excludedMeasuringPointIds: plan.excludedMeasuringPointIds
      }).unwrap()

      successNotificationCreate('Маршрут изменен')

      excludeEquipment({
        plan
      })
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  useEffect(() => {
    const intervalRefetch = setInterval(refetchGetAllNodesQuery, 3000)

    return () => {
      clearInterval(intervalRefetch)
    }
  }, [refetchGetAllNodesQuery])

  return (
    <Tree
      checkable
      showLine={true}
      showIcon
      className={styles['plans-list']}
      loadData={loadItemsInSelectedPlan}
      treeData={generatePlansTreeData({ selectedPlan, dataAllNodesState })}
      onCheck={onCheck}
    />
  )
}

export default PlansList
