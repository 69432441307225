import { SliceName } from '@/constants/store/slices.constant'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import type { IMnemonicInitialState } from '@/types/mnemonic/mnemonic-initial-state.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const mnemonicInitialState: IMnemonicInitialState = {
  mnemoSchemeItem: undefined,
  selectedMnemoItemId: undefined
}

export const mnemonicSlice = createSlice({
  name: SliceName.Mnemonic,
  initialState: mnemonicInitialState,
  reducers: {
    setMnemoSchemeItem(state, action: PayloadAction<TMnemoSchemeItem>) {
      state.mnemoSchemeItem = action.payload
    },
    setSelectedMnemoItemId(state, action: PayloadAction<string | undefined>) {
      state.selectedMnemoItemId = action.payload
    }
  }
})
