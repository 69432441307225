import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TStrobeOptions } from '@/types/express-strobe.type'
import classNames from 'classnames'
import type { FC, LegacyRef } from 'react'

import styles from '../../../../express-sidebar.module.css'

import StrobeListItem from '../strobe-list-item/strobe-list-item'

interface IProps {
  results: {
    skz: number
    pk: number
    pf: number
    ex: number
    as: number
  }[]
  refStrobeListItem: LegacyRef<HTMLLIElement> | null
  title: string
  onClickExpressStrobeButton: () => void
  strobeData: TStrobeOptions | undefined
  currentIndex: number
}

const StrobeResultItem: FC<IProps> = ({
  results,
  refStrobeListItem,
  title,
  onClickExpressStrobeButton,
  strobeData,
  currentIndex
}) => {
  const { selectedResultIndex } = useTypedSelector((state) => state.chartsUiReducer)

  return (
    <li ref={refStrobeListItem} className={styles['list-item']}>
      <button onClick={onClickExpressStrobeButton} type={'button'} className={styles.button}>
        <h3
          className={classNames(styles['list-header'], styles['list-header-bg'], {
            [styles['list-header-active']]: selectedResultIndex === currentIndex
          })}
        >
          {title}
        </h3>

        <ul className={styles.list}>
          {results.map((result, i) => (
            <StrobeListItem
              key={i}
              title={`${i + 1}.`}
              strobeData={i === 0 ? strobeData : undefined}
              skz={result.skz}
              pk={result.pk}
              pf={result.pf}
              ex={result.ex}
              as={result.as}
            />
          ))}
        </ul>
      </button>
    </li>
  )
}

export default StrobeResultItem
