import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import FormSelect from '@/components/UI/form-controls/form-select/form-select'
import {
  GeneratorKey,
  STEP_COUNT_FREQ,
  STEP_COUNT_ROUND,
  distrAmplitudes,
  phases,
  typeSetModule
} from '@/constants/core/common.constant'
import { GeneratorLabel } from '@/constants/device/device-options.constant'
import useFade from '@/hooks/use-fade'
import type { FC } from 'react'
import React from 'react'

import styles from '@/app/configuration/components/devices/popup-generator/form-items-harmonic-signal/form-items-harmonic-signal.module.css'

const FormItemsModuleOne: FC = () => {
  const { ref, style } = useFade()
  return (
    <div ref={ref} style={style} className={styles.columns}>
      <div>
        {' '}
        <FormSelect
          labelContent={GeneratorLabel.M1_Type}
          name={GeneratorKey.M1_Type}
          options={typeSetModule}
          placeholder={'Выберите амплитуду распределения'}
          initialValue={0}
          autoFocus={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M1_DeepA}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M1_DeepA}
          initialValue={1}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M1_Nharm}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M1_Nharm}
          initialValue={1}
          required={true}
        />
        <FormSelect
          labelContent={GeneratorLabel.M1_PhaseSet}
          name={GeneratorKey.M1_PhaseSet}
          options={phases}
          placeholder={'Выберите фазовое распределение'}
          initialValue={0}
        />
      </div>
      <div>
        <FormInputNumber
          labelContent={GeneratorLabel.M1_BaseFreq}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M1_BaseFreq}
          initialValue={1}
          required={true}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M1_DeepT}
          step={STEP_COUNT_FREQ}
          name={GeneratorKey.M1_DeepT}
          required={true}
        />
        <FormSelect
          labelContent={GeneratorLabel.M1_AmpSet}
          name={GeneratorKey.M1_AmpSet}
          options={distrAmplitudes}
          placeholder={'Выберите закон изменения ряда'}
          initialValue={0}
        />
        <FormInputNumber
          labelContent={GeneratorLabel.M1_PhaseShift}
          step={STEP_COUNT_ROUND}
          name={GeneratorKey.M1_PhaseShift}
          min={-180}
          max={180}
          required={true}
        />
      </div>
    </div>
  )
}

export default FormItemsModuleOne
