export const enum ELtr24SamplingRate {
  LTR24_FREQ_117K = 'LTR24_FREQ_117K',
  LTR24_FREQ_58K = 'LTR24_FREQ_58K',
  LTR24_FREQ_29K = 'LTR24_FREQ_29K',
  LTR24_FREQ_14K = 'LTR24_FREQ_14K',
  LTR24_FREQ_7K3 = 'LTR24_FREQ_7K3',
  LTR24_FREQ_3K6 = 'LTR24_FREQ_3K6',
  LTR24_FREQ_1K8 = 'LTR24_FREQ_1K8',
  LTR24_FREQ_915 = 'LTR24_FREQ_915'
}
