export enum EKinematicBlockType {
  BR = 'BR',
  BF = 'BF',
  BS = 'BS',
  AD = 'AD',
  SD = 'SD',
  SG = 'SG',
  DCM = 'DCM',
  DCG = 'DCG',
  GB = 'GB',
  GBD = 'GBD',
  GBT = 'GBT',
  TGB = 'TGB',
  TW = 'TW',
  CW = 'CW',
  BD = 'BD',
  CD = 'CD',
  TN = 'TN',
  CN = 'CN',
  CP = 'CP',
  SH = 'SH',
  HD = 'HD',
  PD = 'PD'
}

export enum FreqRegul {
  None = 'NONE',
  PhaseRotor = 'PHASE_ROTOR',
  PWM = 'PWM'
}

export interface IOut {
  freqOutName: string | null
  freqOutCoefKb: number | null
  freqOutCoefMachine: number | null
  freqOutValue: number | null
}

export interface IIn {
  freqInName: string | null
  freqCoefForIn: number | null
  kinematicBlockSourseId: string | null
  freqOutCoefIndexSourse?: number | null
  freqOutNameSourse: string | null
  freqOutCoefMachineSourse: number | null
  measurementSourseId: string | null
  freqInValue: number | null
  freqInCoefMachine: number | null
}

export interface IKinematicElement {
  kinematicBlockId: string
  kinematicSchemeId: string
  kinematicBlockName: string
  kinematicBlockType: EKinematicBlockType
  description?: string
  imageSourceKS?: string
  imageSourceCW?: string
  width: number
  height: number
  x: number
  y: number
  measurementPointIds?: string[]
  ins?: IIn[]
  outs?: IOut[]
  kinematicBlockBR?: {
    n: number | null
    alfa: number | null
    bearingName: string
    manufacturerName: string
    kftfo: number | null
    kbsf: number | null
    kbpfo: number | null
    kbpfi: number | null
    din: number | null
    dout: number | null
    dtk: number | null
    kftf: number | null
  }
  kinematicBlockAD?: {
    n2p: number | null
    freqRegul: FreqRegul | null
    pwmData?: boolean | null
    fu: number | null
    nr: number | null
    ns: number | null
    fsh?: number | null
    fumin?: number | null
    fumax?: number | null
  }
  kinematicBlockSD?: {
    n2p: number | null
    fu: number | null
    nr: number | null
    ns: number | null
  }
  kinematicBlockSG?: {
    n2p: number
    fu: number
    nr: number
    ns: number
  }
  kinematicBlockDCM?: {
    n2p: number | null
    nc: number | null
    fu: number | null
    nr: number | null
  }
  kinematicBlockDCG?: {
    n2p: number
    nc: number
    fu: number
    nr: number
  }
  kinematicBlockGB?: {
    z1: number
    z2: number
  }
  kinematicBlockGBD?: {
    z1: number
    z2: number
    z3: number
  }
  kinematicBlockGBT?: {
    z1: number
    z2: number
    z3: number
  }
  kinematicBlockTGB?: {
    z1: number
    z2: null
    z3: number
  }
  kinematicBlockTW?: {
    zb: number | null
  }
  kinematicBlockCW?: {
    zb: number | null
  }
  kinematicBlockBD?: {
    d1: number | null
    d2: number | null
    l: number | null
  }
  kinematicBlockCD?: {
    z1: number | null
    z2: number | null
    z3: number | null
  }
  kinematicBlockTN?: {
    nst: number
    zbi: number
  }
  kinematicBlockCN?: {
    nst: number
    zbi: number
  }
}

export type TCreateKinematicElementBody = Omit<IKinematicElement, 'kinematicSchemeId' | 'kinematicBlockId'>

export interface IKinematicScheme {
  kinematicSchemeId?: string
  nodeId: string
  name: string
  width: number
  height: number
}

export interface ICreateKinematicSchemeResponse {
  id: string
}

export interface IGetKinematicSchemeElementsResponse {
  content: IKinematicElement[]
  totalElements: number
}

export interface IDefinitionRemoveCurrentKinematicElement {
  kinematicSchemeId: string
  kinematicElementId: string
}
