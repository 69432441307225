import { Vp3701ModulesName } from '@/constants/device/vp3701/vp3701-modules-name.constant'
import { EVp3701ChannelType } from '@/enums/connection/vp3701-channel-type.enum'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'

export const vp3701Modules = [
  {
    name: Vp3701ModulesName.KX134,
    physicalQuantityType: EPhysicalQuantityType.VIBRO_ACCELERATION,
    channels: [
      {
        name: 'Виброускорение по оси X',
        vp3701ChannelType: EVp3701ChannelType.VibroAccelerationX,
        unitType: EUnitType.G,
        physicalQuantityType: EPhysicalQuantityType.VIBRO_ACCELERATION
      },
      {
        name: 'Виброускорение по оси Y',
        vp3701ChannelType: EVp3701ChannelType.VibroAccelerationY,
        unitType: EUnitType.G,
        physicalQuantityType: EPhysicalQuantityType.VIBRO_ACCELERATION
      },
      {
        name: 'Виброускорение по оси Z',
        vp3701ChannelType: EVp3701ChannelType.VibroAccelerationZ,
        unitType: EUnitType.G,
        physicalQuantityType: EPhysicalQuantityType.VIBRO_ACCELERATION
      }
    ]
  },
  {
    name: Vp3701ModulesName.MLX90,
    physicalQuantityType: EPhysicalQuantityType.TEMPERATURE,
    channels: [
      {
        name: 'Датчик',
        vp3701ChannelType: EVp3701ChannelType.InternalSensorTemperature,
        unitType: EUnitType.DEGREES_CELSIUS,
        physicalQuantityType: EPhysicalQuantityType.TEMPERATURE
      },
      {
        name: 'Объект',
        vp3701ChannelType: EVp3701ChannelType.ObjectTemperature,
        unitType: EUnitType.DEGREES_CELSIUS,
        physicalQuantityType: EPhysicalQuantityType.TEMPERATURE
      }
    ]
  }
] as const
