import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import Popup from '@/components/UI/elements/popup/popup'
import FormInputNumber from '@/components/UI/form-controls/form-input-number/form-input-number'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react'

interface IExpressMarkerPopup {
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
  isPopupOpen: boolean
}

const ExpressMarkerPopup: FC<IExpressMarkerPopup> = ({ setIsPopupOpen, isPopupOpen }) => {
  const [form] = useForm()
  const { markers } = useTypedSelector((state) => state.expressMarkersReducer)
  const { setInitialExpressMarkerOptions } = useActions()

  const handleOnFinish = (values: { markerFreqOne: number; markerFreqTwo: number; markerFreqThree: number }) => {
    console.log(values)
    setInitialExpressMarkerOptions([
      { freq: values.markerFreqOne, index: null },
      { freq: values.markerFreqTwo, index: null },
      { freq: values.markerFreqThree, index: null }
    ])

    setIsPopupOpen(false)
  }

  const handleOnReset = () => {
    setIsPopupOpen(false)
  }

  useEffect(() => {
    if (isPopupOpen) {
      form.setFieldsValue({
        markerFreqOne: markers[0]?.freq,
        markerFreqTwo: markers[1]?.freq,
        markerFreqThree: markers[2]?.freq
      })
    }
  }, [form, markers, isPopupOpen])

  return (
    <Popup onCancel={() => setIsPopupOpen(false)} title={'Управление маркерами'} isOpen={isPopupOpen}>
      <Form form={form} onFinish={handleOnFinish} onReset={handleOnReset}>
        <FormInputNumber labelContent={'Частота 1 маркера'} minValue={0} name={'markerFreqOne'} required />
        <FormInputNumber labelContent={'Частота 2 маркера'} minValue={0} name={'markerFreqTwo'} required />
        <FormInputNumber labelContent={'Частота 3 маркера'} minValue={0} name={'markerFreqThree'} required />

        <ButtonsWrapper>
          <ButtonPrimary htmlType={'reset'} title={'Отмена'} />
          <ButtonPrimary htmlType={'submit'} title={'Сохранить'} />
        </ButtonsWrapper>
      </Form>
    </Popup>
  )
}
export default ExpressMarkerPopup
