import type { DataNode } from 'antd/es/tree'
import type { Key } from 'react'

// Интерфейс IFoundNode с обобщением
interface IFoundNode<T> {
  node: T
  path: Key[]
}

// Основная функция поиска узла
export const findNode = <T extends DataNode>(treeData: T[], key: Key, path: Key[] = []): IFoundNode<T> | undefined => {
  for (const node of treeData) {
    const currentPath = [...path]

    if (node.key === key) {
      return { node, path: currentPath }
    }

    if (node.children) {
      const childNode = findNode<T>(node.children as T[], key, [...currentPath, node.key])
      if (childNode) {
        return childNode
      }
    }
  }

  return undefined
}
