export const enum ESamplingRate {
  RATE_15_25600 = 'RATE_15_25600',
  RATE_14_12800 = 'RATE_14_12800',
  RATE_13_6400 = 'RATE_13_6400',
  RATE_12_3200 = 'RATE_12_3200',
  RATE_11_1600 = 'RATE_11_1600',
  RATE_10_800 = 'RATE_10_800',
  RATE_09_400 = 'RATE_09_400',
  RATE_08_200 = 'RATE_08_200',
  RATE_07_100 = 'RATE_07_100',
  RATE_06_50 = 'RATE_06_50',
  RATE_05_25 = 'RATE_05_25',
  RATE_04_12 = 'RATE_04_12',
  RATE_04_6_25 = 'RATE_04_6_25',
  RATE_02_3_125 = 'RATE_02_3_125',
  RATE_01_1_563 = 'RATE_01_1_563',
  RATE_00_0_781 = 'RATE_00_0_781'
}
