import { ErrorData } from '@/constants/errors.constant'
import { readableGenerator } from '@/constants/reports.constant'
import type { IVp3701 } from '@/types/device/vp3701/vp3701.interface'
import type { IDeviceVP3701, IGenerator } from '@/types/devices.type'
import { ROBOTO_BOLD, ROBOTO_REGULAR } from '@/types/reports/font-pdf'
import type { TGeneratorView } from '@/types/reports/reports.type'
import { capitalizeFirstLetter } from '@/utils/common'
import type { jsPDF } from 'jspdf'
import type { RowInput } from 'jspdf-autotable'
import autoTable from 'jspdf-autotable'

/**
 * Устанавливает настройки для PDF документа.
 * @param document - объект из библеотеки jsPDF для генерации PDF-документа.
 */
export const setSettingsPDF = (document: jsPDF) => {
  document.addFileToVFS('Roboto-Roboto-Regular-normal.ttf', ROBOTO_REGULAR)
  document.addFont('Roboto-Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal')
  document.setFont('Roboto-Regular')
  document.addFileToVFS('Roboto-Roboto-Regular-bold.ttf', ROBOTO_BOLD)
  document.addFont('Roboto-Roboto-Regular-bold.ttf', 'Roboto-Bold', 'bold')
}

/**
 * Генерация таблицы.
 * @param document - объект из библеотеки jsPDF для генерации PDF-документ.
 * @param head - строка с данными для шапки таблицы.
 * @param body - строка с данными для тела таблицы..
 */
export const generationTable = (document: jsPDF, head: RowInput[], body: RowInput[]) => {
  autoTable(document, {
    head,
    body,
    theme: 'grid',
    showHead: 'firstPage',
    headStyles: {
      fillColor: [102, 102, 102],
      textColor: [255, 255, 255],
      font: 'Roboto-Bold',
      lineColor: [224, 224, 224],
      lineWidth: 0.1
    },
    styles: {
      font: 'Roboto-Regular',
      fontSize: 12,
      textColor: [0, 0, 0],
      lineColor: [224, 224, 224],
      lineWidth: 0.1
    },
    margin: {
      top: 25
    }
  })
}

export const adaptableGeneratorForPDF = (dataItem: TGeneratorView) => {
  const result = []

  for (const key in dataItem) {
    const value = dataItem[key as keyof TGeneratorView]

    result.push([readableGenerator[key as keyof TGeneratorView], value || ErrorData.Null])
  }

  return result
}

export const adaptableDataGeneratorForView = (dataItem: TGeneratorView) => {
  const result = []

  for (const key in dataItem) {
    const value = dataItem[key as keyof TGeneratorView]

    result.push({
      key: key,
      name: readableGenerator[key as keyof TGeneratorView],
      value: String(value) || ErrorData.Null
    })
  }
  return result
}

export const removeNesting = <T>(object: T): TGeneratorView => {
  let result = {}

  const recursiveFunction = <U>(obj: U, prevKey = '') => {
    for (const key in obj) {
      const value = obj[key]

      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (typeof item === 'object') {
              for (const keyItem in item) {
                result = {
                  ...result,
                  [`${key}${capitalizeFirstLetter(keyItem)}`]: item[keyItem]
                }
              }
            }
          })
        } else {
          recursiveFunction(value, key)
        }
      } else {
        const viewKey = prevKey ? `${prevKey}${capitalizeFirstLetter(key)}` : key
        result = { ...result, [viewKey]: value }
      }
    }
  }

  recursiveFunction<T>(object)
  return result
}

export const findVp3701Element = (vp3701data?: IVp3701[], id: string): IDeviceVP3701 | undefined =>
  vp3701data?.find((element) => element?.vp3701Id === id)

export const findGenerator = (generatorData: IGenerator[], id: IGenerator['generatorId']): IGenerator | undefined =>
  generatorData.find((element) => element.generatorId === id)
