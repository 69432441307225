import type { ButtonProps } from 'antd'
import { Button, Skeleton } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './button-primary.module.css'

type ButtonPrimary = {
  title: string
  theme?: 'light' | 'dark'
  htmlType: 'reset' | 'submit' | 'button'
  isLoading?: boolean
  isLoadingSkeleton?: boolean
  className?: string
}

type TProps = ButtonPrimary & ButtonProps

const ButtonPrimary: FC<TProps> = ({
  title,
  htmlType,
  isLoading,
  isLoadingSkeleton,
  disabled,
  theme = 'light',
  className,
  ...props
}) =>
  !isLoadingSkeleton ? (
    <Button
      className={cn(styles.button, className, {
        [styles['button-light']]: theme === 'light',
        [styles['button-dark']]: theme === 'dark'
      })}
      htmlType={htmlType}
      disabled={disabled}
      loading={isLoading}
      {...props}
    >
      {title}
    </Button>
  ) : (
    <Skeleton.Button active={isLoadingSkeleton} className={styles['skeleton-button']} />
  )

export default ButtonPrimary
