import { SliceName } from '@/constants/store/slices.constant'
import { ThresholdName, initializedRiftZoneData } from '@/constants/threshold/threshold.constant'
import { EFormMode } from '@/enums/threshold/form-mode.enum'
import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import type { IRift, IThresholdStore, IZone, TThresholdData } from '@/types/threshold/threshold.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IThresholdStore = {
  rifts: initializedRiftZoneData[ThresholdName.Rifts],
  zones: initializedRiftZoneData[ThresholdName.Zones],
  riftCalcType: ERiftCalcType.INCREMENT,
  riftCalcEnabled: false,
  selectedRift: 0,
  isOpenThresholdPopup: false,
  formMode: EFormMode.ADD,
  baseRiftValue: 0
}

export const thresholdSlice = createSlice({
  name: SliceName.Threshold,
  initialState,
  reducers: {
    setRifts(state, action: PayloadAction<IRift[]>) {
      state.rifts = action.payload
    },
    setZones(state, action: PayloadAction<IZone[]>) {
      state.zones = action.payload
    },
    setRiftCalcType(state, action: PayloadAction<ERiftCalcType>) {
      state.riftCalcType = action.payload
    },
    setRiftCalcEnabled(state, action: PayloadAction<boolean>) {
      state.riftCalcEnabled = action.payload
    },
    setSelectedRift(state, action: PayloadAction<number>) {
      state.selectedRift = action.payload
    },
    setIsOpenThresholdPopup(state, action: PayloadAction<boolean>) {
      state.isOpenThresholdPopup = action.payload
    },
    setFormMode(state, action: PayloadAction<EFormMode>) {
      state.formMode = action.payload
    },
    setBaseRiftValue(state, action: PayloadAction<number>) {
      state.baseRiftValue = action.payload
    },
    setThresholdData(state, action: PayloadAction<TThresholdData>) {
      const { riftCalcEnabled, rifts, riftCalcType, baseRiftValue, zones } = action.payload
      state.riftCalcType = riftCalcType
      state.riftCalcEnabled = riftCalcEnabled
      state.baseRiftValue = baseRiftValue
      state.rifts = rifts
      state.zones = zones
    }
  }
})
