import CommonLevelForm from '@/app/configuration/components/equipments/components/measurements/components/common-level-form/common-level-form'
import SignalForm from '@/app/configuration/components/equipments/components/measurements/components/signal-form/signal-form'
import SpecterForm from '@/app/configuration/components/equipments/components/measurements/components/specter-form/specter-form'
import TemperatureForm from '@/app/configuration/components/equipments/components/measurements/components/temperature-form/temperature-form'
import type {
  TMeasurementForm,
  TMeasurementStatic
} from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import {
  generateMeasurementName,
  getMeasurementTypeOptions,
  mapMeasurementToForm,
  mapMeasurementToStatic,
  mapToRequestMeasurement
} from '@/app/configuration/components/equipments/components/measurements/measurement.service'
import { getTypePoint } from '@/app/configuration/components/equipments/components/point/point.service'
import { GroupTree } from '@/app/configuration/components/menu-tree/menu-tree.constant'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import measurementIcon from '@/components/icons/equipments/measurement-icon'
import { ButtonTitle } from '@/constants/core/common.constant'
import { mapDeviceType, targetTypeOptions } from '@/constants/device/device-options.constant'
import { mapMeasurementType } from '@/constants/measurement/measurements.constant'
import { mapUnitType } from '@/constants/measurement/unit.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { ETargetType } from '@/enums/connection/target-type.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import useActions from '@/hooks/use-actions'
import {
  useCreateMeasurementMutation,
  useGetMeasurementQuery,
  useGetMeasurementsQuery,
  useUpdateMeasurementsStatusMutation
} from '@/store/api/measurements.api'
import { useGetMeasuringPointQuery } from '@/store/api/points.api'
import { getUnitTypeOptions } from '@/utils/measurement/get-filter-unit.unit'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form, Select, Switch } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/app/configuration/components/equipments/components/measurements/measurement.module.css'

interface IProps {
  measurementId?: string
  pointId?: string
  machineId?: string
  isAddMode: boolean
  isEditMode: boolean
}

const Measurement: FC<IProps> = ({ measurementId, pointId, isAddMode, isEditMode }) => {
  const { setSelectedMeasurementId, setEquipmentView, updateTreeMenuItem, setSelectedTreeItems } = useActions()
  const [measurementForm] = useForm<TMeasurementForm>()
  const [staticData, setStaticData] = useState<TMeasurementStatic | undefined>(undefined)
  const { data: point } = useGetMeasuringPointQuery(pointId, {
    skip: !pointId
  })
  const { data: measurement, isLoading: isLoadingMeasurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId
  })
  const { data: measurements } = useGetMeasurementsQuery(pointId, {
    skip: !pointId
  })
  const [createMeasurement, { isLoading: isLoadingCreateMeasurement }] = useCreateMeasurementMutation()
  const [updateMeasurementsStatus, { isLoading: isLoadingUpdateMeasurementStatus }] =
    useUpdateMeasurementsStatusMutation()

  const measurementTypeWatched = useWatch('measurementType', measurementForm)
  const targetTypeWatched = useWatch('targetType', measurementForm)
  const typePoint = getTypePoint(point)
  const unitOptions = getUnitTypeOptions(point?.physicalQuantityType)
  const measurementTypeOptions = getMeasurementTypeOptions(point)

  const measurementType = measurementTypeWatched || staticData?.measurementType
  const targetType = targetTypeWatched || staticData?.targetType

  const isSignalDescription = measurementType === ETypeMeasurement.SignalDescription
  const isProgramSpecterDescription = measurementType === ETypeMeasurement.ProgramSpecterDescription
  const isCommonLevelDescription = measurementType === ETypeMeasurement.CommonLevelDescription
  const isTemperatureDescription = measurementType === ETypeMeasurement.TemperatureDescription
  const isLtr24 = targetType === ETargetType.LTR24

  const filteredTypeTargetOptions = point
    ? targetTypeOptions.filter((option) => option.availableFor.includes(point.physicalQuantityType))
    : undefined

  useEffect(() => {
    setStaticData(undefined)
  }, [])

  useEffect(() => {
    if (measurement && point && isEditMode) {
      const staticDataFromResponse = mapMeasurementToStatic(measurement)
      const formData = mapMeasurementToForm(measurement)
      setStaticData(staticDataFromResponse)
      measurementForm.setFieldsValue(formData)
    }
  }, [isEditMode, measurement, measurementForm, point])

  useEffect(() => {
    if (isAddMode) {
      measurementForm.resetFields()
    }
  }, [isAddMode, measurementForm])

  const handleMeasurementFinish = async () => {
    const { getFieldsValue } = measurementForm
    const measurementFormValues = getFieldsValue()
    try {
      if (isAddMode && pointId) {
        const measurementName = generateMeasurementName(measurementFormValues, measurements?.content)
        const measurementForRequest = mapToRequestMeasurement(measurementFormValues, measurementName, pointId)

        if (measurementForRequest && measurementName) {
          const { id } = await createMeasurement(measurementForRequest).unwrap()
          setEquipmentView({
            mode: EViewMode.EDITING,
            content: EEquipmentContent.MEASUREMENT
          })
          setSelectedMeasurementId(id)
          updateTreeMenuItem({
            id: id,
            name: measurementName,
            parentKey: pointId,
            group: GroupTree.Measurement,
            icon: measurementIcon
          })
          setSelectedTreeItems([id])
          successNotificationCreate('Вид измерения успешно добавлен')
        }
      }
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const handleChangePaused = async () => {
    if (measurementId && isEditMode) {
      const isActivated = measurementForm.getFieldValue('activated')
      try {
        await updateMeasurementsStatus({
          updateData: {
            paused: !isActivated
          },
          measurementId
        }).unwrap()

        if (isActivated) {
          successNotificationCreate('Вид измерения активирован')
        } else {
          successNotificationCreate('Вид измерения отключён')
        }
      } catch (err) {
        errorNotificationCreate(err)
      }
    }
  }

  const getTitle = () => {
    if (isAddMode) {
      return 'Добавление нового вида измерения'
    }

    return `Вид измерения ${measurement?.name}`
  }

  const resetMeasurementForm = () => {
    measurementForm.resetFields()
  }

  if (isLoadingMeasurement) {
    return <Spinner />
  }

  return (
    <>
      <HeaderConfiguration content={getTitle()} />
      <ContentWrapper>
        <Form
          form={measurementForm}
          className={styles.container}
          layout='horizontal'
          onFinish={handleMeasurementFinish}
        >
          <ControlsWrapper>
            {isEditMode && <TextStatic label='Наименование' value={staticData?.name} />}
            <TextStatic label='Вид контроля' value={typePoint} />

            {isAddMode && (
              <FormItemApp
                name='targetType'
                label='Тип устройства'
                rules={[{ required: true, message: 'Поле обязательно' }]}
              >
                <Select
                  options={filteredTypeTargetOptions}
                  placeholder='Выберите тип устройства'
                  // onSelect={handleTargetTypeSelect}
                />
              </FormItemApp>
            )}
            {isEditMode && staticData?.targetType && (
              <TextStatic label='Тип устройства' value={mapDeviceType[staticData?.targetType]} />
            )}
          </ControlsWrapper>

          <ControlsWrapper>
            {isAddMode && (
              <FormItemApp
                name='measurementType'
                label='Вид измерения'
                rules={[{ required: isAddMode, message: 'Выберите вид измерения' }]}
              >
                <Select options={measurementTypeOptions} placeholder='Выберите вид измерения' />
              </FormItemApp>
            )}

            {isEditMode && staticData?.measurementType && (
              <TextStatic label='Вид измерения' value={mapMeasurementType[staticData.measurementType]} />
            )}

            {isAddMode && (
              <FormItemApp
                name='unitType'
                label='Единица измерения'
                rules={[{ required: isAddMode, message: 'Поле обязательно' }]}
              >
                <Select placeholder='Выберите единицу измерения' options={unitOptions} />
              </FormItemApp>
            )}

            {isEditMode && staticData?.unitType && (
              <TextStatic label='Единица измерения' value={mapUnitType[staticData.unitType]} />
            )}

            {isEditMode && (
              <FormItemApp
                name='activated'
                label='Активно'
                rules={[{ required: isAddMode, message: 'Поле обязательно' }]}
              >
                <Switch onChange={handleChangePaused} loading={isLoadingUpdateMeasurementStatus} />
              </FormItemApp>
            )}
          </ControlsWrapper>
          {measurementType && (
            <ControlsWrapper>
              <h4 className={styles.title}>Параметры измерения</h4>
              {isSignalDescription && (
                <SignalForm isLtr24={isLtr24} isEditMode={isEditMode} isAddMode={isAddMode} staticData={staticData} />
              )}
              {isProgramSpecterDescription && (
                <SpecterForm isEditMode={isEditMode} isAddMode={isAddMode} staticData={staticData} />
              )}
              {isCommonLevelDescription && (
                <CommonLevelForm isEditMode={isEditMode} isAddMode={isAddMode} staticData={staticData} />
              )}
              {isTemperatureDescription && (
                <TemperatureForm
                  measurementForm={measurementForm}
                  isEditMode={isEditMode}
                  isAddMode={isAddMode}
                  staticData={staticData}
                />
              )}
            </ControlsWrapper>
          )}
          {isAddMode && (
            <ControlsWrapper>
              <ButtonsWrapper>
                <ButtonPrimary htmlType={'button'} title={ButtonTitle.Reset} onClick={resetMeasurementForm} />
                <ButtonPrimary
                  htmlType={'submit'}
                  title={ButtonTitle.Save}
                  disabled={isEditMode}
                  loading={isLoadingCreateMeasurement}
                />
              </ButtonsWrapper>
            </ControlsWrapper>
          )}
        </Form>
      </ContentWrapper>
    </>
  )
}
export default Measurement
