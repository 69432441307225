import type { IPointForm } from '@/app/configuration/components/equipments/components/point/point.interface'
import { PointName } from '@/constants/point/point-form.constant'
import { mapTypePoint } from '@/constants/point/point.constant'
import type { ICreatedPoint, IMeasuringPoint } from '@/types/point/point.type'

export const mapFromResponsePoint = (data: IMeasuringPoint): IPointForm => ({
  [PointName.ShortName]: data.name,
  [PointName.FullName]: data.fullName,
  [PointName.Comment]: data.comment,
  [PointName.PhysicalQuantityType]: data.physicalQuantityType,
  [PointName.isActivated]: !data.paused,
  [PointName.Place]: data.place
})

export const mapToRequestPoint = (formData: IPointForm, machineId: string): ICreatedPoint => ({
  name: formData[PointName.ShortName],
  fullName: formData[PointName.FullName],
  comment: formData[PointName.Comment],
  place: formData[PointName.Place],
  physicalQuantityType: formData[PointName.PhysicalQuantityType],
  machineId: machineId,
  paused: !formData.isActivated
})

export const getTypePoint = (point?: IMeasuringPoint) => {
  if (point) {
    return mapTypePoint[point.physicalQuantityType]
  }
  return undefined
}
