import { mapBaseVibrationUnits } from '@/constants/measurement/unit.constant'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'

const conversionRates: Record<string, Record<string, { multiplier: number; offset: number }>> = {
  // Ускорение
  MS2: {
    G: { multiplier: 1 / 9.81, offset: 0 },
    MMS2: { multiplier: 1000, offset: 0 }
  },
  G: { MS2: { multiplier: 9.81, offset: 0 }, MMS2: { multiplier: 9810, offset: 0 } },
  MMS2: { MS2: { multiplier: 1 / 1000, offset: 0 }, G: { multiplier: 1 / 9810, offset: 0 } },

  // Скорость
  MMS: { MS: { multiplier: 1 / 1000, offset: 0 } },
  MS: { MMS: { multiplier: 1000, offset: 0 } },

  // Перемещение
  MKM: { M: { multiplier: 1 / 1000000, offset: 0 }, MM: { multiplier: 1 / 1000, offset: 0 } },
  MM: { MKM: { multiplier: 1000, offset: 0 }, M: { multiplier: 1 / 1000, offset: 0 } },
  M: { MKM: { multiplier: 1000000, offset: 0 }, MM: { multiplier: 1000, offset: 0 } },

  // Сила тока
  A: { MA: { multiplier: 1000, offset: 0 }, MKA: { multiplier: 10 ** 6, offset: 0 } },
  MA: { A: { multiplier: 1 / 1000, offset: 0 }, MKA: { multiplier: 1000, offset: 0 } },
  MKA: { A: { multiplier: 1 / 10 ** 6, offset: 0 }, MA: { multiplier: 1 / 1000, offset: 0 } },

  // Напряжение
  VOLT: { M_VOLT: { multiplier: 1000, offset: 0 }, U_VOLT: { multiplier: 10 ** 6, offset: 0 } },
  M_VOLT: { VOLT: { multiplier: 1 / 1000, offset: 0 }, U_VOLT: { multiplier: 1000, offset: 0 } },
  U_VOLT: { VOLT: { multiplier: 1 / 10 ** 6, offset: 0 }, M_VOLT: { multiplier: 1 / 1000, offset: 0 } },

  // Температура
  DEGREES_CELSIUS: {
    DEGREES_FAHRENHEIT: { multiplier: 9 / 5, offset: 32 },
    DEGREES_KELVIN: { multiplier: 1, offset: 273.15 }
  },
  DEGREES_FAHRENHEIT: {
    DEGREES_CELSIUS: { multiplier: 5 / 9, offset: (-32 * 5) / 9 },
    DEGREES_KELVIN: { multiplier: 5 / 9, offset: (459.67 * 5) / 9 }
  },
  DEGREES_KELVIN: {
    DEGREES_CELSIUS: { multiplier: 1, offset: -273.15 },
    DEGREES_FAHRENHEIT: { multiplier: 9 / 5, offset: -459.67 }
  }
}

const conversionVibrationFunctions: Record<string, Record<string, (value: number, frequency: number) => number>> = {
  MS2: {
    MMS: (acceleration: number, frequency: number) => (acceleration * 500) / (frequency * Math.PI),
    MKM: (acceleration: number, frequency: number) => acceleration * Math.pow(500 / (frequency * Math.PI), 2)
  },
  MMS: {
    MS2: (velocity: number, frequency: number) => (velocity * (frequency * Math.PI)) / 500,
    MKM: (velocity: number, frequency: number) => (velocity * 500) / (frequency * Math.PI)
  },
  MKM: {
    MS2: (displacement: number, frequency: number) => displacement * Math.pow((frequency * Math.PI) / 500, 2),
    MMS: (displacement: number, frequency: number) => (displacement * (frequency * Math.PI)) / 500
  }
}

export const unitConverter = (value: number, from: EUnitType, to: EUnitType): number => {
  if (from === to) return value

  const conversion = conversionRates[from]?.[to]
  if (conversion === undefined) {
    throw new Error(`Conversion from ${from} to ${to} is not defined`)
  }

  return value * conversion.multiplier + conversion.offset
}

export const vibrationConverter = (value: number, frequency: number, from: EUnitType, to: EUnitType): number => {
  if (from === to) return value

  // Определяем базовые физ. величины для преобразования
  const baseVibrationUnitFrom = mapBaseVibrationUnits[from]
  const baseVibrationUnitTo = mapBaseVibrationUnits[to]

  // Если базовая физ. величина совпала
  if (baseVibrationUnitFrom === baseVibrationUnitTo) {
    return unitConverter(value, from, to)
  }

  // Конвертируем базовую физ. величину
  const baseValueFrom = unitConverter(value, from, baseVibrationUnitFrom)

  // Производим конвертацию в другую физ. величину
  const conversionVibration = conversionVibrationFunctions[baseVibrationUnitFrom][baseVibrationUnitTo]
  const baseValueTo = conversionVibration(baseValueFrom, frequency)

  // Если целевая единица совпадает с базовой, просто возвращаем результат
  if (baseVibrationUnitTo === to) {
    return baseValueTo
  }

  // Конвертируем результат обратно в целевую единицу
  return unitConverter(baseValueTo, baseVibrationUnitTo, to)
}
