export const DeviceTypeLabel = {
  Vp3701: 'Устройства VP-3701',
  Generator: 'Генераторы',
  Vp3711: 'Измерительные посты VP-3711'
} as const

export const DeviceTypeId = {
  Vp3701: 'vp-3701',
  Generator: 'generators',
  Vp3711: 'vp-3711'
} as const
