import ChartTooltip from '@/app/machine-condition/components/results/components/charts/components/tooltip-wrapper/chart-tooltip'
import { UNIT_GZ } from '@/constants/core/common.constant'
import type { FC } from 'react'
import type { TooltipProps } from 'recharts'
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'

type TooltipScalarProps = {
  isLog?: boolean
  fMax?: number
  dfValue: number
} & TooltipProps<ValueType, NameType>

const TooltipSpectrum: FC<TooltipScalarProps> = ({ active, payload }) => {
  if (active && payload) {
    const currentData = payload?.[0]?.payload

    return (
      <ChartTooltip>
        <span>A {currentData?.amplitude}</span>
        <span>
          {currentData?.frequency} {UNIT_GZ}
        </span>
      </ChartTooltip>
    )
  }

  return null
}

export default TooltipSpectrum
