import NavigationSettings from '@/app/settings/components/navigation-settings/navigation-settings'
import ModalServer from '@/app/settings/components/server-settings/components/modal-add-server/modal-server'
import ServersSelect from '@/app/settings/components/server-settings/components/servers-select/servers-select'
import type { IModalSettings } from '@/app/settings/components/server-settings/server-settings.type'
import { EModeModal } from '@/app/settings/components/server-settings/server-settings.type'
import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonToolbar from '@/components/UI/buttons/button-toolbar/button-toolbar'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import SwitchIndicator from '@/components/UI/elements/switch-indicator/switch-indicator'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import Container from '@/components/UI/layouts/container/container'
import LeftBar from '@/components/UI/layouts/left-bar/left-bar'
import ToolBar from '@/components/UI/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/UI/layouts/work-space/work-space'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import PanelTitle from '@/components/UI/panel/panel-title/panel-title'
import { SettingsTitle } from '@/constants/settings/settings.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useLazyGetInfoAnotherUrlQuery } from '@/store/api/info.api'
import { serverStorage } from '@/store/local-storage/server.storage'
import type { IServer } from '@/types/settings/server/server.interface'
import { Form, Tooltip } from 'antd'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './server-settings.module.css'

export const ServerSettings: FC = () => {
  const { setServer } = useActions()
  const { isDesktop, server } = useTypedSelector((state) => state.serverSettingsReducer)
  const [triggerGetInfo, { isSuccess }] = useLazyGetInfoAnotherUrlQuery()
  const [servers, setServers] = useState<IServer[]>([])
  const [selectedServer, setSelectedServer] = useState<IServer | null>(null)
  const [modalSettings, setModalSettings] = useState<IModalSettings>({ opened: false })
  const connectionStatus = (
    <SwitchIndicator sourceBoolean={isSuccess} trueContent={'Сервер онлайн'} falseContent={'Сервер оффлайн'} />
  )

  const isSelectedActiveServer = server.id === selectedServer?.id

  useEffect(() => {
    const activeServer = serverStorage.getActiveServer()
    setServers(serverStorage.getServers())
    setServer(activeServer)
    setSelectedServer(activeServer)
  }, [setServer])

  const handleSetActiveServerFinish = async () => {
    if (!selectedServer) {
      return
    }

    serverStorage.setActiveServer(selectedServer)
    setServer(selectedServer)
  }

  const handleServerSelected = async (selectedServerValue: IServer) => {
    setSelectedServer(selectedServerValue)
    try {
      await triggerGetInfo({ url: selectedServerValue.url }).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  const handleModalOpenedClick = (mode: EModeModal) => {
    setModalSettings({ opened: true, mode })
  }

  const handleCloseModal = () => {
    setModalSettings({ opened: false })
  }

  const handleServersAction = async (value: IServer) => {
    switch (modalSettings.mode) {
      case EModeModal.ADD:
        setServers((prev) => {
          const newServers = [...prev, value]
          serverStorage.setServers(newServers)
          return newServers
        })
        setSelectedServer(value)
        break
      case EModeModal.EDIT:
        setServers((prev) => {
          const index = prev.findIndex((item) => item.id === value.id)
          if (index === -1) {
            serverStorage.setServers(prev)
            return prev
          }

          const newServers = [...prev.slice(0, index), value, ...prev.slice(index + 1)]
          serverStorage.setServers(newServers)
          return newServers
        })
        setSelectedServer(value)
        break
      case EModeModal.DELETE:
        setServers((prev) => {
          const newServers = prev.filter((item) => item.id !== value.id)
          serverStorage.setServers(newServers)
          return newServers
        })
        setSelectedServer(server)
        break
    }
    setModalSettings({ opened: false })
    try {
      await triggerGetInfo({ url: value.url }).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Container>
      <ToolBar>
        {isDesktop && (
          <>
            <Tooltip title='Добавить сервер' placement='right'>
              <ButtonToolbar icon='add' onClick={() => handleModalOpenedClick(EModeModal.ADD)} />
            </Tooltip>
            <Tooltip title='Редактировать сервер' placement='right'>
              <ButtonToolbar icon='edit' onClick={() => handleModalOpenedClick(EModeModal.EDIT)} />
            </Tooltip>
            <Tooltip title='Удалить сервер' placement='right'>
              <ButtonToolbar
                icon='delete'
                onClick={() => handleModalOpenedClick(EModeModal.DELETE)}
                disabled={isSelectedActiveServer}
              />
            </Tooltip>
          </>
        )}
      </ToolBar>
      <LeftBar>
        <NavigationSettings />
      </LeftBar>
      <WorkSpace>
        <HeaderConfiguration content={SettingsTitle.Server} />
        {!isDesktop ? (
          <p style={{ margin: '20px 0', display: 'block', textAlign: 'center' }}>
            Настройки подключения к серверу доступны только в настольном приложении.
          </p>
        ) : (
          <ContentWrapper>
            <Form className={styles['container']} onFinish={handleSetActiveServerFinish}>
              <ControlsWrapper>
                <PanelTitle>Список серверов</PanelTitle>
                <ServersSelect
                  servers={servers}
                  selectedServer={selectedServer}
                  onSelect={handleServerSelected}
                  activatedServer={server}
                />
              </ControlsWrapper>
              <ControlsWrapper>
                <PanelTitle>Информация о сервере</PanelTitle>
                <TextStatic label='Адрес сервера' value={selectedServer?.url} />

                <TextStatic label='Имя сервера' value={selectedServer?.name} />
                <TextStatic label='Статус' value={connectionStatus} />
              </ControlsWrapper>
              <ControlsWrapper>
                <ButtonsWrapper>
                  <ButtonPrimary htmlType={'submit'} title='Установить подключение' />
                </ButtonsWrapper>
              </ControlsWrapper>
            </Form>
          </ContentWrapper>
        )}
      </WorkSpace>

      <ModalServer
        modalOpened={modalSettings}
        onClose={handleCloseModal}
        onUpdateServers={handleServersAction}
        servers={servers}
        selectedServer={selectedServer}
      />
    </Container>
  )
}

export default ServerSettings
