/* eslint-disable no-var */
import { SliceName } from '@/constants/store/slices.constant'
import { serverStorage } from '@/store/local-storage/server.storage'
import type { TSettings } from '@/types/settings.type'
import type { IServer } from '@/types/settings/server/server.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

declare global {
  var IN_DESKTOP_ENV: boolean
  var VERSION: string
}

const initialState: TSettings = {
  server: serverStorage.getActiveServer(),
  version: globalThis.VERSION || process.env?.REACT_APP_VERSION || '',
  isDesktop: globalThis.IN_DESKTOP_ENV
}

export const serverSettingsSlice = createSlice({
  name: SliceName.ServerSettings,
  initialState,
  reducers: {
    setServer(state, action: PayloadAction<IServer>) {
      state.server = action.payload
    }
  }
})
