import type { FC, ReactNode } from 'react'
import React from 'react'

import styles from '@/components/UI/elements/header-work-space/header-configuration.module.css'

type TitleProps = {
  content: ReactNode
}

const HeaderConfiguration: FC<TitleProps> = ({ content }) => (
  <div className={`${styles.container}`}>
    <h2 className={styles.title}>{content}</h2>
  </div>
)

export default HeaderConfiguration
