import MachineIcon from '@/components/icons/equipments/machine-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import { EConnectionContent } from '@/enums/connection/connection-content.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMachinesQuery } from '@/store/api/machines.api'
import { useGetMeasuringPointQuery, useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { TConnectionNode } from '@/types/connection/connection-node.type'
import type { IMachineInfo } from '@/types/machine.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import { updateTreeData } from '@/utils/tree/update-tree-data'
import type { GetRef } from 'antd'
import { Tree } from 'antd'
import type { EventDataNode } from 'antd/es/tree'
import type { FC, Key } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import styles from './connection-equipments-tree.module.css'

const mapPointsToTree = (points: ISelectedMeasuringPoint[], machineId: string): TConnectionNode[] =>
  points.map((point) => ({
    key: point.pointId,
    title: point.name,
    isLeaf: true,
    content: EConnectionContent.Points,
    icon: <PointIcon />,
    parentKey: machineId,
    point: point
  }))

const renderMachineTree = (machines: IMachineInfo[]): TConnectionNode[] =>
  machines.map((machine) => ({
    key: machine.machineId,
    title: machine.name,
    className: styles['tree-item'],
    content: EConnectionContent.Machines,
    icon: <MachineIcon />
  }))

type TreeRef = GetRef<typeof Tree>

const ConnectionEquipmentsTree: FC = () => {
  const { data: machinesResponse } = useGetMachinesQuery()
  const [expandedKeys, setExpendedKeys] = useState<Key[]>([])
  const [triggerGetPoints] = useLazyGetMeasuringPointsQuery()
  const { selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { setSelectedMachineId, setSelectedPointId } = useActions()
  const machines = machinesResponse?.content
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const { data: point } = useGetMeasuringPointQuery(selectedPointId, { skip: !selectedPointId })
  const [treeData, setTreeData] = useState<TConnectionNode[]>([])

  const treeRef = useRef<TreeRef | null>(null)

  const handleLoadData = useCallback(
    async (treeNode: EventDataNode<TConnectionNode>) => {
      const id = treeNode.key as string
      const { content: points } = await triggerGetPoints(id).unwrap()
      const pointsTree = mapPointsToTree(points, id)
      const updatedTreeData = updateTreeData(treeData, id, pointsTree)
      setTreeData(updatedTreeData)
    },
    [treeData, triggerGetPoints]
  )

  useEffect(() => {
    if (point && point.pointId !== selectedKeys[0]) {
      setSelectedKeys([point.pointId])
      setExpendedKeys((prevState) => Array.from(new Set([...prevState, point.machineId])))

      if (treeRef.current) {
        treeRef.current.scrollTo({ key: point.pointId })
      }
    }
  }, [point, selectedKeys])

  useEffect(() => {
    if (machines) {
      setTreeData(renderMachineTree(machines))
    }
  }, [machines])

  const handleSelect = (keys: Key[], info: { node: TConnectionNode }) => {
    const { key, parentKey, content } = info.node
    if (content !== EConnectionContent.Points) {
      return
    }
    const machineId = parentKey as string
    const pointId = key as string

    setSelectedMachineId(machineId)
    setSelectedPointId(pointId)
  }

  const handleExpand = (keys: Key[]) => {
    setExpendedKeys(keys)
  }

  return (
    <Tree
      className={styles['tree']}
      showIcon={true}
      showLine={true}
      treeData={treeData}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      selectedKeys={selectedKeys}
      onSelect={handleSelect}
      loadData={handleLoadData}
      ref={treeRef}
    />
  )
}

export default ConnectionEquipmentsTree
