import {
  AdOutName,
  AdOutValue,
  AdParamName
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.constant'
import type {
  IFormAd,
  IOutDataAd,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.type'
import {
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IKinematicElement, IOut } from '@/types/kinematic/kinematic.type'
import { FreqRegul } from '@/types/kinematic/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common'

export const calculateFreqInValue = (fu?: number | null, n2p?: number | null) =>
  isNumber(fu) && isNumber(n2p) ? (fu / n2p) * 0.97 : null

export const calculateAdxfs = (adxfu?: number | null, n2p?: number | null) =>
  isNumber(adxfu) && isNumber(n2p) ? (adxfu / n2p) * 0.03 : null

export const calculateAdxfe = (adxfu?: number | null) => (isNumber(adxfu) ? adxfu * 2 : null)

export const calculateAdxfnl = (adxfu?: number | null) => (isNumber(adxfu) ? adxfu * 6 : null)

export const calculateAdxfadz = (adxfrad?: number | null, nr?: number | null) =>
  isNumber(adxfrad) && isNumber(nr) ? adxfrad * nr : null
export const calculateAdxfads = (adxfrad?: number | null, ns?: number | null) =>
  isNumber(adxfrad) && isNumber(ns) ? adxfrad * ns : null

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockAD, outs } = kinematicElement

  const fieldsData: IFormAd = {
    [AdParamName.Descriptipn]: description,
    [AdParamName.FreqCoefForIn]: ins && ins[0] ? ins[0].freqCoefForIn : 1,
    [AdParamName.FreqRegulField]: FreqRegul.None,
    [AdParamName.Fu]: null,
    [AdParamName.N2p]: null,
    [AdParamName.Nr]: null,
    [AdParamName.Ns]: null
  }

  let outsData: IOuts | null = null

  if (kinematicBlockAD) {
    const { fu, n2p, nr, ns } = kinematicBlockAD
    Object.assign(fieldsData, {
      [AdParamName.Fu]: fu,
      [AdParamName.N2p]: n2p,
      [AdParamName.Nr]: nr,
      [AdParamName.Ns]: ns !== 0 ? ns : null
    })
  }

  if (outs) {
    const values = {
      [AdOutValue.Adxfu]: outs[0].freqOutValue,
      [AdOutValue.Adxfsh]: outs[1].freqOutValue,
      [AdOutValue.Adxfrad]: outs[2].freqOutValue,
      [AdOutValue.Adxfs]: outs[3].freqOutValue,
      [AdOutValue.Adxfe]: outs[4].freqOutValue,
      [AdOutValue.Adxfnl]: outs[5].freqOutValue,
      [AdOutValue.Adxfadz]: outs[6].freqOutValue,
      [AdOutValue.Adxfads]: outs[7].freqOutValue
    }

    const names = {
      [AdOutName.Adxfu]: outs[0].freqOutName,
      [AdOutName.Adxfsh]: outs[1].freqOutName,
      [AdOutName.Adxfrad]: outs[2].freqOutName,
      [AdOutName.Adxfs]: outs[3].freqOutName,
      [AdOutName.Adxfe]: outs[4].freqOutName,
      [AdOutName.Adxfnl]: outs[5].freqOutName,
      [AdOutName.Adxfadz]: outs[6].freqOutName,
      [AdOutName.Adxfads]: outs[7].freqOutName
    }

    outsData = {
      values,
      names
    }
  }

  return { fieldsData, outsData }
}

export const getCalculatedResults = (fieldsValue: IFormAd, kinematicBlockName?: string) => {
  const { fu, n2p, ns, nr } = fieldsValue

  const resultAdxfradValue = calculateFreqInValue(fu, n2p)
  const names = {
    [AdOutValue.Adxfu]: `${kinematicBlockName}_fU`,
    [AdOutValue.Adxfsh]: `${kinematicBlockName}_fSh`,
    [AdOutValue.Adxfrad]: `${kinematicBlockName}_Frad`,
    [AdOutValue.Adxfs]: `${kinematicBlockName}_Fs`,
    [AdOutValue.Adxfe]: `${kinematicBlockName}_Fe`,
    [AdOutValue.Adxfnl]: `${kinematicBlockName}_Fnl`,
    [AdOutValue.Adxfadz]: `${kinematicBlockName}_Fadz`,
    [AdOutValue.Adxfads]: `${kinematicBlockName}_Fads`
  }

  const values: { [key in AdOutName]: number | null } = {
    [AdOutName.Adxfu]: fu,
    [AdOutName.Adxfsh]: null,
    [AdOutName.Adxfrad]: resultAdxfradValue,
    [AdOutName.Adxfs]: calculateAdxfs(fu, n2p),
    [AdOutName.Adxfe]: calculateAdxfe(fu),
    [AdOutName.Adxfnl]: calculateAdxfnl(fu),
    [AdOutName.Adxfadz]: calculateAdxfadz(resultAdxfradValue, nr),
    [AdOutName.Adxfads]: calculateAdxfads(resultAdxfradValue, ns)
  }

  Object.keys(values).forEach((key) => {
    const keyType = key as AdOutName
    const resultValue = values[keyType]
    if (isNumber(resultValue)) {
      values[keyType] = Number(formatFloat(resultValue, REAL_NUMBER))
    }
  })

  return { values, names }
}

export const parseDataForChangeAd = (
  kinematicElement: IKinematicElement,
  fieldValues: IFormAd,
  outsData: IOuts
): IKinematicElement => {
  const data = {
    ...kinematicElement,
    description: fieldValues[AdParamName.Descriptipn],
    kinematicBlockAD: {
      n2p: fieldValues[AdParamName.N2p],
      freqRegul: fieldValues[AdParamName.FreqRegulField],
      fu: fieldValues[AdParamName.Fu],
      nr: fieldValues[AdParamName.Nr],
      ns: fieldValues[AdParamName.Ns]
    }
  }

  const { names, values } = outsData

  const outs: IOut[] = [
    {
      freqOutName: names[AdOutName.Adxfu],
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: values[AdOutValue.Adxfu]
    },
    {
      freqOutName: names[AdOutName.Adxfsh],
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: values[AdOutValue.Adxfsh]
    },
    {
      freqOutName: names[AdOutName.Adxfrad],
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: values[AdOutValue.Adxfrad]
    },
    {
      freqOutName: names[AdOutName.Adxfs],
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: values[AdOutValue.Adxfs]
    },
    {
      freqOutName: names[AdOutName.Adxfe],
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: values[AdOutValue.Adxfe]
    },
    {
      freqOutName: names[AdOutName.Adxfnl],
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: values[AdOutValue.Adxfnl]
    },
    {
      freqOutName: names[AdOutName.Adxfadz],
      freqOutCoefKb: fieldValues[AdParamName.Nr],
      freqOutCoefMachine: fieldValues[AdParamName.Nr],
      freqOutValue: values[AdOutValue.Adxfadz] || 0
    },
    {
      freqOutName: names[AdOutName.Adxfads],
      freqOutCoefKb: fieldValues[AdParamName.Ns],
      freqOutCoefMachine: fieldValues[AdParamName.Ns],
      freqOutValue: values[AdOutValue.Adxfads]
    }
  ]

  return { ...data, outs }
}

export const formatDataForTable = (outs: IOuts) => {
  const { values, names } = outs
  const data = [
    {
      label: 'Частота питающей сети',
      name: handleName(names[AdOutName.Adxfu]),
      value: handleValue(values[AdOutValue.Adxfu])
    },
    {
      label: 'Частота ШИМ',
      name: handleName(names[AdOutName.Adxfsh]),
      value: handleValue(values[AdOutValue.Adxfsh])
    },
    {
      label: 'Частота вращения',
      name: handleName(names[AdOutName.Adxfrad]),
      value: handleValue(values[AdOutValue.Adxfrad])
    },
    {
      label: 'Частота скольжения',
      name: handleName(names[AdOutName.Adxfs]),
      value: handleValue(values[AdOutValue.Adxfs])
    },
    {
      label: 'Частота ЭМ взаимодействия (магнитострикционная, ЭМС)',
      name: handleName(names[AdOutName.Adxfe]),
      value: handleValue(values[AdOutValue.Adxfe])
    },
    {
      label: 'Частота искажения питания',
      name: handleName(names[AdOutName.Adxfnl]),
      value: handleValue(values[AdOutValue.Adxfnl])
    },
    {
      label: 'Зубцовая частота АД',
      name: handleName(names[AdOutName.Adxfadz]),
      value: handleValue(values[AdOutValue.Adxfadz])
    },
    {
      label: 'Статорная частота АД',
      name: handleName(names[AdOutName.Adxfads]),
      value: handleValue(values[AdOutValue.Adxfads])
    }
  ]

  return data.map<IOutDataAd>((item, key) => ({ ...item, key }))
}
