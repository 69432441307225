import { Checkbox, Slider } from 'antd'
import type { SliderRangeProps } from 'antd/es/slider'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import cn from 'classnames'
import type { SliderRef } from 'rc-slider/lib/Slider'
import type { FC, RefAttributes } from 'react'
import React from 'react'

import styles from './slider-app.module.css'

type TProps = {
  borderBottom?: boolean
  onDoubleClick?: () => void
  onCheckboxChange?: ((e: CheckboxChangeEvent) => void) | undefined
  onChange: (range: number[]) => void
} & SliderRangeProps &
  RefAttributes<SliderRef>

const SliderApp: FC<TProps> = ({
  onDoubleClick,
  onCheckboxChange,
  vertical,
  borderBottom,
  value,
  onChange,
  ...props
}) => {
  const handleChange = (range: number[]) => {
    if (range[1] - range[0] === 0) {
      return
    }

    onChange(range)
  }

  return (
    <div
      className={cn(styles['slider-container'], {
        [styles['horizontal-slider-container']]: !vertical,
        [styles['vertical-slider-container']]: vertical,
        [styles['border-bottom']]: borderBottom
      })}
      onDoubleClick={onDoubleClick}
    >
      {vertical && <Checkbox className={styles['checkbox']} onChange={onCheckboxChange} />}
      <Slider
        className={cn({
          [styles['horizontal-slider']]: !vertical,
          [styles['vertical-slider']]: vertical
        })}
        vertical={vertical}
        {...props}
        onChange={handleChange}
        value={value}
      />
    </div>
  )
}
export default SliderApp
