import type { TExpressSection } from '@/store/reducers/charts/express-section.slice'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import React from 'react'
import { ReferenceArea, ReferenceLine } from 'recharts'

import { binarySearch } from './calculates.helper'

type TRenderSectionsProps = {
  sectionOptions: TExpressSection[]
  setIsAreaDrag: React.Dispatch<React.SetStateAction<boolean>>
  setIsResizeLeft: React.Dispatch<React.SetStateAction<boolean>>
  setIsResizeRight: React.Dispatch<React.SetStateAction<boolean>>
  refIndexSection: React.MutableRefObject<number>
  chartData: IChartSpectrum[]
  sliderValuesHorizontal: number[]
}

export const renderExpressSectionsChart = ({
  sectionOptions,
  setIsAreaDrag,
  refIndexSection,
  setIsResizeLeft,
  setIsResizeRight,
  chartData,
  sliderValuesHorizontal
}: TRenderSectionsProps) => {
  const calculateCoordinatesSection = (section: TExpressSection) => {
    const foundIndexX1 = binarySearch(chartData, section.fl, 0, chartData.length - 1)?.index

    const foundIndexX2 = binarySearch(chartData, section.fh, 0, chartData.length - 1)?.index

    return {
      x1:
        chartData[sliderValuesHorizontal[0]]?.frequency < chartData[foundIndexX1]?.frequency
          ? chartData[foundIndexX1]?.frequency
          : undefined,
      x2:
        chartData[sliderValuesHorizontal[1]]?.frequency > chartData[foundIndexX2]?.frequency
          ? chartData[foundIndexX2]?.frequency
          : undefined
    }
  }

  const calculateCoordinatesResizeLeft = (section: TExpressSection) => {
    const foundIndexX1 = binarySearch(chartData, section.fl, 0, chartData.length - 1)?.index

    return { x: chartData[foundIndexX1]?.frequency }
  }

  const calculateCoordinatesResizeRight = (section: TExpressSection) => {
    const foundIndexX1 = binarySearch(chartData, section.fh, 0, chartData.length - 1)?.index

    return { x: chartData[foundIndexX1]?.frequency }
  }

  return sectionOptions.map((section, index) => (
    <React.Fragment key={index}>
      <ReferenceArea
        {...calculateCoordinatesSection(section)}
        label={{
          position: 'insideTopLeft',
          value: index + 1,
          fill: '#000',
          stroke: 'blue'
        }}
        fill={section.fill}
        cursor={'move'}
        onMouseDown={() => {
          setIsAreaDrag(true)
          refIndexSection.current = index
        }}
      />
      <ReferenceLine
        {...calculateCoordinatesResizeLeft(section)}
        stroke={'blue'}
        strokeWidth={5}
        strokeOpacity={0}
        cursor={'col-resize'}
        onMouseDown={() => {
          setIsResizeLeft(true)
          refIndexSection.current = index
        }}
      />
      <ReferenceLine
        {...calculateCoordinatesResizeRight(section)}
        stroke={'blue'}
        strokeWidth={5}
        strokeOpacity={0}
        cursor={'col-resize'}
        onMouseDown={() => {
          setIsResizeRight(true)
          refIndexSection.current = index
        }}
      />
    </React.Fragment>
  ))
}
