import type { IConnectionElement } from '@/app/configuration/components/connections/components/connection-panel/connection-panel.type'
import { SliceName } from '@/constants/store/slices.constant'
import type { IConnectionInitialState } from '@/types/connection/connection-initial-state.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IConnectionInitialState = {
  connectionElement: undefined
}

export const connectionUiSlice = createSlice({
  name: SliceName.Connection,
  initialState,
  reducers: {
    setConnectionElement(state, action: PayloadAction<IConnectionElement | undefined>) {
      state.connectionElement = action.payload
    }
  }
})
