import { mapVp3701ChannelType } from '@/constants/point/point.constant'
import { EVp3701ChannelType } from '@/enums/connection/vp3701-channel-type.enum'

export const MeasurementLabel = {
  Name: 'Наименование',
  TypeControl: 'Вид контроля',
  TargetType: 'Тип устройства',
  MeasurementType: 'Вид измерения',
  isActivated: 'Активно',
  UnitType: 'Единица измерения',
  SamplingRate: 'Частота дискретизации, Гц',
  Ltr24SamplingRate: 'Частота дискретизации, Гц',
  DurationInSec: 'Длительность сигнала, сек',
  IirFilterEnabled: 'Антиалайзинговый фильтр',
  VisualFreqLimit: 'Граничная частота',
  SpecterLineCount: 'Количество линий',
  Detector: 'Детектор',
  CommonLevelType: 'Полоса, Гц',
  Coef: 'Коэффициент излучения',
  NumberOfAverages: 'Количество усреднений',
  TemperatureType: 'Источник'
} as const

export const MeasurementName = {
  Name: 'name',
  TypeControl: 'typeControl',
  TargetType: 'targetType',
  MeasurementType: 'measurementType',
  isActivated: 'isActivated',
  UnitType: 'unitType',
  SamplingRate: 'samplingRate',
  Ltr24SamplingRate: 'ltr24SamplingRate',
  DurationInSec: 'durationInSec',
  IirFilterEnabled: 'iirFilterEnabled',
  VisualFreqLimit: 'visualFreqLimit',
  SpecterLineCount: 'specterLineCount',
  Detector: 'detector',
  CommonLevelType: 'commonLevelType',
  Coef: 'coef',
  NumberOfAverages: 'numberOfAverages',
  TemperatureType: 'temperatureType'
} as const

export const temperatures = [
  {
    label: mapVp3701ChannelType[EVp3701ChannelType.InternalSensorTemperature],
    value: EVp3701ChannelType.InternalSensorTemperature
  },
  {
    label: mapVp3701ChannelType[EVp3701ChannelType.ObjectTemperature],
    value: EVp3701ChannelType.ObjectTemperature
  }
]
