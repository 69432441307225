import { ELtr24ChannelType } from '@/enums/ltr/ltr24-channel-type.enum'
import type { IOptions } from '@/types/common/options.interface'

export const ltr24ChannelTypeOptions: IOptions<ELtr24ChannelType>[] = [
  {
    label: 'Линейный вход',
    value: ELtr24ChannelType.LIN
  },
  {
    label: 'Датчик ICP',
    value: ELtr24ChannelType.ICP
  },
  {
    label: 'Current',
    value: ELtr24ChannelType.CURRENT
  },
  {
    label: 'Eddy',
    value: ELtr24ChannelType.EDDY
  }
]
