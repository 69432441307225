import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { TPlan } from '@/types/plan/plan.type'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './table-plans-cell.module.css'

type TProps = {
  handleClickRoute: (route: TPlan) => void
  plan: TPlan
}

const TablePlansCell: FC<TProps> = ({ plan, handleClickRoute }) => {
  const { selectedPlanId } = useTypedSelector((state) => state.planReducer)

  return (
    <li
      className={cn(styles['plans-item'], {
        [styles['plans-item-selected']]: plan.planId === selectedPlanId
      })}
    >
      <button
        onClick={() => {
          handleClickRoute(plan)
        }}
        className={styles['plans-button']}
      >
        <p className={styles['plans-title']}>{plan.name}</p>
        <p className={styles['plans-title']}>{plan.deviceType}</p>
        <p className={styles['plans-title']}>
          <span
            className={cn('material-symbols-sharp', {
              [styles['status-bad']]: plan.status === 'COMPLETED_WITH_ERROR'
            })}
          >
            {plan.status === 'WAIT' ? 'upload' : 'get_app'}
          </span>
        </p>
        <p className={styles['plans-title']}>{plan.modified}</p>
        <p className={styles['plans-title']}>{plan.nextExecutionTimestamp}</p>
        <p className={styles['plans-title']}>{plan.lastExecutionTimestamp}</p>
      </button>
    </li>
  )
}

export default TablePlansCell
