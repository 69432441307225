import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { IRequestVp3701Update } from '@/types/device/request-vp3701-update.interface'
import type { IResposeVp3701List } from '@/types/device/respose-vp3701-list.interface'
import type { IVp3701 } from '@/types/device/vp3701/vp3701.interface'
import type { IGenerator } from '@/types/devices.type'

import { baseApi } from './api-base-query'

export interface IGeneratorsGetResponse {
  content: IGenerator[]
}

type ICreatedGenerator = Omit<IGenerator, 'generatorId'>

export const devicesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVp3701List: builder.query<IResposeVp3701List, void>({
      query: () => ({ url: ApiResource.Vp3701 + '?size=100' }),
      providesTags: [ApiTag.Devices]
    }),
    getVp3701: builder.query<IVp3701, string | undefined>({
      query: (id) => ({ url: `${ApiResource.Vp3701}/${id}` }),
      providesTags: [ApiTag.Devices]
    }),
    updateVp3701: builder.mutation<void, { deviceId: string; body: IRequestVp3701Update }>({
      query: (data) => ({ url: `${ApiResource.Vp3701}/${data.deviceId}`, body: data.body, method: 'PUT' }),
      invalidatesTags: [ApiTag.Devices]
    }),
    getGenerators: builder.query<IGeneratorsGetResponse, void>({
      query: () => ({ url: ApiResource.Generators }),
      providesTags: [ApiTag.Devices]
    }),
    getGenerator: builder.query<IGenerator, string | undefined>({
      query: (id) => ({ url: `${ApiResource.Generators}/${id}` }),
      providesTags: [ApiTag.Devices]
    }),
    createGenerator: builder.mutation<{ id?: string }, ICreatedGenerator>({
      query: (data) => ({
        url: ApiResource.Generators,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Devices]
    }),
    updateGenerator: builder.mutation<{ id?: string }, IGenerator>({
      query: (data) => ({
        url: `${ApiResource.Generators}/${data.generatorId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Devices]
    })
  })
})

export const {
  useGetVp3701ListQuery,
  useLazyGetVp3701ListQuery,
  useGetVp3701Query,
  useLazyGetVp3701Query,
  useGetGeneratorQuery,
  useLazyGetGeneratorQuery,
  useLazyGetGeneratorsQuery,
  useGetGeneratorsQuery,
  useCreateGeneratorMutation,
  useUpdateGeneratorMutation,
  useUpdateVp3701Mutation
} = devicesApi
