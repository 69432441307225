import {
  WheelTypeOut,
  WheelTypeParamName
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/wheel-type-form/wheel-type-form.constant'
import type {
  IFormWheelType,
  IWheelTypeOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/wheel-type-form/wheel-type-form.type'
import {
  KbParam,
  TypeSource
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { ITableDataOuts } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import {
  calculateValue,
  formatOutValues,
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import type { IIn, IKinematicElement, IOut } from '@/types/kinematic/kinematic.type'
import { EKinematicBlockType } from '@/types/kinematic/kinematic.type'
import { isNumber } from '@/utils/common'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockCW, kinematicBlockTW, outs } = kinematicElement

  interface IResult {
    fields: IFormWheelType
    ins: IIn[] | null
    outs: IWheelTypeOuts | null
  }

  const result: IResult = {
    fields: {
      [KbParam.Description]: description,
      [KbParam.TypeSource]: TypeSource.Missing,
      [KbParam.FreqCoefForIn]: ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [KbParam.KinematicBlockSourceId]: null,
      [KbParam.FreqOutSource]: null,
      [WheelTypeParamName.Zb]: null
    },
    ins: null,
    outs: null
  }

  if (kinematicBlockCW) {
    result.fields[WheelTypeParamName.Zb] = kinematicBlockCW.zb
  }

  if (kinematicBlockTW) {
    result.fields[WheelTypeParamName.Zb] = kinematicBlockTW.zb
  }

  if (ins) {
    result.fields[KbParam.TypeSource] = ins[0].kinematicBlockSourseId ? TypeSource.Kb : TypeSource.Missing
    result.fields[KbParam.KinematicBlockSourceId] = ins[0].kinematicBlockSourseId
    result.fields[KbParam.FreqOutSource] = ins[0].freqInValue
    result.ins = ins
  }

  if (outs) {
    result.outs = Object.values(WheelTypeOut).reduce((acc, value, index) => {
      acc[value] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<WheelTypeOut, IOut>)
  }
  return result
}

export const getCalculatedResults = (fieldsValue: IFormWheelType, ins: IIn[], kinematicBlockName: string) => {
  const { freqOutSource, freqCoefForIn, zb } = fieldsValue
  const { freqOutCoefMachineSourse, freqOutNameSourse } = ins[0]

  const freqInValue = calculateValue(freqOutSource, freqCoefForIn)
  const freqInCoefMachine = calculateValue(freqOutCoefMachineSourse, freqCoefForIn)

  const result: IWheelTypeOuts = {
    [WheelTypeOut.Frtw]: {
      freqOutName: freqOutNameSourse,
      freqOutCoefKb: freqCoefForIn || null,
      freqOutCoefMachine: freqInCoefMachine,
      freqOutValue: freqInValue
    },
    [WheelTypeOut.Fl]: {
      freqOutName: `${kinematicBlockName}_Fl`,
      freqOutCoefKb: zb,
      freqOutCoefMachine: calculateValue(freqInCoefMachine, zb),
      freqOutValue: calculateValue(freqInValue, zb)
    }
  }

  return formatOutValues<IWheelTypeOuts>(result)
}

export const parseDataForChange = (
  kinematicElement: IKinematicElement,
  fields: IFormWheelType,
  ins: IIn[],
  tgbOuts: IWheelTypeOuts
): IKinematicElement => {
  const { description, kinematicBlockSourseId, freqCoefForIn } = fields

  const getFieldType = (type: EKinematicBlockType): string => {
    if (type === EKinematicBlockType.CW) {
      return 'kinematicBlockCW'
    }

    if (type === EKinematicBlockType.TW) {
      return 'kinematicBlockTW'
    }

    return ''
  }

  return {
    ...kinematicElement,
    description: description,
    [getFieldType(kinematicElement.kinematicBlockType)]: {
      zb: fields[WheelTypeParamName.Zb]
    },
    ins: [
      {
        freqInName: ins[0].freqOutNameSourse,
        freqInValue: ins[0].freqInValue,
        freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
        freqInCoefMachine:
          isNumber(freqCoefForIn) && isNumber(ins[0].freqOutCoefMachineSourse)
            ? ins[0].freqOutCoefMachineSourse * freqCoefForIn
            : 1,
        kinematicBlockSourseId: kinematicBlockSourseId,
        freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse || 0,
        freqOutNameSourse: ins[0].freqOutNameSourse || 'Fin1',
        freqCoefForIn: isNumber(freqCoefForIn) ? freqCoefForIn : 1,
        measurementSourseId: null
      }
    ],
    outs: Object.values(tgbOuts)
  }
}

export const formatDataForTable = (outs: IWheelTypeOuts): ITableDataOuts[] => {
  const { fl, frtw } = outs
  const data: Omit<ITableDataOuts, 'key'>[] = [
    {
      label: 'Частота вращения',
      freqOutName: handleName(frtw.freqOutName),
      freqOutCoefKb: handleValue(frtw.freqOutCoefKb),
      freqOutCoefMachine: handleValue(frtw.freqOutCoefMachine),
      freqOutValue: handleValue(frtw.freqOutValue)
    },
    {
      label: 'Лопастная частота',
      freqOutName: handleName(fl.freqOutName),
      freqOutCoefKb: handleValue(fl.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fl.freqOutCoefMachine),
      freqOutValue: handleValue(fl.freqOutValue)
    }
  ]

  return data.map<ITableDataOuts>((item, key) => ({ ...item, key }))
}
