import type { IConnectionElement } from '@/app/configuration/components/connections/components/connection-panel/connection-panel.type'
import { Vp3701ModulesName } from '@/constants/device/vp3701/vp3701-modules-name.constant'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import useActions from '@/hooks/use-actions'
import type { IConnection } from '@/types/connection.type'
import { Empty } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './path-connections.module.css'

type TProps = {
  title: string
  connections?: IConnectionElement[]
  undefinedText: string
  foundConnectedElement?: IConnection
}

const PathConnections: FC<TProps> = ({ title, connections, undefinedText, foundConnectedElement }) => {
  const { setSelectedPointId, setSelectedDeviceId, setConnectionElement } = useActions()
  const handleSelectPointAndDeviceClick = (element: IConnectionElement) => {
    setSelectedPointId(element.measuringPointId)

    let deviceId = element.generatorId
    if (element.vp3701Id) {
      const nameModule =
        element.signalType === EPhysicalQuantityType.TEMPERATURE ? Vp3701ModulesName.MLX90 : Vp3701ModulesName.KX134

      deviceId = `${element.vp3701Id}-${nameModule}-${element.vp3701ChannelType}`
    }

    if (element.ltrModuleId) {
      deviceId = `${element.ltrModuleId}-${element.ltrModuleChannelNumber}`
    }

    setSelectedDeviceId(deviceId)
    setConnectionElement(element)
  }

  return (
    <div className={styles['path-connections']}>
      <h2 className={styles['title']}>{title}</h2>
      <div className={styles['list']}>
        {!connections && <Empty description={undefinedText} className={styles['empty']} />}
        {connections?.length === 0 && <Empty description={'Подключений нет'} className={styles['empty']} />}
        {connections?.map((connection) => (
          <span
            key={connection.connectionId}
            className={cn(styles['list-item'], {
              [styles['list-item-found']]: foundConnectedElement?.connectionId === connection.connectionId
            })}
          >
            <a onClick={() => handleSelectPointAndDeviceClick(connection)} className={styles['link']}>
              {connection.name}
            </a>
          </span>
        ))}
      </div>
    </div>
  )
}

export default PathConnections
