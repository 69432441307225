import { ETargetType } from '@/enums/connection/target-type.enum'

export const DeviceValue = {
  VP3701: 'vp3701',
  Generator: 'generator'
}

export const DeviceLabel = {
  VP3701: 'VP-3701',
  Generator: 'Генератор'
}

export const mapTargetType = {
  [ETargetType.VP3701]: 'VP-3701',
  [ETargetType.LTR24]: 'LTR-24',
  [ETargetType.LTR51]: 'LTR-51',
  [ETargetType.GENERATOR]: 'Генератор'
}
