import { FreqRegul } from '@/types/kinematic/kinematic.type'

export const enum AdParamName {
  Descriptipn = 'description',
  FreqCoefForIn = 'freqCoefForIn',
  Fu = 'fu',
  N2p = 'n2p',
  FreqRegulField = 'freqRegulField',
  Nr = 'nr',
  Ns = 'ns',
  PwmData = 'pwmData',
  Fsh = 'fsh',
  FuMin = 'fuMin',
  FuMax = 'fuMax',
  Dfr = 'dfr'
}

export const enum AdOutValue {
  Adxfu = 'adxfu',
  Adxfsh = 'adxfsh',
  Adxfrad = 'adxfrad',
  Adxfs = 'adxfs',
  Adxfe = 'adxfe',
  Adxfnl = 'adxfnl',
  Adxfadz = 'adxfadz',
  Adxfads = 'adxfads'
}

export const enum AdOutName {
  Adxfu = 'adxfu',
  Adxfsh = 'adxfsh',
  Adxfrad = 'adxfrad',
  Adxfs = 'adxfs',
  Adxfe = 'adxfe',
  Adxfnl = 'adxfnl',
  Adxfadz = 'adxfadz',
  Adxfads = 'adxfads'
}

export const optionsFreqRegul = [
  { label: 'Отсутствует', value: FreqRegul.None },
  { label: 'ШИМ', value: FreqRegul.PWM },
  { label: 'Фазный ротор', value: FreqRegul.PhaseRotor }
]
