import type {
  TMeasurementForm,
  TMeasurementStatic
} from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import {
  mapSamplingRateForName,
  mapSamplingRateLtr24ForName,
  signalMeasurementOption,
  temperatureMeasurementOption
} from '@/constants/measurement/measurements.constant'
import { nameMeasurementType } from '@/constants/measurement/measurment-type.constant'
import { mapPhysicalQuantity, mapUnitType } from '@/constants/measurement/unit.constant'
import type { ETargetType } from '@/enums/connection/target-type.enum'
import { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { IOptions } from '@/types/common/options.interface'
import type {
  ICommonLevelDescription,
  IMeasurement,
  IProgramSpecterDescription,
  ISignalDescription,
  ITemperatureDescription,
  TCreatedMeasurement
} from '@/types/measurement/measurement.type'
import type { IMeasuringPoint } from '@/types/point/point.type'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { isUndefined } from 'lodash'

export const getMeasurementTypeOptions = (point?: IMeasuringPoint) => {
  if (point) {
    const measurementTypes: {
      [key in EPhysicalQuantityType]?: IOptions<ETypeMeasurement>[]
    } = {
      // Set to undefined for future features
      [EPhysicalQuantityType.TEMPERATURE]: temperatureMeasurementOption,
      [EPhysicalQuantityType.VIBRO_ACCELERATION]: signalMeasurementOption,
      [EPhysicalQuantityType.AMPERAGE]: signalMeasurementOption,
      [EPhysicalQuantityType.VOLTAGE]: signalMeasurementOption,
      [EPhysicalQuantityType.VIBRO_VELOCITY]: undefined,
      [EPhysicalQuantityType.VIBRO_DISPLACEMENT]: undefined,
      [EPhysicalQuantityType.RPM]: undefined,
      [EPhysicalQuantityType.DRPM]: undefined,
      [EPhysicalQuantityType.PHASE]: undefined,
      [EPhysicalQuantityType.NULL]: undefined
    }

    return measurementTypes[point.physicalQuantityType]
  }

  return undefined
}

export const mapMeasurementToForm = (measurement: IMeasurement): TMeasurementForm => ({
  activated: !measurement.paused
})

export const mapMeasurementToStatic = (measurement: IMeasurement): TMeasurementStatic => ({
  ...measurement,
  measurementType: getTypeMeasurement(measurement)
})

const filterMeasurementByType = (measurements: IMeasurement[], typeMeasurement: ETypeMeasurement): IMeasurement[] =>
  measurements.filter((measurement) => getTypeMeasurement(measurement) === typeMeasurement)

const abbreviateToThousands = (currentNumber: string) => {
  if (currentNumber?.length < 4) {
    return currentNumber
  }

  const integerPart = currentNumber.slice(0, -3)
  const fractionalPart = currentNumber.slice(-3)
  const floatNumber = parseFloat(fractionalPart) / 100

  const lineEnding = Math.round(floatNumber) || ''

  return `${integerPart}k${lineEnding}`
}

const formatSpecterMeasurement = (firstSpecterIndication: string, secondSpecterIndication: string) => {
  const visualFreqLimit = firstSpecterIndication.slice(5)
  const [, , specterLineCount] = secondSpecterIndication.split('_')

  return `${abbreviateToThousands(visualFreqLimit)}_${specterLineCount}`
}

const formatCommonLevelMeasurement = (commonLevelIndication: string) => {
  const [, firstValue, secondValue] = commonLevelIndication.split('_')

  return `${firstValue}-${abbreviateToThousands(secondValue)}`
}

const generateNameByCommonLevelDescription = (
  measurements: IMeasurement[],
  unit?: EUnitType,
  commonLevelDescription?: ICommonLevelDescription
) => {
  if (unit && commonLevelDescription) {
    const { commonLevelType, detector } = commonLevelDescription
    const filteredCommonLevelDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.ProgramSpecterDescription
    )
    const numberCommonLevelMeasurement = ++filteredCommonLevelDescription.length
    const formattedCommonLevelMeasurement = formatCommonLevelMeasurement(commonLevelType)
    const unitCommonLevelDescription = mapUnitType[unit]
    const typeMeasurement = mapPhysicalQuantity[unit]
    const postfixType = nameMeasurementType[typeMeasurement]
    return `BBL${postfixType}_${numberCommonLevelMeasurement}_${detector}_${formattedCommonLevelMeasurement}_${unitCommonLevelDescription}`
  }
}

const generateNameByProgramSpecterDescription = (
  measurements: IMeasurement[],
  unit?: EUnitType,
  programSpecterDescription?: IProgramSpecterDescription
) => {
  if (unit && programSpecterDescription) {
    const { specterLineCount, visualFreqLimit } = programSpecterDescription
    const filteredMeasurementsByProgramSpecterDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.ProgramSpecterDescription
    )
    const numberProgramSpecterMeasurement = ++filteredMeasurementsByProgramSpecterDescription.length
    const formattedSpecterMeasurement = formatSpecterMeasurement(visualFreqLimit, specterLineCount)
    const unitProgramSpecterMeasurement = mapUnitType[unit]
    const typeMeasurement = mapPhysicalQuantity[unit]
    const postfixType = nameMeasurementType[typeMeasurement]
    return `ASR${postfixType}_${numberProgramSpecterMeasurement}_${formattedSpecterMeasurement}_${unitProgramSpecterMeasurement}`
  }
}

const generateNameBySignalDescription = (
  measurements: IMeasurement[],
  unit?: EUnitType,
  signalDescription?: ISignalDescription
) => {
  if (unit && signalDescription) {
    const { ltr24SamplingRate, samplingRate, durationInSec } = signalDescription
    const filteredMeasurementsBySignalDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.SignalDescription
    )

    const numberSignalMeasurement = ++filteredMeasurementsBySignalDescription.length
    const rateSignalMeasurement =
      (samplingRate && mapSamplingRateForName[samplingRate]) ||
      (ltr24SamplingRate && mapSamplingRateLtr24ForName[ltr24SamplingRate])
    const unitSignalMeasurement = mapUnitType[unit]
    const typeMeasurement = mapPhysicalQuantity[unit]
    const postfixType = nameMeasurementType[typeMeasurement]

    return `TS${postfixType}_${numberSignalMeasurement}_${rateSignalMeasurement}_${durationInSec}_${unitSignalMeasurement}`
  }
}

const generateNameByTemperatureDescription = (
  measurements: IMeasurement[],
  unit?: EUnitType,
  temperatureDescription?: ITemperatureDescription
) => {
  if (!isUndefined(temperatureDescription) && unit) {
    const { temperatureType, coef } = temperatureDescription
    const temperatureTypeNames = {
      [ETemperatureType.OBJECT]: 'o',
      [ETemperatureType.SENSOR]: 'a'
    }

    const temperatureTypeForName = temperatureTypeNames[temperatureType]

    const filteredMeasurementsByTemperatureDescription = filterMeasurementByType(
      measurements,
      ETypeMeasurement.TemperatureDescription
    )
    const numberTemperatureMeasurementForName = ++filteredMeasurementsByTemperatureDescription.length

    const coefficientForName = coef ? `${coef.toString().split('.').join('')}_` : ''

    const unitSignalMeasurement = mapUnitType[unit]

    return `TR${temperatureTypeForName}_${numberTemperatureMeasurementForName}_${coefficientForName}${unitSignalMeasurement}`
  }
}

export const generateMeasurementName = (formMeasurement: TMeasurementForm, measurements?: IMeasurement[]) => {
  const { measurementType } = formMeasurement

  if (!isUndefined(measurementType) && !isUndefined(measurements)) {
    const nameGenerationPatterns: {
      [key in ETypeMeasurement]?: string
    } = {
      [ETypeMeasurement.TemperatureDescription]: generateNameByTemperatureDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement?.temperatureDescription
      ),
      [ETypeMeasurement.SignalDescription]: generateNameBySignalDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.signalDescription
      ),
      [ETypeMeasurement.ProgramSpecterDescription]: generateNameByProgramSpecterDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.programSpecterDescription
      ),
      [ETypeMeasurement.CommonLevelDescription]: generateNameByCommonLevelDescription(
        measurements,
        formMeasurement.unitType,
        formMeasurement.commonLevelDescription
      )
    }

    return nameGenerationPatterns[measurementType]
  }
}

export const mapToRequestMeasurement = (
  formMeasurement: TMeasurementForm,
  measurementName?: string,
  pointId?: string
): TCreatedMeasurement | undefined => {
  if (isUndefined(measurementName) || isUndefined(pointId)) {
    return undefined
  }

  return {
    measurementName: measurementName,
    targetType: formMeasurement.targetType as ETargetType,
    unitType: formMeasurement.unitType as EUnitType,
    measuringPointId: pointId,
    paused: false,
    temperatureDescription: formMeasurement.temperatureDescription,
    signalDescription: formMeasurement.signalDescription,
    programSpecterDescription: formMeasurement.programSpecterDescription,
    commonLevelDescription: formMeasurement.commonLevelDescription
  }
}
