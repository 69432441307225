import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { TMnemoScheme, TMnemoSchemeItem, TMnemoState } from '@/types/mnemonic.type'

import { baseApi } from './api-base-query'

interface IGetMnemoSchemeResponse {
  content: TMnemoScheme[]
}

interface ICreateMnemoSchemeResponse {
  id: string
}

interface IGetMnemoSchemeElementsResponse {
  content: TMnemoSchemeItem[]
  totalElements: number
}

interface IGetMnemoStateResponse {
  mnemoSchemeId: string
  mnemoElementState: TMnemoState[]
}

export const mnemonicApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMnemonics: builder.query<IGetMnemoSchemeResponse, string | undefined>({
      query: (nodeId) => ({
        url: `${ApiResource.Mnemo}?nodeId=${nodeId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Mnemo]
    }),

    getMnemoStats: builder.query<IGetMnemoStateResponse, string>({
      query: (mnemoId) => ({
        url: `${ApiResource.Mnemo}/${mnemoId}/state`,
        method: 'GET'
      })
    }),

    getCurrentMnemonic: builder.query<TMnemoScheme, string | undefined>({
      query: (mnemoSchemeId) => ({
        url: `${ApiResource.Mnemo}/${mnemoSchemeId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Mnemo]
    }),

    getMnemonicElement: builder.query<TMnemoSchemeItem, { mnemoSchemeId?: string; mnemoElementId?: string }>({
      query: ({ mnemoSchemeId, mnemoElementId }) => ({
        url: `${ApiResource.Mnemo}/${mnemoSchemeId}/elements/${mnemoElementId}`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Mnemo]
    }),

    getMnemonicElements: builder.query<IGetMnemoSchemeElementsResponse, string | undefined>({
      query: (mnemoSchemeId) => ({
        url: `${ApiResource.Mnemo}/${mnemoSchemeId}/elements`,
        method: 'GET'
      }),
      providesTags: [ApiTag.Mnemo]
    }),

    createMnemonic: builder.mutation<ICreateMnemoSchemeResponse, TMnemoScheme>({
      query: (data) => ({ url: '/mnemo', method: 'POST', body: data }),
      invalidatesTags: [ApiTag.Mnemo]
    }),

    changeMnemonic: builder.mutation<null, TMnemoScheme>({
      query: (data) => ({
        url: `${ApiResource.Mnemo}/${data.mnemoSchemeId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Mnemo]
    }),

    changeMnemonicElement: builder.mutation<null, TMnemoSchemeItem>({
      query: (data) => ({
        url: `${ApiResource.Mnemo}/${data.mnemoSchemeId}/elements/${data.mnemoElementId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Mnemo]
    }),

    addMnemonicItem: builder.mutation<ICreateMnemoSchemeResponse, TMnemoSchemeItem>({
      query: (data) => ({
        url: `${ApiResource.Mnemo}/${data.mnemoSchemeId}/elements`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Mnemo]
    }),

    removeMnemonic: builder.mutation<null, string>({
      query: (mnemoSchemeId) => ({
        url: `${ApiResource.Mnemo}/${mnemoSchemeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ApiTag.Mnemo]
    }),

    removeMnemonicItem: builder.mutation<null, { mnemoSchemeId: string; mnemoElementId: string }>({
      query: ({ mnemoSchemeId, mnemoElementId }) => ({
        url: `${ApiResource.Mnemo}/${mnemoSchemeId}/elements/${mnemoElementId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ApiTag.Mnemo]
    })
  })
})

export const {
  useGetMnemonicsQuery,
  useLazyGetMnemoStatsQuery,
  useGetMnemoStatsQuery,
  useGetCurrentMnemonicQuery,
  useGetMnemonicElementQuery,
  useGetMnemonicElementsQuery,
  useChangeMnemonicMutation,
  useChangeMnemonicElementMutation,
  useCreateMnemonicMutation,
  useRemoveMnemonicMutation,
  useAddMnemonicItemMutation,
  useRemoveMnemonicItemMutation
} = mnemonicApi
