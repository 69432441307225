export const enum EModeModal {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export interface IModalSettings {
  opened: boolean
  mode?: EModeModal
}
