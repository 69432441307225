import { ECommonLevelType } from '@/enums/measurment/common-level-type.enum'
import { EDetector } from '@/enums/measurment/detector.enum'
import { ELtr24SamplingRate } from '@/enums/measurment/ltr24-sampling-rate.enum'
import { ESamplingRate } from '@/enums/measurment/sampling-rate.enum'
import { ESpecterLineCount } from '@/enums/measurment/specter-line-count.enum'
import { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import { EVisualFreqLimit } from '@/enums/measurment/visual-freq-limit.enum'
import type { IOptions } from '@/types/common/options.interface'

export const temperatureMeasurementOption: IOptions<ETypeMeasurement>[] = [
  { label: 'Температура', value: ETypeMeasurement.TemperatureDescription }
]

export const signalMeasurementOption: IOptions<ETypeMeasurement>[] = [
  { label: 'Сигнал', value: ETypeMeasurement.SignalDescription },
  { label: 'Спектр', value: ETypeMeasurement.ProgramSpecterDescription },
  { label: 'Общий уровень', value: ETypeMeasurement.CommonLevelDescription }
]

export const samplingRateOptions = [
  { label: '25 600', value: ESamplingRate.RATE_15_25600 },
  { label: '12 800', value: ESamplingRate.RATE_14_12800 },
  { label: '6 400', value: ESamplingRate.RATE_13_6400 },
  { label: '3 200', value: ESamplingRate.RATE_12_3200 },
  { label: '1 600', value: ESamplingRate.RATE_11_1600 },
  { label: '800', value: ESamplingRate.RATE_10_800 }
]

export const samplingRateLtr24Options = [
  { label: '117 000', value: ELtr24SamplingRate.LTR24_FREQ_117K, disabled: true },
  { label: '58 000', value: ELtr24SamplingRate.LTR24_FREQ_58K },
  { label: '29 000', value: ELtr24SamplingRate.LTR24_FREQ_29K },
  { label: '14 000', value: ELtr24SamplingRate.LTR24_FREQ_14K },
  { label: '7 300', value: ELtr24SamplingRate.LTR24_FREQ_7K3 },
  { label: '3 600', value: ELtr24SamplingRate.LTR24_FREQ_3K6 },
  { label: '1 800', value: ELtr24SamplingRate.LTR24_FREQ_1K8 },
  { label: '915', value: ELtr24SamplingRate.LTR24_FREQ_915 }
]

export const freqLimitOptions = [
  { label: '10 000', value: EVisualFreqLimit.RATE_10000 },
  { label: '5 000', value: 'RATE_5000' },
  { label: '2 500', value: 'RATE_2500' },
  { label: '1 250', value: 'RATE_1250' },
  { label: '625', value: 'RATE_625' }
]

export const specterOptions = [
  { label: '25 600', value: ESpecterLineCount.COUNT_0_25600_32768 },
  { label: '12 800', value: ESpecterLineCount.COUNT_1_12800_16384 },
  { label: '6 400', value: ESpecterLineCount.COUNT_2_6400_8192 },
  { label: '3 200', value: ESpecterLineCount.COUNT_3_3200_4096 },
  { label: '1 600', value: ESpecterLineCount.COUNT_4_1600_2048 },
  { label: '800', value: ESpecterLineCount.COUNT_5_800_1024 },
  { label: '400', value: ESpecterLineCount.COUNT_6_400_512 },
  { label: '200', value: ESpecterLineCount.COUNT_7_200_256 },
  { label: '100', value: ESpecterLineCount.COUNT_8_100_128 }
]

export const mapCommonLevels = {
  [ECommonLevelType.LEVEL_2_1000]: '2-1000',
  [ECommonLevelType.LEVEL_10_1000]: '10-1000',
  [ECommonLevelType.LEVEL_10_2000]: '10-2000',
  [ECommonLevelType.LEVEL_05_500]: '05-500',
  [ECommonLevelType.LEVEL_07_300]: '07-300',
  [ECommonLevelType.LEVEL_100_5000]: '100-5000',
  [ECommonLevelType.LEVEL_5_500]: '5-500',
  [ECommonLevelType.LEVEL_10_5000]: '10-5000',
  [ECommonLevelType.LEVEL_5_10000]: '5-10000'
}

export const commonLevels = [
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_2_1000],
    value: ECommonLevelType.LEVEL_2_1000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_10_1000],
    value: ECommonLevelType.LEVEL_10_1000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_10_2000],
    value: ECommonLevelType.LEVEL_10_2000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_05_500],
    value: ECommonLevelType.LEVEL_05_500
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_07_300],
    value: ECommonLevelType.LEVEL_07_300
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_100_5000],
    value: ECommonLevelType.LEVEL_100_5000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_5_500],
    value: ECommonLevelType.LEVEL_5_500
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_10_5000],
    value: ECommonLevelType.LEVEL_10_5000
  },
  {
    label: mapCommonLevels[ECommonLevelType.LEVEL_5_10000],
    value: ECommonLevelType.LEVEL_5_10000
  }
]

export const detectors = [
  { value: EDetector.SKZ, label: 'CK3' },
  { value: EDetector.PEAK, label: 'ПИК', disabled: true },
  { value: EDetector.PEAK_PEAK, label: 'ПИК-ПИК', disabled: true }
]

export const mapMeasurementType: { [key in ETypeMeasurement]: string } = {
  [ETypeMeasurement.SignalDescription]: 'Сигнал',
  [ETypeMeasurement.ProgramSpecterDescription]: 'Спектр',
  [ETypeMeasurement.CommonLevelDescription]: 'Общий уровень',
  [ETypeMeasurement.TemperatureDescription]: 'Температура'
}

export const mapTemperatureType = {
  [ETemperatureType.OBJECT]: 'Объект',
  [ETemperatureType.SENSOR]: 'Датчик'
}

export const temperatureTypeOptions = [
  {
    value: ETemperatureType.OBJECT,
    label: mapTemperatureType[ETemperatureType.OBJECT]
  },
  {
    value: ETemperatureType.SENSOR,
    label: mapTemperatureType[ETemperatureType.SENSOR]
  }
]

export const mapSamplingRateLtr24ForName: { [KEY_MAP in ELtr24SamplingRate]: string } = {
  [ELtr24SamplingRate.LTR24_FREQ_117K]: '117k',
  [ELtr24SamplingRate.LTR24_FREQ_58K]: '58k',
  [ELtr24SamplingRate.LTR24_FREQ_29K]: '29k',
  [ELtr24SamplingRate.LTR24_FREQ_14K]: '14k',
  [ELtr24SamplingRate.LTR24_FREQ_7K3]: '7k3',
  [ELtr24SamplingRate.LTR24_FREQ_3K6]: '3k6',
  [ELtr24SamplingRate.LTR24_FREQ_1K8]: '1k8',
  [ELtr24SamplingRate.LTR24_FREQ_915]: '0k9'
}

export const mapSamplingRateForName: { [KEY_MAP in ESamplingRate]: string } = {
  [ESamplingRate.RATE_15_25600]: '25k6',
  [ESamplingRate.RATE_14_12800]: '12k8',
  [ESamplingRate.RATE_13_6400]: '6k4',
  [ESamplingRate.RATE_12_3200]: '3k2',
  [ESamplingRate.RATE_11_1600]: '1k6',
  [ESamplingRate.RATE_10_800]: '0k8',
  [ESamplingRate.RATE_09_400]: '0k4',
  [ESamplingRate.RATE_08_200]: '0k2',
  [ESamplingRate.RATE_07_100]: '0k1',
  [ESamplingRate.RATE_06_50]: '0k05',
  [ESamplingRate.RATE_05_25]: '0k025',
  [ESamplingRate.RATE_04_12]: '0k012',
  [ESamplingRate.RATE_00_0_781]: '0k7',
  [ESamplingRate.RATE_01_1_563]: '0k5',
  [ESamplingRate.RATE_02_3_125]: '0k01',
  [ESamplingRate.RATE_04_6_25]: '0k025'
}
