import { SliceName } from '@/constants/store/slices.constant'
import { generationPdfHarmonic, generationPdfSquare, generationPdfVp3701 } from '@/store/reducers/thunks/reports.thunk'
import type { IDeviceVP3701, IGenerator } from '@/types/devices.type'
import type { IReport, IReportsSlice } from '@/types/reports/reports.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IReportsSlice = {
  selectedReport: null,
  checkedData: []
}

export const reportsSlice = createSlice({
  name: SliceName.Reports,
  initialState,
  reducers: {
    setSelectedReport(state, action: PayloadAction<IReport>) {
      state.selectedReport = action.payload
    },
    setCheckedData(state, action: PayloadAction<IGenerator[] | IDeviceVP3701[]>) {
      state.checkedData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(generationPdfVp3701.fulfilled, () => {
      successNotificationCreate('PDF-файл успешно создан')
    })
    builder.addCase(generationPdfVp3701.rejected, () => {
      errorNotificationCreate('Ошибка при создании PDF-файла')
    })
    builder.addCase(generationPdfHarmonic.fulfilled, () => {
      successNotificationCreate('PDF-файл успешно создан')
    })
    builder.addCase(generationPdfHarmonic.rejected, () => {
      errorNotificationCreate('Ошибка при создании PDF-файла')
    })
    builder.addCase(generationPdfSquare.fulfilled, () => {
      successNotificationCreate('PDF-файл успешно создан')
    })
    builder.addCase(generationPdfSquare.rejected, () => {
      errorNotificationCreate('Ошибка при создании PDF-файла')
    })
  }
})
