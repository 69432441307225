import type {
  IFormDcg,
  IOutDataDcg,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcg-form/dcg-form.type'
import type { IOutDataDcm } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form.type'
import { TypeSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import {
  calculateValue,
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import type { IKinematicElement, IOut } from '@/types/kinematic/kinematic.type'
import { isNumber } from 'lodash'

import { DcgOutName, DcgParamName } from './dcg-form.constant'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockDCM, outs } = kinematicElement

  const result: {
    fields: IFormDcg
    outs: IOuts | null
  } = {
    fields: {
      [DcgParamName.Description]: description,
      [DcgParamName.FreqCoefForIn]: ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [DcgParamName.TypeSource]: TypeSource.Missing,
      [DcgParamName.Fu]: null,
      [DcgParamName.N2p]: null,
      [DcgParamName.Nr]: null,
      [DcgParamName.Nc]: null
    },
    outs: null
  }

  if (ins) {
    const typeSource = ins[0].measurementSourseId ? TypeSource.Mp : TypeSource.Manual

    const insAdapted = {
      [DcgParamName.TypeSource]: typeSource,
      [DcgParamName.FreqOutSource]: ins[0].freqInValue,
      [DcgParamName.FreqCoefForIn]: ins[0].freqCoefForIn
    }

    Object.assign(result.fields, insAdapted)
  }

  if (kinematicBlockDCM) {
    const { fu, n2p, nr, nc } = kinematicBlockDCM

    Object.assign(result.fields, {
      [DcgParamName.Fu]: fu,
      [DcgParamName.N2p]: n2p,
      [DcgParamName.Nr]: nr,
      [DcgParamName.Nc]: nc
    })
  }

  if (outs) {
    const outsData: Record<DcgOutName, IOut> = Object.values(DcgOutName).reduce((acc, key, index) => {
      acc[key] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<DcgOutName, IOut>)

    result.outs = outsData
  }

  return result
}

export const getCalculatedResults = (fieldsValue: IFormDcg, kinematicBlockName?: string) => {
  const { fu, nc, nr, freqOutSource, freqCoefForIn } = fieldsValue
  const freqInValue = isNumber(freqOutSource) && isNumber(freqCoefForIn) ? freqOutSource * freqCoefForIn : null

  const resultOuts: IOuts = {
    [DcgOutName.Fu]: {
      freqOutName: `${kinematicBlockName}_Fu`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: fu
    },
    [DcgOutName.Frdcg]: {
      freqOutName: `${kinematicBlockName}_Frdcg`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: freqCoefForIn,
      freqOutValue: freqInValue
    },
    [DcgOutName.Fe]: {
      freqOutName: `${kinematicBlockName}_Fe`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(fu, 2)
    },
    [DcgOutName.Fnl]: {
      freqOutName: `${kinematicBlockName}_Fnl`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(fu, 6)
    },
    [DcgOutName.Fdcgz]: {
      freqOutName: `${kinematicBlockName}_Fdcgz`,
      freqOutCoefKb: nr,
      freqOutCoefMachine: nr,
      freqOutValue: calculateValue(freqInValue, nr)
    },
    [DcgOutName.Fdcgc]: {
      freqOutName: `${kinematicBlockName}_Fdcgc`,
      freqOutCoefKb: nc,
      freqOutCoefMachine: nc,
      freqOutValue: calculateValue(freqInValue, nc)
    }
  }

  return resultOuts
}

export const formatDataForTable = (outs: IOuts): IOutDataDcm[] => {
  const { fu, fdcgc, fdcgz, fe, fnl, frdcg } = outs
  const data: Omit<IOutDataDcg, 'key'>[] = [
    {
      label: 'Частота питающей сети',
      name: handleName(fu.freqOutName),
      value: handleValue(fu.freqOutValue)
    },
    {
      label: 'Частота вращения',
      name: handleName(frdcg.freqOutName),
      value: handleValue(frdcg.freqOutValue)
    },
    {
      label: 'Частота ЭМ взаимодействия (магнитострикционная, ЭМС)',
      name: handleName(fe.freqOutName),
      value: handleValue(fe.freqOutValue)
    },
    {
      label: 'Частота искажения питания',
      name: handleName(fnl.freqOutName),
      value: handleValue(fnl.freqOutValue)
    },
    {
      label: 'Зубцовая частота DCG',
      name: handleName(fdcgc.freqOutName),
      value: handleValue(fdcgc.freqOutValue)
    },
    {
      label: 'Коллекторная частота DCG',
      name: handleName(fdcgz.freqOutName),
      value: handleValue(fdcgz.freqOutValue)
    }
  ]

  return data.map<IOutDataDcg>((item, key) => ({ ...item, key }))
}

export const parseDataForChangeDcg = (
  kinematicElement: IKinematicElement,
  fieldValues: IFormDcg,
  outsData: IOuts
): IKinematicElement => {
  const data: IKinematicElement = {
    ...kinematicElement,
    description: fieldValues.description,
    kinematicBlockDCM: {
      n2p: fieldValues.n2p,
      fu: fieldValues.fu,
      nr: fieldValues.nr,
      nc: fieldValues.nc
    },
    ins: [
      {
        freqInName: null,
        freqOutCoefIndexSourse: null,
        measurementSourseId: null,
        kinematicBlockSourseId: null,
        freqOutCoefMachineSourse: null,
        freqInValue: fieldValues.freqOutSource || null,
        freqOutNameSourse: 'Fin1',
        freqInCoefMachine: fieldValues[DcgParamName.FreqCoefForIn],
        freqCoefForIn: fieldValues[DcgParamName.FreqCoefForIn]
      }
    ],
    outs: Object.values(outsData)
  }

  return data
}
