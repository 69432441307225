import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import { CancelIconSvg } from '@/components/icons/cancel-icon-svg/cancel-icon-svg'
import { BdParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.constant'
import BearingForm from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bearing-form/bearing-form'
import { BrParamName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.constant'
import type {
  IBearingOption,
  IInitialData,
  IManufacturerOption,
  IOuts,
  TFormBr,
  TKinematicBearing
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.type'
import {
  getAdaptedBearingForKinematic,
  getCalculatedResults,
  parseDataForChangeBr,
  parseServerData
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.util'
import { BearingCoefficient } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/components/bearing-coefficients/bearing-coefficients'
import { BearingGeometric } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/components/bearing-geometric/bearing-geometric'
import { ControlPanel } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import { KbSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/kb-source/kb-source'
import {
  TypeSource,
  optionsTypeSourceWithKb
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IOptionOutSource } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import { useGetManufacturesQuery, useLazyGetBearingQuery, useLazyGetBearingsQuery } from '@/store/api/bearing.api'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateExcludedValue, validateMinValue } from '@/utils/validation-rules'
import { AutoComplete, Form, InputNumber, type InputRef, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { useForm } from 'antd/lib/form/Form'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import styles from '../../drawer-kinematic.module.css'

type TProps = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const BrForm: FC<TProps> = ({ onClose, kinematicElement, image }) => {
  const { data: manufactures, isFetching: isFetchingManufactures } = useGetManufacturesQuery()

  const [brForm] = useForm<TFormBr>()
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [disabledCalculateButton, setDisabledCalculateButton] = useState(false)
  const [initialData, setInitialData] = useState<IInitialData | null>(null)
  const [isOpenedBearingDrawer, setIsOpenedBearingDrawer] = useState(false)
  const [searchOptions, setSearchOptions] = useState<IBearingOption[]>([])
  const [manufacturesOptions, setManufacturesOptions] = useState<IManufacturerOption[]>([])
  const [kinematicBlockName, setKinematicBlockName] = useState<string | null>(null)
  const [bearing, setBearing] = useState<TKinematicBearing | null>(null)
  const [outs, setOuts] = useState<IOuts | null>(null)
  const [getBearings, { data: bearings }] = useLazyGetBearingsQuery()
  const [getBearing, { isFetching: isFetchingBearing }] = useLazyGetBearingQuery()
  const [changeKinematicElement, { isLoading: isUpdating }] = useChangeKinematicElementMutation()

  const areaRef = useRef<InputRef>(null)

  const typeSourceObservable = useWatch(BrParamName.TypeSource, brForm)
  const fieldsObservable = useWatch([], brForm)

  const requestBearings = useCallback(
    async (typeSrch: string | null, manufacturer?: string): Promise<void> => {
      try {
        await getBearings({
          typeSrch: typeSrch || '',
          manufacturer: manufacturer
        }).unwrap()
      } catch (e) {
        errorNotificationCreate(e)
      }
    },
    [getBearings]
  )

  // Initializing values into form field
  useEffect(() => {
    const { setFieldsValue } = brForm
    setOuts(null)
    setBearing(null)
    if (kinematicElement) {
      const { fieldsData, bearingData, outsData } = parseServerData(kinematicElement)

      setKinematicBlockName(kinematicElement.kinematicBlockName)
      setFieldsValue(fieldsData)
      setInitialData({
        fields: fieldsData,
        bearing: bearingData,
        outs: outsData
      })

      if (bearingData) {
        requestBearings(null, bearingData.manufacturerName).catch((e) => errorNotificationCreate(e))
        setKinematicBlockName(kinematicElement.kinematicBlockName)
        setBearing(bearingData)
        setOuts(outsData)
      }
    }
  }, [brForm, kinematicElement, requestBearings])

  // Initializing values in the bearing search field
  useEffect(() => {
    if (bearings?.content) {
      const result: IBearingOption[] = bearings.content.map((item) => ({
        value: item.modelType,
        label: item.modelType,
        manufacturer: item.manufacturer,
        bearingId: item.bearingId
      }))
      setSearchOptions(result)
    }
  }, [bearings?.content])

  // Initializing values into field manufacture
  useEffect(() => {
    if (manufactures) {
      const result = manufactures?.map((manufacture) => ({
        value: manufacture.manufacturerName,
        label: manufacture.manufacturerName
      }))
      setManufacturesOptions(result)
    }
  }, [manufactures])

  // Validation fields
  useEffect(() => {
    const { validateFields } = brForm

    const fields = brForm.getFieldsValue()

    const isEqualFields = isEqual(initialData?.fields, fields)
    const isEqualBearing = isEqual(initialData?.bearing, bearing)
    const isInitialData = isEqualFields && isEqualBearing

    setDisabledCancelButton(isInitialData)
    setDisabledCalculateButton(isInitialData)
    setDisabledFinishButton(true)

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields({
          validateOnly: true,
          recursive: true
        })

        if (!isEqualFields) {
          setDisabledCalculateButton(false)
        }
      } catch (e) {
        setDisabledCalculateButton(true)
      }
    }

    if (!disabledCancelButton) {
      checkValidateFields()
    }
  }, [bearing, brForm, disabledCancelButton, fieldsObservable, initialData?.bearing, initialData?.fields])

  const handleSearch = async (searchValue: string): Promise<void> => {
    const manufacturer = brForm.getFieldValue(BrParamName.ManufacturerValue)
    await requestBearings(searchValue, manufacturer)
  }

  const handleChangeManufacturer = async (manufacturer: string): Promise<void> => {
    await requestBearings(null, manufacturer)
    brForm.resetFields([BrParamName.BearingOptionValue])
  }

  const handleSelectBearing = async (_: string, selectedBearing: IBearingOption): Promise<void> => {
    try {
      const { bearingId, manufacturer } = selectedBearing
      if (bearingId) {
        const resultBearing = await getBearing(bearingId).unwrap()
        const adaptedBearing = getAdaptedBearingForKinematic(resultBearing)
        setBearing(adaptedBearing)
      }

      brForm.setFieldValue(BrParamName.ManufacturerValue, manufacturer)
      brForm.validateFields([BrParamName.ManufacturerValue])
    } catch (e) {
      setBearing(null)
      console.error(e)
    }
  }

  const handleCalculation = async () => {
    const { getFieldsValue, validateFields } = brForm
    const fieldsValue = getFieldsValue()
    try {
      await validateFields()

      const result = getCalculatedResults(fieldsValue, bearing, kinematicElement?.kinematicBlockName)

      if (!result) {
        return null
      }

      const { outs: outsData, blockName } = result
      setOuts(outsData)
      setKinematicBlockName(blockName)
      successNotificationCreate('Коэффициенты успешно рассчитаны')
      setDisabledFinishButton(false)
      setDisabledCalculateButton(true)
    } catch (e) {
      setDisabledFinishButton(true)
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
    }
  }

  const handleFinish = async (): Promise<void> => {
    const { getFieldsValue, validateFields } = brForm
    const fieldValues = getFieldsValue()

    try {
      await validateFields()
    } catch (e) {
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
      return
    }

    try {
      if (kinematicElement && bearing && outs && kinematicBlockName) {
        const requestDataParsed = parseDataForChangeBr(kinematicBlockName, kinematicElement, fieldValues, bearing, outs)

        await changeKinematicElement(requestDataParsed).unwrap()

        successNotificationCreate(`Кинематический блок ${kinematicBlockName} сохранён`)
        onClose()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleChangeSelect = (value: TypeSource): void => {
    brForm.setFieldValue('typesource', value)
  }

  const showChildrenDrawer = (): void => {
    setIsOpenedBearingDrawer(true)
  }

  const handleChildrenDrawerClose = (): void => {
    setIsOpenedBearingDrawer(false)
  }

  const handleUpdateManufacturesOptions = (value: string): void => {
    setManufacturesOptions([...manufacturesOptions, { value: value, label: value }])
  }

  const handleGetBearing = async (value: string) => {
    try {
      const resultBearing = await getBearing(value).unwrap()
      const adaptedBearing = getAdaptedBearingForKinematic(resultBearing)
      setBearing(adaptedBearing)
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleSetFieldsBearing = (manufacturer?: string, modelType?: string) => {
    brForm.setFieldsValue({
      [BrParamName.ManufacturerValue]: manufacturer,
      [BrParamName.BearingOptionValue]: modelType
    })
    setOuts(null)
  }

  const handleSelectOutFreq = (_: number, options: IOptionOutSource) => {
    brForm.setFieldValue(BrParamName.FreqOutSource, options)
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = brForm
      resetFields()
      setFieldsValue(initialData.fields)
      setBearing(initialData.bearing)
      setOuts(initialData.outs)
    }
  }

  return (
    <>
      <Form className={styles['drawer-kinematic-form']} form={brForm}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
        <div className={styles['drawer-kinematic-header']}>
          <Form.Item name={BrParamName.Description} className={styles['drawer-kinematic-text-area']}>
            <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} ref={areaRef} />
          </Form.Item>
          <div className={styles['drawer-kinematic-image-wrapper']}>
            <img className={styles['drawer-kinematic-image']} src={image} alt='' />
          </div>
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Вход</h2>
        <Form.Item
          label='Тип источника'
          name={BrParamName.TypeSource}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо выбрать тип источника'
            },
            {
              validator: validateExcludedValue({
                excludedValue: TypeSource.Missing,
                errorMessage: 'Необходимо выбрать тип источника'
              })
            }
          ]}
        >
          <Select
            placeholder='Выберите тип источника'
            options={optionsTypeSourceWithKb}
            onChange={handleChangeSelect}
          />
        </Form.Item>
        <KbSource
          typeSource={typeSourceObservable}
          kinematicSchemeId={kinematicElement?.kinematicSchemeId}
          form={brForm}
          onSelectOutFreq={handleSelectOutFreq}
          currentKinematicBlockId={kinematicElement?.kinematicBlockId}
        />
        <Form.Item
          label='Коэффициент частоты КБ'
          name={BdParamName.FreqCoefForIn}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать коэффициент входной частоты'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            className={styles['drawer-kinematic-input-number']}
            placeholder='Введите коэффициент входной частоты'
            min={0}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Подшипник</h2>
        <Form.Item
          label='Производитель подшипника'
          name={BrParamName.ManufacturerValue}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо выбрать производителя подшипника'
            }
          ]}
        >
          <Select
            placeholder='Выберите производителя подшипника'
            options={manufacturesOptions}
            loading={isFetchingManufactures}
            allowClear={{ clearIcon: CancelIconSvg }}
            onChange={handleChangeManufacturer}
          />
        </Form.Item>
        <Form.Item
          label='Поиск типа подшипника'
          name={BrParamName.BearingOptionValue}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать тип подшипника'
            }
          ]}
        >
          <AutoComplete
            placeholder='Введите тип подшипника для поиска'
            options={searchOptions}
            onSelect={handleSelectBearing}
            onSearch={handleSearch}
            allowClear={{ clearIcon: CancelIconSvg }}
          />
        </Form.Item>
        <div className={styles['drawer-kinematic-button-container']}>
          <ButtonPrimary
            className={styles['drawer-kinematic-button-add']}
            title='Новый подшипник'
            htmlType='button'
            onClick={showChildrenDrawer}
            theme='dark'
          />
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Выход</h2>
        <BearingCoefficient outs={outs} />
        <BearingGeometric bearing={bearing} isFetchingBearing={isFetchingBearing} />
        <BearingForm
          manufacturesOptions={manufacturesOptions}
          onUpdateManufacturesOptions={handleUpdateManufacturesOptions}
          onClose={handleChildrenDrawerClose}
          isOpenedBearingDrawer={isOpenedBearingDrawer}
          onGetBearing={handleGetBearing}
          onSetFieldsBearing={handleSetFieldsBearing}
        />
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickCalculation={handleCalculation}
        onClickSave={handleFinish}
        disabledCalculation={disabledCalculateButton}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </>
  )
}

export default BrForm
