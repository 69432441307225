import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { LegacyRef, MutableRefObject } from 'react'
import { type FC, useState } from 'react'

import styles from '../../express-sidebar.module.css'

import ExpressBarListItem from './components/express-bar-list-item'
import ExpressBarPopup from './components/express-bar-popup'

interface IExpressBar {
  onClickExpressBarButton: (index: number) => void
  itemBarsRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
}

const ExpressBar: FC<IExpressBar> = ({ onClickExpressBarButton, itemBarsRefs }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { resultsBar, isBarsExpressBarOpen } = useTypedSelector((state) => state.expressBarsReducer)
  return (
    <div className={styles.container}>
      <ButtonPrimary onClick={() => setIsPopupOpen(true)} htmlType={'button'} title={'Ряды'} />

      <div className={styles['container-express']}>
        <ExpressBarPopup setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen} />

        <ul className={styles.list}>
          {isBarsExpressBarOpen
            ? resultsBar.map((result, i) => (
                <ExpressBarListItem
                  key={result.title}
                  onClickExpressBarButton={() => onClickExpressBarButton(i)}
                  refBarListItem={itemBarsRefs?.current && itemBarsRefs?.current[i]}
                  result={result}
                  currentIndex={i}
                />
              ))
            : null}
        </ul>
      </div>
    </div>
  )
}
export default ExpressBar
