import useActions from '@/hooks/use-actions'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Circle, Group, Image, Text } from 'react-konva'

interface IPositionNumber {
  x: number
  y: number
}

const initialPositionNumber: IPositionNumber = {
  x: 0,
  y: 0
}

type TKinematicBlock = {
  kinematicElement: IKinematicElement
  image?: HTMLImageElement
  statusConnection?: string
  index: number
}

const RADIUS_CIRCLE = 12

const InformationBlock: FC<TKinematicBlock> = ({ kinematicElement, image, statusConnection, index }) => {
  const { setSelectedKinematic } = useActions()
  const [positionNumber, setPositionNumber] = useState<IPositionNumber>(initialPositionNumber)
  useEffect(() => {
    if (kinematicElement.width && kinematicElement.height) {
      const x = kinematicElement.x + kinematicElement.width / 2
      const y = kinematicElement.y + kinematicElement.height / 2
      setPositionNumber({ x, y })
    }
  }, [kinematicElement.height, kinematicElement.width, kinematicElement.x, kinematicElement.y])

  const handleSelectionClick = () => {
    setSelectedKinematic(kinematicElement)
  }

  return (
    <Group onClick={handleSelectionClick}>
      <Image
        y={kinematicElement.y}
        x={kinematicElement.x}
        width={kinematicElement.width}
        height={kinematicElement.height}
        image={image}
        stroke={statusConnection}
      />
      <Circle
        x={kinematicElement.x + kinematicElement.width / 2}
        y={kinematicElement.y + kinematicElement.height / 2}
        fill='rgba(198,184,116, 0.9)'
        radius={RADIUS_CIRCLE}
      />
      <Text
        x={positionNumber.x - RADIUS_CIRCLE}
        y={positionNumber.y - RADIUS_CIRCLE}
        text={(index + 1).toString()}
        fontSize={12}
        fontFamily='Arial'
        fill='white'
        align='center'
        verticalAlign='middle'
        width={RADIUS_CIRCLE * 2}
        height={RADIUS_CIRCLE * 2}
        strokeWidth={3}
      />
    </Group>
  )
}

export default InformationBlock
