import type { TVp3701Form } from '@/types/device/vp3701/vp3701-form.type'
import type { TVp3701Static } from '@/types/device/vp3701/vp3701-static.type'
import type { IVp3701 } from '@/types/device/vp3701/vp3701.interface'

export const mapDataToStatic = (vp3701: IVp3701): TVp3701Static => ({
  accelType: vp3701.accelType,
  batteryCharge: vp3701.batteryCharge,
  connectionStatus: vp3701.connectionStatus,
  firmware: vp3701.firmware,
  lastErrors: vp3701.lastErrors,
  modification: vp3701.modification,
  powerType: vp3701.powerType,
  serialNumber: vp3701.serialNumber,
  ssid: vp3701.ssid,
  status: vp3701.status,
  statusUpdatingTimestamp: vp3701.statusUpdatingTimestamp,
  thermoType: vp3701.thermoType,
  channelUnitType1: vp3701.channelUnitType1,
  channelUnitType2: vp3701.channelUnitType2,
  channelUnitType3: vp3701.channelUnitType3,
  channelUnitType4: vp3701.channelUnitType4,
  channelUnitType5: vp3701.channelUnitType5
})

export const mapDataToForm = (vp3701: IVp3701): TVp3701Form => ({
  comment: vp3701.comment,
  location: vp3701.location,
  name: vp3701.name
})
