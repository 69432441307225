import DocTooltip from '@/components/doc-tooltip/doc-tooltip'
import { BrOutName } from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.constant'
import type {
  IOutDataBr,
  IOuts
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.type'
import {
  handleName,
  handleValue
} from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/components/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type BearingCoefficientType = {
  outs: IOuts | null
}

export const BearingCoefficient: FC<BearingCoefficientType> = ({ outs }) => {
  const [bearingDataTable, setBearingDataTable] = useState<IOutDataBr[] | undefined>(undefined)

  useEffect(() => {
    if (!outs) {
      setBearingDataTable(undefined)
      return
    }

    const data: Omit<IOutDataBr, 'key'>[] = [
      {
        label: {
          name: 'kFTF',
          tooltip: 'Коэффициент частоты вращения сепаратора'
        },
        name: handleName(outs[BrOutName.Kftf].freqOutName),
        coefKb: handleValue(outs[BrOutName.Kftf].freqOutCoefKb),
        coefMachine: handleValue(outs[BrOutName.Kftf].freqOutCoefMachine),
        value: handleValue(outs[BrOutName.Kftf].freqOutValue)
      },
      {
        label: {
          name: 'kFTFo',
          tooltip: 'Коэффициент обратной частоты сепаратора'
        },
        name: handleName(outs[BrOutName.Kftfo].freqOutName),
        coefKb: handleValue(outs[BrOutName.Kftfo].freqOutCoefKb),
        coefMachine: handleValue(outs[BrOutName.Kftfo].freqOutCoefMachine),
        value: handleValue(outs[BrOutName.Kftfo].freqOutValue)
      },
      {
        label: {
          name: 'kBSF',
          tooltip: 'Коэффициент частоты контакта тел качения'
        },
        name: handleName(outs[BrOutName.Kbsf].freqOutName),
        coefKb: handleValue(outs[BrOutName.Kbsf].freqOutCoefKb),
        coefMachine: handleValue(outs[BrOutName.Kbsf].freqOutCoefMachine),
        value: handleValue(outs[BrOutName.Kbsf].freqOutValue)
      },
      {
        label: {
          name: 'kBPFO',
          tooltip: 'Коэффициент частоты перекатывания тел качения по наружному кольцу'
        },
        name: handleName(outs[BrOutName.Kbpfo].freqOutName),
        coefKb: handleValue(outs[BrOutName.Kbpfo].freqOutCoefKb),
        coefMachine: handleValue(outs[BrOutName.Kbpfo].freqOutCoefMachine),
        value: handleValue(outs[BrOutName.Kbpfo].freqOutValue)
      },
      {
        label: {
          name: 'kBPFI',
          tooltip: 'Коэффициент частоты перекатывания тел качения по внутреннему кольцу'
        },
        name: handleName(outs[BrOutName.Kbpfi].freqOutName),
        coefKb: handleValue(outs[BrOutName.Kbpfi].freqOutCoefKb),
        coefMachine: handleValue(outs[BrOutName.Kbpfi].freqOutCoefMachine),
        value: handleValue(outs[BrOutName.Kbpfi].freqOutValue)
      }
    ]
    const tableData = data.map((item, key) => ({ ...item, key }))
    setBearingDataTable(tableData)
  }, [outs])

  const bearingCoefficientColumns: ColumnsType<IOutDataBr> = [
    {
      dataIndex: 'label',
      render: (_, { label }) => (
        <div className={styles['drawer-kinematic-space-table-column']}>
          <DocTooltip simpleText={label.tooltip} />
          <strong>{label.name}</strong>
        </div>
      )
    },
    {
      title: 'Наименование коэффициентов',
      dataIndex: 'name'
    },
    {
      title: 'Коэффициенты в рамках КБ',
      dataIndex: 'coefKb'
    },
    {
      title: 'Коэффициенты в рамках машины',
      dataIndex: 'coefMachine'
    },
    {
      title: 'Значения частот в машине',
      dataIndex: 'value'
    }
  ]

  return (
    <Table
      className={styles['drawer-kinematic-table']}
      columns={bearingCoefficientColumns}
      dataSource={bearingDataTable}
      bordered={true}
      pagination={false}
    />
  )
}
