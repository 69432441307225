import type { TSortingValues } from '@/types/plan/plan.type'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './table-plans-title-cell.module.css'

export type TTag = 'name' | 'device' | 'status' | 'modified' | 'nextExecutionTimestamp' | 'lastExecutionTimestamp'

type TProps = {
  sortingState: TSortingValues | ''
  handleSortingButton: (tag: TTag) => void
  title: string
  tag: TTag
}

const TablePlansTitleCell: FC<TProps> = ({ sortingState, title, tag, handleSortingButton }) => (
  <li
    className={cn(styles['sorting-item'], {
      [styles['sorting-item-active']]: sortingState === tag || sortingState === `${tag}Reverse`
    })}
  >
    <button
      onClick={() => {
        handleSortingButton(tag)
      }}
      className={styles['sorting-button']}
    >
      {title}
      {(sortingState === tag || sortingState === `${tag}Reverse`) && (
        <img
          src={require('@/assets/images/icons/expand_more.svg').default}
          alt='Стрелка'
          className={cn(styles['sorting-button-image-active-up'], {
            [styles['sorting-button-image-active-down']]: sortingState === `${tag}Reverse`
          })}
        />
      )}
    </button>
  </li>
)

export default TablePlansTitleCell
