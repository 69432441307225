import { useGetCurrentMnemoScheme } from '@/hooks/api/use-get-current-mnemo-scheme'
import { useUploadImageMutation } from '@/store/api/images.api'
import { useAddMnemonicItemMutation } from '@/store/api/mnemo.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { ChangeEvent, FC } from 'react'

const UploadFile: FC = () => {
  const { data: currentMnemonicSchemeData } = useGetCurrentMnemoScheme()
  const [uploadImage] = useUploadImageMutation()
  const [addMnemonicItem] = useAddMnemonicItemMutation()

  const uploadImageHandler = async (file: FormData) => {
    if (currentMnemonicSchemeData?.mnemoSchemeId) {
      try {
        const dataImage = await uploadImage(file).unwrap()

        if (!dataImage?.id || !dataImage?.name) throw new Error('Ошибка загрузки')

        const createdMnemonicItem = await addMnemonicItem({
          mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
          name: dataImage.name,
          x: 10,
          y: 10,
          staticImageElement: {
            fileId: dataImage.id,
            width: null,
            height: null
          }
        }).unwrap()

        if (!createdMnemonicItem.id) throw new Error('Ошибка при добавлении в базу элемента')

        successNotificationCreate('Изображение добавлено')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const onChangeHandler = async (evt: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()
    const files = evt?.target?.files
    const lastFile = files && files[files.length - 1]

    if (lastFile) {
      formData.append('file', lastFile)

      uploadImageHandler(formData)
    }
  }

  return <input style={{ width: '100%', flexShrink: 0 }} accept='image/png' type={'file'} onChange={onChangeHandler} />
}

export default UploadFile
