import ButtonPrimary from '@/components/UI/buttons/button-primary/button-primary'
import ButtonsWrapper from '@/components/UI/elements/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/UI/elements/controls-wrapper/controls-wrapper'
import HeaderConfiguration from '@/components/UI/elements/header-work-space/header-configuration'
import Spinner from '@/components/UI/elements/spinner/spinner'
import SwitchIndicator from '@/components/UI/elements/switch-indicator/switch-indicator'
import TextStatic from '@/components/UI/form-controls/text-static/text-static'
import ContentWrapper from '@/components/UI/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/UI/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/UI/panel/panel-title/panel-title'
import { ButtonTitle } from '@/constants/core/common.constant'
import { LtrModuleLabel } from '@/constants/device/vp3711/ltr-module-label.constant'
import { ELtrModuleStatus } from '@/enums/ltr/ltr-module-status.enum'
import { useGetLtrModuleQuery, useUpdateModuleMutation } from '@/store/api/ltr.api'
import type { TVp3711ModuleForm } from '@/types/device/vp3711/vp3711-module-form.type'
import type { TVp3711ModuleStatic } from '@/types/device/vp3711/vp3711-module-static.type'
import type { ILtrModule } from '@/types/ltr/ltr-module/ltr-module.interface'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Checkbox, Flex, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './vp3711-module.module.css'

type TProps = {
  deviceId: string | undefined
}

const getValuesForForm = (ltrModule: ILtrModule): TVp3711ModuleForm => ({
  name: ltrModule.name,
  comment: ltrModule.comment,
  testMode: ltrModule.testMode
})

const Vp3711ModulePanel: FC<TProps> = ({ deviceId }) => {
  const { data: ltrModule, isLoading } = useGetLtrModuleQuery({ ltrModuleId: deviceId }, { skip: !deviceId })

  const [triggerUpdateModule, { isLoading: isUpdating }] = useUpdateModuleMutation()
  const [staticData, setStaticData] = useState<TVp3711ModuleStatic>({})
  const [form] = useForm<TVp3711ModuleForm>()

  useEffect(() => {
    if (ltrModule) {
      const data: TVp3711ModuleStatic = {
        moduleInfo: ltrModule.moduleInfo,
        countChannels: ltrModule.ltr24ChannelSettings.length,
        moduleType: ltrModule.moduleType,
        serial: ltrModule.serial,
        status: ltrModule.status,
        slotNumber: ltrModule.slotNumber,
        crateSerial: ltrModule.crateSerial
      }
      setStaticData(data)

      const dataForForm: TVp3711ModuleForm = {
        name: ltrModule.name,
        comment: ltrModule.comment,
        testMode: ltrModule.testMode
      }

      form.setFieldsValue(dataForForm)
    }
  }, [form, ltrModule])

  if (isLoading) {
    return <Spinner />
  }

  if (!ltrModule) {
    return undefined
  }

  const handleUpdateVp3711ModuleFinish = async () => {
    const fields = form.getFieldsValue()
    try {
      if (fields && deviceId) {
        await triggerUpdateModule({ ltrModuleId: deviceId, body: fields }).unwrap()
        successNotificationCreate('Параметры крейта обновлены')
      }
    } catch (e) {
      console.error(e)
      errorNotificationCreate(e)
    }
  }

  const handleCancelForm = async () => {
    form.setFieldsValue(getValuesForForm(ltrModule))
  }

  const determineSupportIcp = (supportIcp?: boolean) => {
    if (supportIcp) {
      return 'Есть'
    }

    return 'Нет'
  }

  // TODO: Добавить цвет статуса "Измеряет" для индикатора
  const determineDeviceStatus = (status?: ELtrModuleStatus) => {
    switch (status) {
      case ELtrModuleStatus.READY:
        return <SwitchIndicator sourceBoolean={true} trueContent='Готов к измерению' />
      case ELtrModuleStatus.RUNNING:
        return <SwitchIndicator sourceBoolean={true} trueContent='Измеряет' />
      default:
        return <SwitchIndicator sourceBoolean={false} falseContent='Оффлайн' />
    }
  }

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex gap={5} align='center'>
            <span className='material-symbols-outlined'>developer_board</span>
            <span>Модуль {ltrModule.name}</span>
          </Flex>
        }
      />
      <ContentWrapper>
        <Form className={styles.container} form={form} onFinish={handleUpdateVp3711ModuleFinish} disabled={isUpdating}>
          <ControlsWrapper>
            <PanelTitle>Параметры модуля</PanelTitle>
            <FormItemApp name='name' label={LtrModuleLabel.Name}>
              <Input />
            </FormItemApp>
            <FormItemApp name='testMode' label={LtrModuleLabel.TestMode} valuePropName='checked'>
              <Checkbox />
            </FormItemApp>
            <FormItemApp name='comment' label={LtrModuleLabel.Comment}>
              <TextArea className={styles['text-area']} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <PanelTitle>Информация о модуле</PanelTitle>
            <TextStatic label={LtrModuleLabel.CrateSerial} value={staticData.crateSerial} />
            <TextStatic label={LtrModuleLabel.SlotNumber} value={staticData.slotNumber} />
            <TextStatic label={LtrModuleLabel.ModuleType} value={staticData.moduleType} />
            <TextStatic label={LtrModuleLabel.Serial} value={staticData.serial} />
            <TextStatic label={LtrModuleLabel.Status} value={staticData.status} />
            <TextStatic label={LtrModuleLabel.VersionPLD} value={staticData.moduleInfo?.versionPLD} />
            <TextStatic
              label={LtrModuleLabel.SupportICP}
              value={determineSupportIcp(staticData.moduleInfo?.supportICP)}
            />
            <TextStatic label={LtrModuleLabel.CountChannels} value={staticData.countChannels} />
            <PanelTitle withoutBorder={true} className={styles['title']}>
              Статус устройства
            </PanelTitle>
            <TextStatic label={LtrModuleLabel.Status} value={determineDeviceStatus(staticData.status)} />
          </ControlsWrapper>
          <ControlsWrapper className={styles['buttons']}>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                title={ButtonTitle.Reset}
                isLoadingSkeleton={false}
                isLoading={false}
                disabled={false}
                onClick={handleCancelForm}
              />
              <ButtonPrimary htmlType='submit' title={ButtonTitle.Save} loading={isUpdating} />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}

export default Vp3711ModulePanel
