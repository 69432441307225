import { SliceName } from '@/constants/store/slices.constant'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TResultsMarkers = {
  title: string
  isDb: boolean
  results: { freq: number | string; value: number | string }[]
  d12Freq: number | string
  d13Freq: number | string
  d23Freq: number | string
  d12Value: number | string
  d13Value: number | string
  d23Value: number | string
}

export type TMarker = { freq: number | string; index: number | string | null }

interface IInitialExpressData {
  markers: TMarker[]
  isMarkersExpressBarOpen: boolean
  resultsMarkers: TResultsMarkers[]
}

const initialState: IInitialExpressData = {
  markers: [],
  isMarkersExpressBarOpen: false,
  resultsMarkers: []
}

export const expressMarkersSlice = createSlice({
  name: SliceName.ExpressMarkers,
  initialState,
  reducers: {
    setIsMarkersExpressOpen(state, action: PayloadAction<boolean>) {
      state.isMarkersExpressBarOpen = action.payload
    },

    setInitialExpressMarkerOptions(state, action: PayloadAction<TMarker[]>) {
      state.markers = action.payload
    },

    setExpressMarkerOptions(state, actions: PayloadAction<{ markerOption: TMarker; index: number }>) {
      state.markers[actions.payload.index] = actions.payload.markerOption
    },

    setResultsMarker(state, action: PayloadAction<TResultsMarkers>) {
      const foundIndex = state.resultsMarkers.findIndex((result) => result.title === action.payload.title)

      if (foundIndex === -1) {
        state.resultsMarkers.push(action.payload)
      } else {
        state.resultsMarkers[foundIndex] = {
          ...state.resultsMarkers[foundIndex],
          ...action.payload
        }
      }
    },

    resetResultsMarker(state) {
      state.resultsMarkers = []
    }
  }
})
