export const enum EMnemoElementType {
  CIRCLE_INDICATOR = 'CIRCLE_INDICATOR',
  DANGER_STATE_INDICATOR = 'DANGER_STATE_INDICATOR',
  MEASUREMENT_LAST_VALUE = 'MEASUREMENT_LAST_VALUE',
  STATIC_IMAGE = 'STATIC_IMAGE',
  STATIC_LABEL = 'STATIC_LABEL',
  MEASUREMENT_WITH_THRESHOLD_INDICATOR = 'MEASUREMENT_WITH_THRESHOLD_INDICATOR',
  STATISTICAL_INDICATOR = 'STATISTICAL_INDICATOR',
  TEXT_INDICATOR = 'TEXT_INDICATOR'
}
